import React, { useEffect, useState, useRef, useCallback } from "react";
import { css, keyframes } from "@emotion/react";
import { useTheme } from "../context/ThemeContext";
import { QubitDisplayInfo } from "../hooks/useQuantumCircuitSimulator";

/** @jsxImportSource @emotion/react */

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.9;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.9;
  }
`;

interface QuantumCanvasProps {
  qubits: QubitDisplayInfo[];
  selectedQubitId: number | null;
  entangleMode: boolean;
  swapMode: boolean;
  entangleSourceId: number | null;
  swapSourceId: number | null;
  onQubitClick: (id: number) => void;
  onCanvasClick: () => void;
  updateQubitPositions: (
    timestamp: number,
    qubits: QubitDisplayInfo[]
  ) => QubitDisplayInfo[];
}

// Colors
const QUBIT_COLORS = {
  default: "#26dafd",
  selected: "#ffcc00",
  entangleSource: "#00ff99",
  swapSource: "#ff66cc",
  entangled: "#66ff66",
};

// Constants
const QUBIT_RADIUS = 15;
const ENTANGLEMENT_WIDTH = 2;

const QuantumCanvas: React.FC<QuantumCanvasProps> = ({
  qubits,
  selectedQubitId,
  entangleMode,
  swapMode,
  entangleSourceId,
  swapSourceId,
  onQubitClick,
  onCanvasClick,
  updateQubitPositions,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [localQubits, setLocalQubits] = useState<QubitDisplayInfo[]>(qubits);
  const animationRef = useRef<number>(0);
  const { currentTheme } = useTheme();
  const themeColors = currentTheme.colorScheme;

  // Adapt to canvas size changes
  useEffect(() => {
    if (!canvasRef.current) return;

    const updateSize = () => {
      if (!canvasRef.current) return;

      const { width, height } = canvasRef.current.getBoundingClientRect();
      setCanvasSize({ width, height });

      // Set actual canvas dimensions (for high DPI screens)
      canvasRef.current.width = width;
      canvasRef.current.height = height;
    };

    updateSize();
    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // Update local qubits when prop changes
  useEffect(() => {
    setLocalQubits(qubits);
  }, [qubits]);

  // Animation loop - simplified to reduce CPU usage
  const animate = useCallback(
    (timestamp: number) => {
      const ctx = canvasRef.current?.getContext("2d");
      if (!ctx) return;

      // Only call updateQubitPositions to keep the physics simple
      const updatedQubits = updateQubitPositions(timestamp, localQubits);
      setLocalQubits(updatedQubits);

      // Clear canvas
      ctx.clearRect(0, 0, canvasSize.width, canvasSize.height);

      // Draw entanglement lines first (behind qubits)
      ctx.lineWidth = ENTANGLEMENT_WIDTH;
      ctx.strokeStyle = QUBIT_COLORS.entangled;

      updatedQubits.forEach((qubit) => {
        if (qubit.entangled.length > 0) {
          qubit.entangled.forEach((entangledId) => {
            const entangledQubit = updatedQubits.find(
              (q) => q.id === entangledId
            );
            if (entangledQubit) {
              // Convert normalized coordinates to pixel coordinates
              const qubitX = qubit.x * canvasSize.width;
              const qubitY = qubit.y * canvasSize.height;
              const targetX = entangledQubit.x * canvasSize.width;
              const targetY = entangledQubit.y * canvasSize.height;

              // Draw entanglement line
              ctx.beginPath();
              ctx.moveTo(qubitX, qubitY);
              ctx.lineTo(targetX, targetY);
              ctx.stroke();
            }
          });
        }
      });

      // Draw all qubits
      updatedQubits.forEach((qubit) => {
        // Convert normalized coordinates to pixel coordinates
        const qubitX = qubit.x * canvasSize.width;
        const qubitY = qubit.y * canvasSize.height;

        // Determine qubit color based on state
        let qubitColor = QUBIT_COLORS.default;

        if (qubit.id === selectedQubitId) {
          qubitColor = QUBIT_COLORS.selected;
        } else if (qubit.id === entangleSourceId) {
          qubitColor = QUBIT_COLORS.entangleSource;
        } else if (qubit.id === swapSourceId) {
          qubitColor = QUBIT_COLORS.swapSource;
        } else if (qubit.entangled.length > 0) {
          qubitColor = QUBIT_COLORS.entangled;
        }

        // Draw qubit circle
        ctx.beginPath();
        ctx.arc(qubitX, qubitY, QUBIT_RADIUS, 0, 2 * Math.PI);
        ctx.fillStyle = qubitColor;
        ctx.fill();
        ctx.strokeStyle = "#ffffff";
        ctx.lineWidth = 2;
        ctx.stroke();

        // Show qubit ID
        ctx.fillStyle = "#ffffff";
        ctx.font = "12px Arial";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(qubit.id.toString(), qubitX, qubitY);
      });

      // Schedule next frame - use a slower refresh rate to reduce CPU usage
      animationRef.current = requestAnimationFrame(animate);
    },
    [
      canvasSize,
      localQubits,
      selectedQubitId,
      entangleSourceId,
      swapSourceId,
      updateQubitPositions,
    ]
  );

  // Start/stop animation
  useEffect(() => {
    // Only start animation if canvas is properly sized
    if (canvasSize.width > 0 && canvasSize.height > 0) {
      animationRef.current = requestAnimationFrame(animate);
    }
    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [animate, canvasSize]);

  // Handle clicks on canvas
  const handleCanvasClick = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      // Check if click is on a qubit
      const clickedQubit = localQubits.find((qubit) => {
        const qubitX = qubit.x * canvasSize.width;
        const qubitY = qubit.y * canvasSize.height;
        const distance = Math.sqrt((x - qubitX) ** 2 + (y - qubitY) ** 2);
        return distance <= QUBIT_RADIUS;
      });

      if (clickedQubit) {
        onQubitClick(clickedQubit.id);
      } else {
        onCanvasClick();
      }
    },
    [localQubits, canvasSize, onQubitClick, onCanvasClick]
  );

  // Special styles when in entangle or swap mode
  let canvasCursor = "default";
  if (entangleMode || swapMode) {
    canvasCursor = "crosshair";
  }

  return (
    <canvas
      ref={canvasRef}
      onClick={handleCanvasClick}
      style={{
        width: "100%",
        height: "100%",
        cursor: canvasCursor,
      }}
    />
  );
};

// Memoize the canvas component as it relies on potentially large/complex props (qubits array)
// and rendering can be expensive.
export default React.memo(QuantumCanvas);
