import React, { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { GridLines, Dots, MovingLines } from "@arwes/react-bgs";
import { useTheme } from "../context/ThemeContext";

/** @jsxImportSource @emotion/react */

const ArwesBackground: React.FC = () => {
  const { currentTheme } = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Enhanced debug info to track current theme and background settings
  console.log("ArwesBackground: Current settings", {
    themeId: currentTheme.id,
    themeName: currentTheme.name,
    backgroundId: currentTheme.background.id,
    backgroundType: currentTheme.background.type,
    backgroundName: currentTheme.background.name,
    primaryColor: currentTheme.colorScheme.primary,
  });

  // Set up canvas animation for custom animated backgrounds (particles, waves)
  useEffect(() => {
    // Only run this effect for type 'animation'
    if (currentTheme.background.type !== "animation" || !canvasRef.current) {
      console.log(
        "Skipping animation setup - not an animation type or canvas not ready"
      );
      return;
    }

    console.log("Setting up animation for:", currentTheme.background.id);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      console.error("Failed to get canvas context");
      return;
    }

    // Set canvas size
    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    setCanvasSize();
    window.addEventListener("resize", setCanvasSize);

    // Animation variables
    let animationFrameId: number;
    let particles: {
      x: number;
      y: number;
      size: number;
      speed: number;
      color: string;
      opacity?: number;
      angle?: number;
      wave?: { amplitude: number; frequency: number; offset: number };
    }[] = [];
    let waveTime = 0;

    // Create particles based on background type
    const createParticles = () => {
      particles = [];
      const backgroundId = currentTheme.background.id;
      const primaryColor = currentTheme.colorScheme.primary;
      const secondaryColor = currentTheme.colorScheme.secondary;
      const accentColor = currentTheme.colorScheme.accent;

      console.log("Creating particles for background:", backgroundId);

      if (backgroundId === "animated-particles") {
        console.log("Generating Neural Particles");
        // Neural Particles
        const particleCount = Math.floor((canvas.width * canvas.height) / 8000);

        for (let i = 0; i < particleCount; i++) {
          particles.push({
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height,
            size: Math.random() * 2.5 + 1,
            speed: Math.random() * 0.8 + 0.3,
            color: i % 5 === 0 ? secondaryColor : primaryColor,
            opacity: Math.random() * 0.5 + 0.5,
          });
        }
        console.log(`Created ${particles.length} neural particles`);
      } else if (backgroundId === "animated-waves") {
        console.log("Generating Digital Waves");
        // Digital Waves
        const rows = 8;
        const pointsPerRow = Math.floor(canvas.width / 20);

        for (let row = 0; row < rows; row++) {
          const y = (canvas.height / (rows + 1)) * (row + 1);
          const colors = [primaryColor, secondaryColor, accentColor];
          const color = colors[row % colors.length];

          for (let i = 0; i < pointsPerRow; i++) {
            const x = (canvas.width / pointsPerRow) * i;
            particles.push({
              x: x,
              y: y,
              size: 0, // Size not used for waves
              speed: 0, // Speed not used for waves
              color: color,
              wave: {
                amplitude: 25 + row * 8,
                frequency: 0.015 - row * 0.001,
                offset: i * 0.3,
              },
            });
          }
        }
        console.log(`Created ${particles.length} wave points`);
      } else {
        console.log("Unknown animation background type:", backgroundId);
      }
    };

    createParticles();
    window.addEventListener("resize", createParticles);

    // Animation loop
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const backgroundId = currentTheme.background.id;

      if (backgroundId === "animated-particles") {
        // Neural Particles animation
        for (let i = 0; i < particles.length; i++) {
          const p = particles[i];

          // Move particle with enhanced speed
          p.y += p.speed * (currentTheme.animationSpeed || 1) * 1.5;
          if (p.y > canvas.height) {
            p.y = 0;
            p.x = Math.random() * canvas.width;
          }

          // Draw particle with increased size
          ctx.beginPath();
          ctx.arc(p.x, p.y, p.size * 1.5, 0, Math.PI * 2);
          ctx.fillStyle = p.color;
          ctx.globalAlpha = p.opacity || 0.9; // Increased opacity
          ctx.fill();

          // Draw stronger glow effect
          ctx.beginPath();
          ctx.arc(p.x, p.y, p.size * 3, 0, Math.PI * 2);
          ctx.fillStyle = p.color;
          ctx.globalAlpha = (p.opacity || 0.7) * 0.5; // Stronger glow
          ctx.fill();

          // Draw connections to nearby particles with greater distance
          for (let j = i + 1; j < particles.length; j++) {
            const p2 = particles[j];
            const dx = p.x - p2.x;
            const dy = p.y - p2.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance < 180) {
              // Increased connection distance
              ctx.beginPath();
              ctx.moveTo(p.x, p.y);
              ctx.lineTo(p2.x, p2.y);
              ctx.strokeStyle = p.color;
              ctx.globalAlpha = 0.5 * (1 - distance / 180); // Increased opacity
              ctx.lineWidth = 1.5; // Thicker lines
              ctx.stroke();
            }
          }
        }
      } else if (backgroundId === "animated-waves") {
        // Digital Waves animation
        waveTime += 0.02 * (currentTheme.animationSpeed || 1); // Faster wave movement

        // Draw waves with enhanced appearance
        for (let row = 0; row < particles.length; row++) {
          const p = particles[row];
          if (!p.wave) continue;

          // Calculate wave position with increased amplitude
          const yOffset =
            Math.sin(waveTime + p.wave.offset) * (p.wave.amplitude * 1.5);

          if (row > 0 && row % Math.floor(canvas.width / 20) !== 0) {
            const prevPoint = particles[row - 1];

            // Main wave line
            ctx.beginPath();
            ctx.moveTo(
              prevPoint.x,
              prevPoint.y +
                (prevPoint.wave
                  ? Math.sin(waveTime + prevPoint.wave.offset) *
                    (prevPoint.wave.amplitude * 1.5)
                  : 0)
            );
            ctx.lineTo(p.x, p.y + yOffset);
            ctx.strokeStyle = p.color;
            ctx.globalAlpha = 0.85; // Increased opacity
            ctx.lineWidth = 3.5; // Thicker lines
            ctx.stroke();

            // Enhanced glow effect
            ctx.beginPath();
            ctx.moveTo(
              prevPoint.x,
              prevPoint.y +
                (prevPoint.wave
                  ? Math.sin(waveTime + prevPoint.wave.offset) *
                    (prevPoint.wave.amplitude * 1.5)
                  : 0)
            );
            ctx.lineTo(p.x, p.y + yOffset);
            ctx.strokeStyle = p.color;
            ctx.globalAlpha = 0.5; // Stronger glow
            ctx.lineWidth = 7; // Much thicker for glow
            ctx.stroke();
          }
        }
      } else {
        console.log("Unknown animation type in animation loop:", backgroundId);
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    // Cleanup
    return () => {
      window.removeEventListener("resize", setCanvasSize);
      window.removeEventListener("resize", createParticles);
      cancelAnimationFrame(animationFrameId);
    };
  }, [currentTheme]);

  // Render image background
  if (
    currentTheme.background.type === "image" &&
    currentTheme.background.path
  ) {
    console.log("Rendering image background:", {
      imagePath: currentTheme.background.path,
      backgroundName: currentTheme.background.name,
    });

    return (
      <div
        css={css`
          position: fixed;
          inset: 0;
          background-color: ${currentTheme.colorScheme.background};
          background-image: url(${currentTheme.background.path});
          background-size: cover;
          background-position: center center;
          z-index: 0;
          overflow: hidden;
          &::before {
            content: "";
            position: absolute;
            inset: 0;
            background: radial-gradient(
              circle at center,
              transparent 0%,
              ${currentTheme.colorScheme.background} 100%
            );
            opacity: 0.7;
            z-index: 1;
          }
          &::after {
            content: "";
            position: absolute;
            inset: 0;
            background: repeating-linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.1),
              rgba(0, 0, 0, 0.1) 1px,
              transparent 1px,
              transparent 2px
            );
            z-index: 2;
          }
        `}
      />
    );
  }

  // Render Arwes component-based background (like Quantum Grid)
  if (currentTheme.background.type === "arwes") {
    const primaryColor = currentTheme.colorScheme.primary;
    const bgColor = currentTheme.colorScheme.background;
    const lineOpacity = 0.1;
    const lineColor = `rgba(${parseInt(bgColor.slice(1, 3), 16)}, ${parseInt(
      bgColor.slice(3, 5),
      16
    )}, ${parseInt(bgColor.slice(5, 7), 16)}, ${lineOpacity})`;

    console.log("Rendering Arwes grid background", {
      id: currentTheme.background.id,
      primaryColor,
      bgColor,
      lineColor,
    });

    return (
      <div
        css={css`
          position: fixed;
          inset: 0;
          background-color: ${bgColor};
          z-index: 0;
          overflow: hidden;
        `}
      >
        {/* Quantum Grid */}
        {currentTheme.background.id === "animated-grid" && (
          <>
            <GridLines lineColor={lineColor} distance={30} />
            <Dots color={lineColor} distance={30} size={1} />
            <MovingLines lineColor={primaryColor} distance={50} sets={40} />
          </>
        )}
      </div>
    );
  }

  // Render canvas for custom animations ('animation' type)
  if (currentTheme.background.type === "animation") {
    return (
      <canvas
        ref={canvasRef}
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: ${currentTheme.colorScheme.background};
          z-index: 0;
        `}
      />
    );
  }

  // Fallback or default case
  return (
    <div
      css={css`
        position: fixed;
        inset: 0;
        background-color: ${currentTheme.colorScheme.background};
        z-index: 0;
      `}
    />
  );
};

export default ArwesBackground;
