import React, { useState, useRef, useEffect } from "react";
import { css } from "@emotion/react";
import { useAudio } from "../context/AudioContext";
import { useTheme } from "../context/ThemeContext";

/** @jsxImportSource @emotion/react */

// Format seconds to mm:ss
const formatTime = (seconds: number): string => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs.toString().padStart(2, "0")}`;
};

interface MusicPlayerProps {
  windowId: string;
}

const MusicPlayer: React.FC<MusicPlayerProps> = ({ windowId }) => {
  const {
    currentTrack,
    isPlaying,
    currentTime,
    musicVolume,
    isMusicEnabled,
    availableMusicTracks,
    playMusic,
    pauseMusic,
    resumeMusic,
    nextTrack,
    previousTrack,
    setMusicVolume,
    seekTo,
  } = useAudio();

  // Log received context values for debugging
  console.log("[MusicPlayer] Received from useAudio:", {
    currentTrack,
    isPlaying,
    currentTime,
    musicVolume,
    availableMusicTracks,
  });

  const { currentTheme } = useTheme();
  const themeColors = currentTheme.colorScheme;

  // Safer volume state initialization with fallback
  const [volume, setVolume] = useState((musicVolume ?? 0.5) * 100);
  const progressBarRef = useRef<HTMLDivElement>(null);

  // Update local volume state if context changes (e.g., loaded from settings later)
  useEffect(() => {
    setVolume((musicVolume ?? 0.5) * 100);
  }, [musicVolume]);

  // Handle play/pause button click
  const handlePlayPause = () => {
    if (!availableMusicTracks || availableMusicTracks.length === 0) return;

    if (!currentTrack) {
      // If no track is selected, play the first track
      playMusic(availableMusicTracks[0].id);
    } else if (isPlaying) {
      pauseMusic();
    } else {
      resumeMusic();
    }
  };

  // Handle volume change
  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = Number(e.target.value);
    setVolume(newVolume);
    setMusicVolume(newVolume / 100);
  };

  // Handle track selection
  const handleTrackSelect = (trackId: string) => {
    playMusic(trackId);
  };

  // Handle seeking
  const handleSeek = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!currentTrack || !progressBarRef.current) return;

    const rect = progressBarRef.current.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const percentClicked = clickPosition / rect.width;
    const seekTime = percentClicked * currentTrack.duration;

    seekTo(seekTime);
  };

  // Calculate progress percentage with safeguard for duration=0
  const progressPercent = currentTrack?.duration
    ? (currentTime / currentTrack.duration) * 100
    : 0;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: ${themeColors.background};
        color: ${themeColors.text};
        font-family: "Share Tech Mono", monospace;
      `}
    >
      {/* Player Header */}
      <div
        css={css`
          display: flex;
          align-items: center;
          padding: 20px;
          border-bottom: 1px solid ${themeColors.border};
        `}
      >
        <div
          css={css`
            width: 80px;
            height: 80px;
            background-color: ${themeColors.primary};
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            border-radius: 5px;
          `}
        >
          <span
            className="material-symbols-outlined"
            css={css`
              font-size: 40px;
              color: ${themeColors.background};
            `}
          >
            music_note
          </span>
        </div>

        <div>
          <h2
            css={css`
              margin: 0 0 5px 0;
              font-size: 18px;
              color: ${themeColors.primary};
            `}
          >
            {currentTrack ? currentTrack.name : "No track selected"}
          </h2>
          <p
            css={css`
              margin: 0;
              font-size: 14px;
              opacity: 0.8;
            `}
          >
            {currentTrack ? currentTrack.artist : "Select a track to play"}
          </p>
        </div>
      </div>

      {/* Player Controls */}
      <div
        css={css`
          padding: 20px;
          border-bottom: 1px solid ${themeColors.border};
        `}
      >
        {/* Progress Bar */}
        <div
          ref={progressBarRef}
          onClick={handleSeek}
          css={css`
            height: 6px;
            background-color: ${themeColors.border};
            margin-bottom: 10px;
            position: relative;
            cursor: pointer;
            border-radius: 3px;
            overflow: hidden;
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: ${progressPercent}%;
              background-color: ${themeColors.primary};
              transition: width 0.1s linear;
            `}
          />
        </div>

        {/* Time Display */}
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            font-size: 12px;
          `}
        >
          <span>{formatTime(currentTime)}</span>
          <span>
            {currentTrack ? formatTime(currentTrack.duration) : "0:00"}
          </span>
        </div>

        {/* Control Buttons */}
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
          `}
        >
          <button
            onClick={previousTrack}
            css={css`
              background: none;
              border: none;
              cursor: pointer;
              color: ${themeColors.text};
              font-size: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                color: ${themeColors.primary};
              }
            `}
          >
            <span className="material-symbols-outlined">skip_previous</span>
          </button>

          <button
            onClick={handlePlayPause}
            css={css`
              background-color: ${themeColors.primary};
              color: ${themeColors.background};
              border: none;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              transition: transform 0.1s;
              &:active {
                transform: scale(0.95);
              }
            `}
          >
            <span className="material-symbols-outlined">
              {isPlaying ? "pause" : "play_arrow"}
            </span>
          </button>

          <button
            onClick={nextTrack}
            css={css`
              background: none;
              border: none;
              cursor: pointer;
              color: ${themeColors.text};
              font-size: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                color: ${themeColors.primary};
              }
            `}
          >
            <span className="material-symbols-outlined">skip_next</span>
          </button>
        </div>

        {/* Volume Control */}
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-top: 20px;
            gap: 10px;
          `}
        >
          <span className="material-symbols-outlined">volume_up</span>
          <input
            type="range"
            min="0"
            max="100"
            value={volume}
            onChange={handleVolumeChange}
            css={css`
              flex: 1;
              height: 4px;
              -webkit-appearance: none;
              appearance: none;
              background: ${themeColors.border};
              outline: none;
              border-radius: 2px;

              &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: ${themeColors.primary};
                cursor: pointer;
              }

              &::-moz-range-thumb {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: ${themeColors.primary};
                cursor: pointer;
                border: none;
              }
            `}
          />
        </div>
      </div>

      {/* Track List */}
      <div
        css={css`
          flex: 1;
          overflow-y: auto;
          padding: 0;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            background: ${themeColors.background};
          }

          &::-webkit-scrollbar-thumb {
            background-color: ${themeColors.border};
            border-radius: 4px;
          }
        `}
      >
        <h3
          css={css`
            padding: 0 20px;
            margin: 20px 0 10px 0;
            font-size: 16px;
            color: ${themeColors.primary};
          `}
        >
          Library
        </h3>

        {availableMusicTracks && availableMusicTracks.length > 0 ? (
          availableMusicTracks.map((track) => (
            <div
              key={track.id}
              onClick={() => handleTrackSelect(track.id)}
              css={css`
                padding: 12px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid ${themeColors.border};
                cursor: pointer;
                transition: background-color 0.1s;
                background-color: ${currentTrack?.id === track.id
                  ? `${themeColors.primary}22`
                  : "transparent"};

                &:hover {
                  background-color: ${currentTrack?.id === track.id
                    ? `${themeColors.primary}33`
                    : `${themeColors.border}22`};
                }
              `}
            >
              <div>
                <div
                  css={css`
                    font-weight: ${currentTrack?.id === track.id
                      ? "bold"
                      : "normal"};
                    color: ${currentTrack?.id === track.id
                      ? themeColors.primary
                      : themeColors.text};
                  `}
                >
                  {track.name}
                </div>
                <div
                  css={css`
                    font-size: 12px;
                    opacity: 0.7;
                  `}
                >
                  {track.artist}
                </div>
              </div>

              <div
                css={css`
                  font-size: 12px;
                  opacity: 0.7;
                `}
              >
                {formatTime(track.duration)}
              </div>
            </div>
          ))
        ) : (
          <div
            css={css`
              padding: 20px;
              text-align: center;
              opacity: 0.7;
            `}
          >
            Loading library or no tracks available...
          </div>
        )}
      </div>
    </div>
  );
};

export default MusicPlayer;
