/**
 * Audio utilities for the A.E.G.I.S system
 * Handles sound loading, management, and playback
 */

// At the beginning of the file, add this type declaration
declare global {
  interface Window {
    AudioContext: typeof AudioContext;
    webkitAudioContext: typeof AudioContext;
    audioContext: AudioContext;
  }
}

// Sound paths - should match the file structure in /public/sounds
export const SOUND_PATHS = {
  ui: {
    boot: "/sounds/ui/boot.mp3",
    type: "/sounds/ui/type.mp3",
    keystroke: "/sounds/ui/keystroke.mp3",
    glitch: "/sounds/ui/glitch.mp3",
    confirm: "/sounds/ui/confirm.mp3",
    complete: "/sounds/ui/complete.mp3",
    restart: "/sounds/ui/restart.mp3",
    shutdown: "/sounds/ui/shutdown.mp3",
    click: "/sounds/ui/click.mp3",
    hover: "/sounds/ui/hover.mp3",
    alert: "/sounds/ui/alert.mp3",
    error: "/sounds/ui/error.mp3",
    notify: "/sounds/ui/notify.mp3",
  },
  effects: {
    // Add more effect sounds here
  },
  ambient: {
    // Add ambient sounds here
  },
};

// Pre-loaded sound buffers
const soundBuffers = new Map<string, AudioBuffer>();
const failedSounds = new Set<string>();

// Store decoded buffers in memory
const audioBufferCache: { [key: string]: AudioBuffer } = {};

// Define a type for the categories in SOUND_PATHS
type SoundCategory = keyof typeof SOUND_PATHS;

/**
 * Initialize audio with browser compatibility
 */
export const getAudioContext = (): AudioContext | null => {
  try {
    // Handle different browser implementations
    const AudioContextClass =
      window.AudioContext || (window as any).webkitAudioContext;

    if (!AudioContextClass) {
      console.warn("AudioContext not supported in this browser");
      return null;
    }

    // Use existing context if available, create new one if not
    if (!(window as any)._aegisAudioContext) {
      try {
        (window as any)._aegisAudioContext = new AudioContextClass();

        // Initialize with silent audio to unlock audio on iOS devices
        if ((window as any)._aegisAudioContext.state === "suspended") {
          console.log(
            "Audio context suspended, will resume on user interaction"
          );
        }
      } catch (error) {
        console.error("Failed to create AudioContext:", error);
        return null;
      }
    }

    return (window as any)._aegisAudioContext;
  } catch (error) {
    console.error("Failed to initialize audio context:", error);
    return null;
  }
};

/**
 * Create a silent audio buffer that can be used as a fallback
 */
export const createSilentBuffer = (context: AudioContext): AudioBuffer => {
  // Create a 0.5 second silent buffer
  const sampleRate = context.sampleRate;
  const buffer = context.createBuffer(1, sampleRate * 0.5, sampleRate);
  const data = buffer.getChannelData(0);

  // Fill with silence
  for (let i = 0; i < data.length; i++) {
    data[i] = 0;
  }

  return buffer;
};

// Create a valid MP3 buffer directly in code as fallback
const createValidMP3Buffer = (): ArrayBuffer => {
  // This is a more complete valid MP3 file with proper headers and frames
  const bytes = [
    0xff, 0xfb, 0x30, 0xc4, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    0x00, 0x00, 0x00, 0x00, 0x54, 0x41, 0x47, 0x53, 0x69, 0x6c, 0x65, 0x6e,
    0x74, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
  ];

  return new Uint8Array(bytes).buffer;
};

/**
 * Load the silent audio file or create a silent buffer
 * @returns Promise with the silent audio buffer
 */
export const loadSilentAudio = async (): Promise<AudioBuffer | null> => {
  const audioContext = getAudioContext();
  if (!audioContext) return null;

  try {
    // Try multiple approaches to get a valid silent audio buffer

    // First try: Create silent buffer programmatically
    try {
      const buffer = createSilentBuffer(audioContext);
      console.log("Using programmatically created silent buffer.");
      return buffer;
    } catch (error) {
      console.warn("Failed to create silent buffer programmatically:", error);
    }

    // Second try: Create from valid MP3 bytes
    try {
      const buffer = await audioContext.decodeAudioData(createValidMP3Buffer());
      console.log("Using embedded valid MP3 buffer for silence.");
      return buffer;
    } catch (error) {
      console.warn("Failed to decode valid MP3 buffer:", error);
    }

    // Last resort: return null
    console.error("All silent audio creation methods failed");
    return null;
  } catch (error) {
    console.error("Fatal error in loadSilentAudio:", error);
    return null;
  }
};

/**
 * Load an audio file and decode it into an AudioBuffer
 * @param url URL of the audio file to load
 * @returns Promise that resolves to an AudioBuffer or undefined
 */
export const loadSound = async (
  url: string
): Promise<AudioBuffer | undefined> => {
  const audioContext = getAudioContext();
  if (!audioContext) return undefined; // Return undefined

  // Return cached buffer if we already loaded this sound
  if (soundBuffers.has(url)) {
    return soundBuffers.get(url); // Already AudioBuffer | undefined from Map
  }

  // If this sound previously failed, don't try again
  if (failedSounds.has(url)) {
    console.debug(`Returning undefined for previously failed sound: ${url}`);
    return undefined; // Return undefined
  }

  try {
    const response = await fetch(url);

    if (!response.ok) {
      console.warn(
        `Failed to fetch sound file ${url}: ${response.status} ${response.statusText}`
      );
      failedSounds.add(url);
      return undefined; // Return undefined
    }

    const arrayBuffer = await response.arrayBuffer();

    try {
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      soundBuffers.set(url, audioBuffer);
      return audioBuffer;
    } catch (error) {
      console.error(`Error decoding audio ${url}:`, error);
      failedSounds.add(url);
      return undefined; // Return undefined
    }
  } catch (error) {
    console.error(`Error loading sound ${url}:`, error);
    failedSounds.add(url);
    return undefined; // Return undefined
  }
};

/**
 * Play a sound by URL
 * @param url URL of the sound to play
 * @param volume Volume level (0.0 to 1.0)
 * @param loop Whether to loop the sound
 * @returns Promise that resolves to the AudioBufferSourceNode or null
 */
export const playSound = async (
  url: string,
  volume: number = 1.0,
  loop: boolean = false
): Promise<AudioBufferSourceNode | null> => {
  const audioContext = getAudioContext();
  if (!audioContext) return null;

  try {
    if (audioContext.state === "suspended") {
      try {
        await audioContext.resume();
      } catch (error) {
        console.warn("Failed to resume audio context:", error);
        // Don't necessarily return null here, attempt to play anyway
      }
    }

    let buffer = soundBuffers.get(url);
    if (!buffer) {
      buffer = await loadSound(url); // loadSound now returns AudioBuffer | undefined
    }

    if (!buffer) {
      console.warn(`Cannot play sound ${url}, buffer is undefined.`);
      return null; // Can't play if buffer is undefined
    }

    const source = audioContext.createBufferSource();
    source.buffer = buffer;

    const gainNode = audioContext.createGain();
    gainNode.gain.value = Math.max(0, Math.min(1, volume)); // Clamp volume

    source.connect(gainNode);
    gainNode.connect(audioContext.destination);

    source.loop = loop;
    source.start(0);

    return source;
  } catch (error) {
    console.error(`Error playing sound ${url}:`, error);
    return null;
  }
};

/**
 * Preload all necessary sounds
 * @returns Promise that resolves when all sounds are loaded or attempted
 */
export const preloadSounds = async (): Promise<void> => {
  console.log("Preloading sounds...");
  const audioContext = getAudioContext();
  if (!audioContext) {
    console.warn("Cannot preload sounds, AudioContext not available.");
    return;
  }

  const loadPromises: Promise<AudioBuffer | undefined>[] = [];

  // Iterate over sound categories with explicit type
  for (const categoryKey in SOUND_PATHS) {
    const category = categoryKey as SoundCategory;

    // Removed the check that skipped the 'ui' category

    // Load sounds for the current category
    const categoryPaths = SOUND_PATHS[category];

    // Check if categoryPaths is actually an object (like ui, effects or ambient)
    if (typeof categoryPaths === "object" && categoryPaths !== null) {
      // Define a type for the keys within a specific category
      type SoundKey = keyof typeof categoryPaths;

      for (const soundKey in categoryPaths) {
        // Cast the key to the specific SoundKey type
        const key = soundKey as SoundKey;
        // Ensure categoryPaths[key] exists and is a string before accessing it
        // This requires asserting the type for indexing, or checking type
        const path = (categoryPaths as any)[key];

        if (typeof path === "string") {
          // Check if already loaded or failed to avoid redundant fetches
          if (!soundBuffers.has(path) && !failedSounds.has(path)) {
            loadPromises.push(loadSound(path));
          }
        } else {
          console.warn(`Invalid sound path found for ${category}/${key}`);
        }
      }
    }
  }

  // Wait for all relevant preload attempts to complete
  try {
    await Promise.all(loadPromises);
    console.log("Sound preloading complete");
  } catch (error) {
    console.error("Error during sound preloading:", error);
  }
};

/**
 * Unlock AudioContext across all browsers
 * This is a more robust implementation that works across Chrome, Safari, Firefox and mobile browsers
 * Based on best practices from multiple sources
 */
export const unlockAudio = async (): Promise<boolean> => {
  console.log("Attempting to unlock audio context...");

  const audioContext = getAudioContext();
  if (!audioContext) {
    console.warn("No audio context available to unlock");
    return false;
  }

  // If context is already running, we're good
  if (audioContext.state === "running") {
    console.log("Audio context already running");
    return true;
  }

  // Resume the AudioContext first
  try {
    await audioContext.resume();
    console.log("Audio context resumed by user interaction");

    // Create and play a silent buffer to fully unlock Audio on iOS and other mobile browsers
    // This technique works across browsers including Safari iOS
    const buffer = audioContext.createBuffer(1, 1, 22050);
    const source = audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(audioContext.destination);

    console.log("Using programmatically created silent buffer.");

    // Different browsers use different methods
    if (source.start) {
      source.start(0);
    } else if (source.noteOn) {
      source.noteOn(0);
    }

    return audioContext.state === "running";
  } catch (error) {
    console.error("Failed to unlock audio context:", error);
    return false;
  }
};

/**
 * Get or load an audio buffer, with caching.
 * Returns undefined if loading fails or context is unavailable.
 */
export const getOrLoadAudioBuffer = async (
  url: string
): Promise<AudioBuffer | undefined> => {
  const audioContext = getAudioContext();
  if (!audioContext) return undefined;

  if (audioBufferCache[url]) {
    return audioBufferCache[url];
  }

  let buffer: AudioBuffer | undefined = undefined; // Initialize as undefined
  try {
    buffer = await loadSound(url); // Should now correctly assign AudioBuffer | undefined
    if (buffer) {
      audioBufferCache[url] = buffer;
    }
  } catch (error) {
    console.error(`Failed to get or load audio buffer for ${url}:`, error);
  }
  return buffer; // Return the buffer (which might be undefined)
};

export default preloadSounds;
