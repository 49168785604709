import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { useTheme } from "../context/ThemeContext";

/** @jsxImportSource @emotion/react */

interface IconButtonProps {
  icon: string; // Material icon name
  onClick: (e?: React.MouseEvent) => void;
  title?: string;
  size?: "small" | "medium" | "large";
  variant?: "primary" | "accent" | "transparent";
  css?: SerializedStyles;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  title,
  size = "medium",
  variant = "primary",
  ...props
}) => {
  const { currentTheme } = useTheme();
  const themeColors = currentTheme.colorScheme;

  // Get size dimensions based on the size prop
  const getSizeStyles = () => {
    switch (size) {
      case "small":
        return {
          padding: "4px",
          fontSize: "18px",
          width: "28px",
          height: "28px",
        };
      case "large":
        return {
          padding: "10px",
          fontSize: "28px",
          width: "48px",
          height: "48px",
        };
      case "medium":
      default:
        return {
          padding: "6px",
          fontSize: "24px",
          width: "36px",
          height: "36px",
        };
    }
  };

  // Get color styles based on the variant prop
  const getVariantStyles = () => {
    switch (variant) {
      case "accent":
        return {
          backgroundColor: `${themeColors.accent}22`,
          color: themeColors.accent,
          hoverBg: `${themeColors.accent}44`,
          activeBg: `${themeColors.accent}66`,
        };
      case "transparent":
        return {
          backgroundColor: "transparent",
          color: themeColors.text,
          hoverBg: `${themeColors.primary}22`,
          activeBg: `${themeColors.primary}44`,
        };
      case "primary":
      default:
        return {
          backgroundColor: `${themeColors.primary}22`,
          color: themeColors.primary,
          hoverBg: `${themeColors.primary}44`,
          activeBg: `${themeColors.primary}66`,
        };
    }
  };

  const sizeStyles = getSizeStyles();
  const variantStyles = getVariantStyles();

  return (
    <button
      type="button"
      onClick={onClick}
      title={title}
      css={css`
        background-color: ${variantStyles.backgroundColor};
        color: ${variantStyles.color};
        border: none;
        border-radius: 4px;
        width: ${sizeStyles.width};
        height: ${sizeStyles.height};
        padding: ${sizeStyles.padding};
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;
        font-size: ${sizeStyles.fontSize};

        &:hover {
          background-color: ${variantStyles.hoverBg};
        }

        &:active {
          background-color: ${variantStyles.activeBg};
          transform: scale(0.95);
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px ${themeColors.primary}44;
        }
      `}
      {...props}
    >
      <span className="material-symbols-outlined">{icon}</span>
    </button>
  );
};

export default IconButton;
