import React, { useState, useEffect, useCallback, useRef } from "react";
import { css, keyframes } from "@emotion/react";
import { useTheme } from "../context/ThemeContext";
import { useQuantumCircuitSimulator } from "../hooks/useQuantumCircuitSimulator";
import QuantumCanvas from "./QuantumCanvas";
import QuantumControlPanel from "./QuantumControlPanel";

/** @jsxImportSource @emotion/react */

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const glowPulse = keyframes`
  0% {
    box-shadow: 0 0 10px rgba(0, 200, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 200, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 200, 255, 0.5);
  }
`;

const scanline = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
`;

const toastFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

interface QuantumVisualizerProps {
  width?: string;
  height?: string;
}

export const QuantumVisualizer: React.FC<QuantumVisualizerProps> = ({
  width = "100%",
  height = "600px",
}) => {
  const { currentTheme } = useTheme();
  const themeColors = currentTheme.colorScheme;
  const containerRef = useRef<HTMLDivElement>(null);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [isSimulating, setIsSimulating] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const {
    qubitDisplay,
    selectedQubitId,
    operations,
    pendingGate,
    handleOperationButtonClick: applyOperation,
    handleQubitSelection: handleQubitClick,
    updateQubitPhysics,
    resetCircuit,
    runSimulation,
    addQubit: addCircuitQubit,
  } = useQuantumCircuitSimulator();

  const entangleMode = pendingGate?.gate === "CNOT";
  const swapMode = pendingGate?.gate === "SWAP";
  const entangleSourceId = entangleMode ? pendingGate.controlQubit : null;
  const swapSourceId = swapMode ? pendingGate.controlQubit : null;

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setCanvasDimensions({
          width: containerRef.current.clientWidth,
          height: containerRef.current.clientHeight,
        });
      }
    };

    updateDimensions();

    const resizeObserver = new ResizeObserver(updateDimensions);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleCanvasClick = useCallback(() => {
    handleQubitClick(null);
  }, [handleQubitClick]);

  const handleRunSimulation = useCallback(() => {
    setIsSimulating(true);
    runSimulation();
    setTimeout(() => setIsSimulating(false), 2000);
  }, [runSimulation]);

  const addQubit = useCallback(() => {
    addCircuitQubit();
    setToastMessage(`Qubit ${qubitDisplay.length} added to circuit`);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  }, [addCircuitQubit, qubitDisplay.length]);

  return (
    <div
      ref={containerRef}
      css={css`
        width: ${width};
        height: ${height};
        display: flex;
        flex-direction: column;
        color: ${themeColors.text};
        background: linear-gradient(
          135deg,
          ${themeColors.background}E6,
          ${themeColors.background}99
        );
        border: 1px solid ${themeColors.primary}40;
        overflow: hidden;
        box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.4),
          0 0 15px rgba(0, 200, 255, 0.1);
        border-radius: 4px;
        position: relative;
        animation: ${fadeIn} 0.4s ease-out;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 1px;
          background: linear-gradient(
            to right,
            transparent,
            ${themeColors.primary}80,
            transparent
          );
          opacity: 0.7;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          background: linear-gradient(
            to right,
            transparent,
            ${themeColors.primary}80,
            transparent
          );
          opacity: 0.7;
        }
      `}
    >
      {showToast && (
        <div
          css={css`
            position: absolute;
            top: 60px;
            left: 50%;
            transform: translateX(-50%);
            background-color: ${themeColors.secondary};
            color: ${themeColors.background};
            padding: 8px 15px;
            border-radius: 4px;
            z-index: 100;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
            font-family: monospace;
            animation: ${toastFade} 3s ease-in-out forwards;
          `}
        >
          {toastMessage}
        </div>
      )}
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
          overflow: hidden;
          z-index: 1;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            background: linear-gradient(
              180deg,
              rgba(0, 200, 255, 0.03) 0%,
              rgba(0, 0, 0, 0) 5%,
              rgba(0, 0, 0, 0) 95%,
              rgba(0, 200, 255, 0.03) 100%
            );
            opacity: 0.4;
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            background: repeating-linear-gradient(
              0deg,
              transparent,
              transparent 2px,
              rgba(0, 200, 255, 0.03) 3px,
              transparent 4px
            );
            pointer-events: none;
            opacity: 0.3;
          `}
        />
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            background: rgba(0, 200, 255, 0.01);
            animation: ${scanline} 8s linear infinite;
            pointer-events: none;
            opacity: 0.2;
          `}
        />
      </div>

      <div
        css={css`
          position: absolute;
          top: 10px;
          left: 15px;
          font-family: "Courier New", monospace;
          font-size: 12px;
          opacity: 0.6;
          user-select: none;
          z-index: 2;
        `}
      >
        QUANTUM CIRCUIT SIMULATOR v1.0.2
      </div>

      <QuantumControlPanel
        operations={operations}
        selectedQubitId={selectedQubitId}
        entangleMode={entangleMode}
        swapMode={swapMode}
        applyOperation={applyOperation}
        addQubit={addQubit}
        resetQubits={resetCircuit}
      />

      <div
        css={css`
          flex: 1;
          position: relative;
          overflow: hidden;
          padding: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <div
          css={css`
            position: relative;
            width: 100%;
            height: ${canvasDimensions.height}px;
            margin-bottom: 15px;
            background: ${themeColors.background}90;
            border-radius: 4px;
            border: 1px solid ${themeColors.primary}20;
            box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            transition: box-shadow 0.3s ease;

            ${isSimulating &&
            `animation: ${glowPulse} 1.5s infinite;
               box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.2), 0 0 15px rgba(0, 200, 255, 0.4);`}
          `}
        >
          <QuantumCanvas
            qubits={qubitDisplay}
            selectedQubitId={selectedQubitId}
            entangleMode={entangleMode}
            swapMode={swapMode}
            entangleSourceId={entangleSourceId}
            swapSourceId={swapSourceId}
            onQubitClick={handleQubitClick}
            onCanvasClick={handleCanvasClick}
            updateQubitPositions={updateQubitPhysics}
          />

          {isSimulating && (
            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(0, 0, 0, 0.5);
                backdrop-filter: blur(3px);
                color: ${themeColors.accent};
                font-family: "Courier New", monospace;
                font-size: 20px;
                text-shadow: 0 0 10px ${themeColors.accent};
                z-index: 10;

                &::after {
                  content: "SIMULATING...";
                  animation: pulse 1.5s infinite;
                }

                @keyframes pulse {
                  0% {
                    opacity: 0.5;
                  }
                  50% {
                    opacity: 1;
                  }
                  100% {
                    opacity: 0.5;
                  }
                }
              `}
            />
          )}
        </div>

        {(entangleMode || swapMode) && pendingGate && (
          <div
            css={css`
              position: absolute;
              bottom: 15px;
              left: 50%;
              transform: translateX(-50%);
              background-color: ${themeColors.accent}E0;
              color: ${themeColors.background};
              border: 1px solid ${themeColors.accent};
              padding: 8px 15px;
              border-radius: 4px;
              text-align: center;
              font-size: 13px;
              font-family: monospace;
              box-shadow: 0 2px 15px ${themeColors.accent}50;
              pointer-events: none;
              animation: ${fadeIn} 0.3s ease-out;
              z-index: 3;
            `}
          >
            {entangleMode
              ? `CNOT Mode: Select target qubit for control Qubit ${pendingGate.controlQubit}`
              : `SWAP Mode: Select second qubit to swap with Qubit ${pendingGate.controlQubit}`}
          </div>
        )}

        <div
          css={css`
            display: flex;
            gap: 15px;
            margin-top: 10px;
          `}
        >
          <button
            onClick={resetCircuit}
            css={css`
              background-color: ${themeColors.primary}80;
              color: ${themeColors.text};
              border: 1px solid ${themeColors.primary}80;
              padding: 8px 15px;
              border-radius: 4px;
              cursor: pointer;
              font-family: monospace;
              font-weight: bold;
              box-shadow: 0 2px 10px ${themeColors.primary}30;
              transition: all 0.2s ease;
              &:hover {
                background-color: ${themeColors.primary}A0;
                transform: translateY(-1px);
                box-shadow: 0 4px 12px ${themeColors.primary}40;
              }
              &:active {
                transform: translateY(0);
                box-shadow: 0 2px 8px ${themeColors.primary}30;
              }
            `}
          >
            Reset Circuit
          </button>

          <button
            onClick={handleRunSimulation}
            disabled={isSimulating}
            css={css`
              background-color: ${themeColors.secondary}CC;
              color: black;
              border: 1px solid ${themeColors.secondary};
              padding: 8px 20px;
              border-radius: 4px;
              cursor: pointer;
              font-family: monospace;
              font-weight: bold;
              box-shadow: 0 2px 10px ${themeColors.secondary}50;
              transition: all 0.2s ease;
              position: relative;
              overflow: hidden;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: -100%;
                width: 100%;
                height: 100%;
                background: linear-gradient(
                  90deg,
                  transparent,
                  rgba(255, 255, 255, 0.2),
                  transparent
                );
                transition: 0.5s;
                pointer-events: none;
              }

              &:hover {
                background-color: ${themeColors.secondary};
                transform: translateY(-1px);
                box-shadow: 0 4px 15px ${themeColors.secondary}60;

                &::before {
                  left: 100%;
                }
              }

              &:active {
                transform: translateY(0);
                box-shadow: 0 2px 8px ${themeColors.secondary}50;
              }

              &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
                background-color: ${themeColors.secondary}80;
              }
            `}
          >
            Run Simulation
          </button>
        </div>
      </div>

      <div
        css={css`
          position: absolute;
          bottom: 7px;
          right: 10px;
          font-family: "Courier New", monospace;
          font-size: 11px;
          opacity: 0.5;
          user-select: none;
          z-index: 2;
        `}
      >
        AEGIS QUANTUM ENGINE
      </div>
    </div>
  );
};

export default QuantumVisualizer;
