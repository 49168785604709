import React, { useCallback, useState, useEffect } from "react";
import { css } from "@emotion/react";
import { useWindowManager, WindowType } from "../context/WindowManagerContext";
import { useTheme } from "../context/ThemeContext";
import { createRgba } from "../utils/colorUtils";
import { useAudio } from "../context/AudioContext";
import IconButton from "./IconButton";
import StartMenu from "./StartMenu";
import KeyboardShortcutsHelp from "./KeyboardShortcutsHelp";
import Footer, {
  TOGGLE_COPYRIGHT_EVENT,
  TOGGLE_TECH_INFO_EVENT,
} from "./Footer";

/** @jsxImportSource @emotion/react */

// New Taskbar Clock component defined within Desktop.tsx
const TaskbarClock: React.FC = () => {
  const [time, setTime] = useState(new Date());
  const [expanded, setExpanded] = useState(false); // State for expanded view
  const { currentTheme } = useTheme();
  const themeColors = currentTheme.colorScheme;
  const { playUISound } = useAudio(); // Get audio hook

  useEffect(() => {
    const intervalId = setInterval(() => {
      // console.log("Tick - updating time");
      setTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // Toggle expanded view
  const toggleExpanded = () => {
    setExpanded(!expanded);
    playUISound(expanded ? "close" : "open"); // Play sound on toggle
  };

  const formatTime = (date: Date): string => {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit", // Re-added seconds
    });
  };

  // Format Date function
  const formatDate = (date: Date): string => {
    return date.toLocaleDateString([], {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  };

  return (
    <div
      css={css`
        position: relative; /* Needed for positioning the expanded view */
        display: flex;
        align-items: center;
        // Removed positioning styles, portal, etc.
        font-family: "Share Tech Mono", monospace;
        padding: 5px 8px; // Adjusted padding
        border-radius: 4px;
        color: ${themeColors.text}; // Use text color, primary might be too bright
        font-size: 13px; // Adjusted font size
        background-color: transparent; // Make background transparent to blend
        border: 1px solid transparent; // Add transparent border for spacing consistency
        transition: all 0.2s ease;

        &:hover {
          background-color: ${createRgba(themeColors.primary, 0.1)};
          border-color: ${createRgba(themeColors.primary, 0.3)};
          color: ${themeColors.primary};
        }
      `}
      onClick={toggleExpanded} // Add click handler
    >
      <span
        className="material-symbols-outlined"
        style={{ fontSize: "16px", marginRight: "6px" }} // Adjusted margin
      >
        schedule
      </span>
      {formatTime(time)}

      {/* Expanded view */}
      {expanded && (
        <div
          css={css`
            position: absolute;
            bottom: 45px; /* Position above the taskbar */
            right: 0;
            width: 280px; /* Adjust width as needed */
            padding: 15px;
            background-color: ${createRgba(themeColors.background, 0.95)};
            border: 1px solid ${themeColors.primary};
            border-radius: 6px;
            box-shadow: 0 0 20px ${createRgba(themeColors.primary, 0.4)};
            color: ${themeColors.text};
            font-size: 13px;
            backdrop-filter: blur(10px);
            z-index: 150; /* Ensure it's above other taskbar items */
            animation: clockFadeIn 0.2s ease-out;
            font-family: ${currentTheme.fontFamily};

            @keyframes clockFadeIn {
              from {
                opacity: 0;
                transform: translateY(10px);
              }
              to {
                opacity: 1;
                transform: translateY(0);
              }
            }
          `}
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
        >
          <div
            css={css`
              /* Full Date */
              font-size: 16px;
              color: ${themeColors.primary};
              margin-bottom: 10px;
              text-align: center;
              border-bottom: 1px solid ${createRgba(themeColors.primary, 0.3)};
              padding-bottom: 8px;
            `}
          >
            {formatDate(time)}
          </div>
          <div
            css={css`
              /* Time with seconds */
              text-align: center;
              font-size: 20px;
              color: ${themeColors.accent || themeColors.primary};
              text-shadow: 0 0 5px ${createRgba(themeColors.primary, 0.5)};
            `}
          >
            {formatTime(time)}
          </div>
          {/* Add more info here if desired */}
        </div>
      )}
    </div>
  );
};

// App icon component
interface AppIconProps {
  icon: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const AppIcon: React.FC<AppIconProps> = ({
  icon,
  label,
  onClick,
  disabled,
  style,
}) => {
  const { currentTheme } = useTheme();
  const themeColors = currentTheme.colorScheme;

  return (
    <div
      className="app-icon"
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px;
        width: 110px;
        cursor: ${disabled ? "not-allowed" : "pointer"};
        opacity: ${disabled ? 0.5 : 1};
        pointer-events: ${disabled ? "none" : "all"};
        transition: all 0.2s ease;
        position: relative;
        color: ${themeColors.text};

        &:hover {
          transform: ${disabled ? "none" : "scale(1.05)"};

          &::before {
            content: "";
            position: absolute;
            top: -5px;
            left: -5px;
            right: -5px;
            bottom: -5px;
            border: ${disabled
              ? "none"
              : `1px solid ${createRgba(themeColors.primary, 0.5)}`};
            border-radius: 5px;
            pointer-events: none;
          }

          .icon-container {
            background-color: ${disabled
              ? createRgba(themeColors.secondary, 0.3)
              : createRgba(themeColors.primary, 0.4)};
            border-color: ${disabled
              ? createRgba(themeColors.primary, 0.3)
              : themeColors.primary};
            box-shadow: ${disabled
              ? `0 0 8px ${createRgba(themeColors.primary, 0.3)}`
              : `0 0 15px ${createRgba(themeColors.primary, 0.6)}`};
          }

          .icon-span {
            color: ${disabled ? themeColors.primary : themeColors.accent};
            transform: ${disabled ? "none" : "scale(1.1)"};
          }

          .label-container {
            background-color: ${disabled
              ? createRgba(themeColors.background, 0.8)
              : createRgba(themeColors.primary, 0.3)};
            border-color: ${disabled
              ? createRgba(themeColors.primary, 0.4)
              : createRgba(themeColors.primary, 0.6)};
            max-width: ${disabled ? "100%" : "100%"};
          }
        }
      `}
      onClick={disabled ? undefined : onClick}
      style={style}
    >
      <div
        className="icon-container"
        css={css`
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${createRgba(themeColors.secondary, 0.3)};
          border-radius: 8px;
          margin-bottom: 8px;
          border: 1px solid ${createRgba(themeColors.primary, 0.3)};
          position: relative;
          overflow: hidden;
          transition: all 0.3s ease;
          box-shadow: 0 0 8px ${createRgba(themeColors.primary, 0.3)};
        `}
      >
        <span
          className="material-symbols-outlined icon-span"
          css={css`
            font-size: 32px;
            color: ${themeColors.primary};
            text-shadow: 0 0 8px ${createRgba(themeColors.primary, 0.7)};
            transition: all 0.3s ease;
          `}
        >
          {icon}
        </span>
      </div>
      <div
        className="label-container"
        css={css`
          font-size: 13px;
          color: ${themeColors.text};
          text-align: center;
          text-shadow: 0 0 5px ${createRgba(themeColors.primary, 0.5)};
          background: ${createRgba(themeColors.background, 0.8)};
          padding: 6px 8px;
          border-radius: 4px;
          width: 100%;
          white-space: normal;
          overflow: visible;
          line-height: 1.3;
          min-height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid ${createRgba(themeColors.primary, 0.4)};
          font-family: ${currentTheme.fontFamily};
          letter-spacing: 0.5px;
          transition: all 0.3s ease;
          font-weight: 500;
          box-shadow: 0 2px 5px ${createRgba(themeColors.background, 0.5)};
        `}
      >
        {label}
      </div>
    </div>
  );
};

// Define props for Desktop component
interface DesktopProps {
  children?: React.ReactNode;
}

// Main Desktop component
const Desktop: React.FC<DesktopProps> = ({ children }) => {
  const {
    windows, // Get windows list
    restoreWindow, // Get restore function
    addWindow,
    arrangeWindows,
    minimizeAllWindows,
  } = useWindowManager();
  const { playUISound } = useAudio();
  const { currentTheme } = useTheme();
  const themeColors = currentTheme.colorScheme;

  const [showShortcuts, setShowShortcuts] = useState(false);
  const [isStartMenuOpen, setIsStartMenuOpen] = useState(false);
  const [showCopyrightMenu, setShowCopyrightMenu] = useState(false);

  // Simple document click handler to close menu
  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      // Don't close if clicking inside the copyright menu or on the copyright element itself
      const clickedInsideCopyrightMenu = (e.target as Element)?.closest(
        '[data-copyright-menu="true"]'
      );
      const clickedOnCopyright = (e.target as Element)?.closest(
        '[data-copyright-text="true"]'
      );

      if (
        showCopyrightMenu &&
        !clickedInsideCopyrightMenu &&
        !clickedOnCopyright
      ) {
        setShowCopyrightMenu(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [showCopyrightMenu]);

  // Toggle start menu
  const toggleStartMenu = useCallback(() => {
    playUISound("click");
    setIsStartMenuOpen(!isStartMenuOpen);
  }, [isStartMenuOpen, playUISound]);

  // Close start menu
  const closeStartMenu = useCallback(() => {
    setIsStartMenuOpen(false);
  }, []);

  const openFileExplorer = useCallback(() => {
    playUISound("click");
    closeStartMenu();
    addWindow({
      id: `fileExplorer-${Date.now()}`,
      appId: WindowType.FileExplorer,
      title: "File Explorer",
      position: { x: 180 + Math.random() * 50, y: 180 + Math.random() * 50 },
      size: { width: 750, height: 500 },
      minSize: { width: 300, height: 200 },
    });
  }, [addWindow, playUISound, closeStartMenu]);

  const openTerminal = useCallback(() => {
    playUISound("click");
    closeStartMenu();
    addWindow({
      id: `terminal-${Date.now()}`,
      title: "Terminal v1.0.0",
      appId: WindowType.Terminal,
      position: { x: 150, y: 150 },
      size: { width: 800, height: 500 },
      minSize: { width: 300, height: 200 },
    });
  }, [addWindow, playUISound, closeStartMenu]);

  const openQuantumVisualizer = useCallback(() => {
    playUISound("click");
    closeStartMenu();
    addWindow({
      id: `quantum-visualizer-${Date.now()}`,
      title: "Quantum Visualizer",
      appId: WindowType.QuantumVisualizer,
      position: { x: 200, y: 200 },
      size: { width: 600, height: 400 },
      minSize: { width: 300, height: 200 },
    });
  }, [addWindow, playUISound, closeStartMenu]);

  const openThemeSelector = useCallback(() => {
    playUISound("click");
    closeStartMenu();
    addWindow({
      id: `theme-selector-${Date.now()}`,
      title: "Theme & Appearance",
      appId: WindowType.ThemeSelector,
      position: { x: 250, y: 120 },
      size: { width: 400, height: 550 },
      minSize: { width: 350, height: 450 },
    });
  }, [addWindow, playUISound, closeStartMenu]);

  const openLogs = useCallback(() => {
    playUISound("click");
    closeStartMenu();
    addWindow({
      id: `logs-${Date.now()}`,
      title: "System Logs",
      appId: WindowType.Logs,
      position: { x: 220 + Math.random() * 50, y: 130 + Math.random() * 50 },
      size: { width: 800, height: 600 },
      minSize: { width: 400, height: 300 },
    });
  }, [addWindow, playUISound, closeStartMenu]);

  const openCodex = useCallback(() => {
    playUISound("click");
    closeStartMenu();
    addWindow({
      id: `codex-${Date.now()}`,
      title: "AZIMUTH Codex",
      appId: WindowType.Codex,
      position: { x: 350, y: 150 },
      size: { width: 800, height: 600 },
      minSize: { width: 500, height: 400 },
    });
  }, [addWindow, playUISound, closeStartMenu]);

  // Function to open Music Player
  const openMusicPlayer = useCallback(() => {
    playUISound("click");
    closeStartMenu(); // Close start menu if open
    addWindow({
      appId: WindowType.MusicPlayer,
      title: "Music Player",
      position: { x: 300 + Math.random() * 50, y: 200 + Math.random() * 50 },
      size: { width: 700, height: 450 },
      minSize: { width: 300, height: 200 },
    });
  }, [addWindow, playUISound, closeStartMenu]);

  // Define desktop app icons
  const desktopApps = [
    {
      id: "terminal",
      icon: "terminal",
      label: "Terminal",
      action: openTerminal,
    },
    {
      id: "files",
      icon: "folder_open",
      label: "File Explorer",
      action: openFileExplorer,
    },
    {
      id: "visualizer",
      icon: "bubble_chart",
      label: "Quantum Visualizer",
      action: () => {
        // Disabled - do nothing
        console.log("Quantum Visualizer is currently unavailable");
      },
      disabled: true,
    },
    {
      id: "logs",
      icon: "subject",
      label: "System Logs",
      action: openLogs,
    },
    {
      id: "codex",
      icon: "menu_book",
      label: "Codex",
      action: () => {
        // Disabled - do nothing
        console.log("Codex is currently unavailable");
      },
      disabled: true,
    },
    {
      id: "themes",
      icon: "palette",
      label: "Appearance",
      action: openThemeSelector,
    },
    {
      id: "music",
      icon: "music_note",
      label: "Music Player",
      action: openMusicPlayer,
    },
    {
      id: "fish",
      icon: "waves",
      label: "Fisch (updating)",
      action: () => {
        // Assuming 'FISCH' type will be added
        console.log("Fisch (updating) is currently unavailable");
      },
      disabled: true,
    },
  ];

  // Create app definitions for the start menu
  const appDefinitions = [
    {
      key: "terminal",
      title: "Terminal",
      icon: <span className="material-symbols-outlined">terminal</span>,
      action: openTerminal,
    },
    {
      key: "files",
      title: "File Explorer",
      icon: <span className="material-symbols-outlined">folder_open</span>,
      action: openFileExplorer,
    },
    {
      key: "music",
      title: "Music Player",
      icon: <span className="material-symbols-outlined">music_note</span>,
      action: () => {
        addWindow({
          title: "Music Player",
          appId: WindowType.MusicPlayer,
          position: { x: 150, y: 150 },
          size: { width: 700, height: 450 },
          minSize: { width: 300, height: 200 },
        });
      },
    },
    {
      key: "logs",
      title: "System Logs",
      icon: <span className="material-symbols-outlined">subject</span>,
      action: openLogs,
    },
    {
      key: "themes",
      title: "Theme Settings",
      icon: <span className="material-symbols-outlined">palette</span>,
      action: openThemeSelector,
    },
    {
      key: "restart",
      title: "Restart System",
      icon: <span className="material-symbols-outlined">restart_alt</span>,
      action: () => {
        closeStartMenu();

        // Create overlay for restart animation
        const overlay = document.createElement("div");
        overlay.style.position = "fixed";
        overlay.style.top = "0";
        overlay.style.left = "0";
        overlay.style.right = "0";
        overlay.style.bottom = "0";
        overlay.style.backgroundColor = "rgba(0, 0, 0, 0.9)";
        overlay.style.zIndex = "10000";
        overlay.style.display = "flex";
        overlay.style.justifyContent = "center";
        overlay.style.alignItems = "center";
        overlay.style.flexDirection = "column";
        overlay.style.color = themeColors.primary;
        overlay.style.fontFamily = "'Share Tech Mono', monospace";
        overlay.style.transition = "all 0.5s ease";

        // Add a pulsing effect
        const pulse = document.createElement("div");
        pulse.style.width = "100px";
        pulse.style.height = "100px";
        pulse.style.borderRadius = "50%";
        pulse.style.backgroundColor = themeColors.primary;
        pulse.style.opacity = "0.7";
        pulse.style.position = "absolute";
        pulse.style.animation = "pulse 2s infinite";

        // Add the animations
        const style = document.createElement("style");
        style.textContent = `
          @keyframes pulse {
            0% { transform: scale(0.8); opacity: 0.7; }
            50% { transform: scale(1.2); opacity: 0.3; }
            100% { transform: scale(0.8); opacity: 0.7; }
          }
          @keyframes fadeIn {
            0% { opacity: 0; transform: translateY(-20px); }
            100% { opacity: 1; transform: translateY(0); }
          }
          @keyframes glitch {
            0% { transform: translate(0) }
            20% { transform: translate(-3px, 3px) }
            40% { transform: translate(-3px, -3px) }
            60% { transform: translate(3px, 3px) }
            80% { transform: translate(3px, -3px) }
            100% { transform: translate(0) }
          }
        `;
        document.head.appendChild(style);

        // Add system message container
        const systemMessages = document.createElement("div");
        systemMessages.style.display = "flex";
        systemMessages.style.flexDirection = "column";
        systemMessages.style.alignItems = "center";
        systemMessages.style.position = "relative";
        systemMessages.style.zIndex = "10";

        const text = document.createElement("div");
        text.textContent = "SYSTEM RESTART INITIATED";
        text.style.fontSize = "24px";
        text.style.marginBottom = "10px";
        text.style.zIndex = "10";
        text.style.animation = "fadeIn 0.5s ease-out";
        text.style.position = "relative";

        const subtext = document.createElement("div");
        subtext.textContent = "Please wait...";
        subtext.style.fontSize = "16px";
        subtext.style.marginBottom = "20px";
        subtext.style.opacity = "0.8";
        subtext.style.animation = "fadeIn 0.5s ease-out 0.3s forwards";
        subtext.style.opacity = "0";

        // Add a progress indicator
        const progressContainer = document.createElement("div");
        progressContainer.style.width = "300px";
        progressContainer.style.height = "4px";
        progressContainer.style.backgroundColor = "rgba(255, 255, 255, 0.2)";
        progressContainer.style.borderRadius = "2px";
        progressContainer.style.marginTop = "20px";
        progressContainer.style.position = "relative";
        progressContainer.style.overflow = "hidden";

        const progress = document.createElement("div");
        progress.style.position = "absolute";
        progress.style.top = "0";
        progress.style.left = "0";
        progress.style.height = "100%";
        progress.style.width = "0%";
        progress.style.backgroundColor = themeColors.primary;
        progress.style.transition = "width 3s cubic-bezier(0.1, 0.9, 0.2, 1)";

        // Add status log element
        const statusLog = document.createElement("div");
        statusLog.style.marginTop = "20px";
        statusLog.style.fontSize = "14px";
        statusLog.style.fontFamily = "monospace";
        statusLog.style.textAlign = "left";
        statusLog.style.width = "80%";
        statusLog.style.maxWidth = "500px";
        statusLog.style.opacity = "0.8";

        progressContainer.appendChild(progress);
        systemMessages.appendChild(text);
        systemMessages.appendChild(subtext);
        systemMessages.appendChild(progressContainer);
        systemMessages.appendChild(statusLog);

        overlay.appendChild(pulse);
        overlay.appendChild(systemMessages);
        document.body.appendChild(overlay);

        // Add log messages
        const addLogMessage = (message: string, delay: number) => {
          setTimeout(() => {
            const logEntry = document.createElement("div");
            logEntry.textContent = `> ${message}`;
            logEntry.style.animation = "fadeIn 0.3s ease-out";
            statusLog.appendChild(logEntry);
          }, delay);
        };

        // Start restart sequence with sound first
        addLogMessage("Initializing restart sequence...", 0);

        // Play restart sound then continue with animations
        const soundPromise = playUISound("restart") as Promise<boolean>;
        soundPromise
          .then(() => {
            // Sequence events
            setTimeout(() => {
              progress.style.width = "30%";
              addLogMessage("Unloading modules...", 0);
            }, 300);

            setTimeout(() => {
              progress.style.width = "50%";
              addLogMessage("Preparing system state...", 800);
              text.style.animation = "glitch 0.3s infinite";
            }, 1200);

            setTimeout(() => {
              progress.style.width = "70%";
              addLogMessage("Clearing runtime cache...", 1500);
            }, 2000);

            setTimeout(() => {
              progress.style.width = "90%";
              addLogMessage("Reboot sequence initiated...", 2200);
              // Add final glitch effect
              overlay.style.animation = "glitch 0.2s infinite";
            }, 2800);

            setTimeout(() => {
              progress.style.width = "100%";
              addLogMessage("System restarting now.", 2800);
            }, 3300);

            // Set a timeout to reload the page after animations
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          })
          .catch(() => {
            // Even if sound fails, continue with restart
            console.warn(
              "Restart sound failed to play, continuing restart sequence"
            );

            setTimeout(() => {
              progress.style.width = "100%";
              addLogMessage("System restarting now.", 500);

              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }, 1000);
          });
      },
    },
    {
      key: "shutdown",
      title: "Shutdown",
      icon: (
        <span className="material-symbols-outlined">power_settings_new</span>
      ),
      action: () => {
        closeStartMenu();

        // Show shutdown animation/screen
        const overlay = document.createElement("div");
        overlay.style.position = "fixed";
        overlay.style.top = "0";
        overlay.style.left = "0";
        overlay.style.right = "0";
        overlay.style.bottom = "0";
        overlay.style.backgroundColor = "rgba(0, 0, 0, 0.9)";
        overlay.style.zIndex = "10000";
        overlay.style.display = "flex";
        overlay.style.justifyContent = "center";
        overlay.style.alignItems = "center";
        overlay.style.flexDirection = "column";
        overlay.style.color = themeColors.primary;
        overlay.style.fontFamily = "'Share Tech Mono', monospace";
        overlay.style.transition = "all 1.5s ease"; // Faster transition

        // Add the animations
        const style = document.createElement("style");
        style.textContent = `
          @keyframes fadeIn {
            0% { opacity: 0; transform: translateY(-10px); }
            100% { opacity: 1; transform: translateY(0); }
          }
          @keyframes pulse {
            0% { text-shadow: 0 0 5px ${themeColors.primary}, 0 0 10px ${themeColors.primary}; }
            50% { text-shadow: 0 0 15px ${themeColors.primary}, 0 0 20px ${themeColors.primary}; }
            100% { text-shadow: 0 0 5px ${themeColors.primary}, 0 0 10px ${themeColors.primary}; }
          }
          @keyframes scanline {
            0% { transform: translateY(0); }
            100% { transform: translateY(100vh); }
          }
        `;
        document.head.appendChild(style);

        // Add scanline effect
        const scanline = document.createElement("div");
        scanline.style.position = "absolute";
        scanline.style.top = "0";
        scanline.style.left = "0";
        scanline.style.width = "100%";
        scanline.style.height = "2px";
        scanline.style.backgroundColor = `rgba(${parseInt(
          themeColors.primary.slice(1, 3),
          16
        )}, 
                                             ${parseInt(
                                               themeColors.primary.slice(3, 5),
                                               16
                                             )}, 
                                             ${parseInt(
                                               themeColors.primary.slice(5, 7),
                                               16
                                             )}, 0.3)`;
        scanline.style.zIndex = "10001";
        scanline.style.animation = "scanline 8s linear infinite";

        // Add logo element
        const logo = document.createElement("div");
        logo.style.fontSize = "40px";
        logo.style.marginBottom = "20px";
        logo.style.opacity = "0";
        logo.style.animation = "fadeIn 0.5s ease forwards, pulse 2s infinite"; // Faster animation
        logo.textContent = "A.Z.I.M.U.T.H";

        const text = document.createElement("div");
        text.textContent = "SYSTEM SHUTDOWN INITIATED";
        text.style.fontSize = "24px";
        text.style.marginBottom = "40px";
        text.style.opacity = "0";
        text.style.animation = "fadeIn 0.5s 0.2s ease forwards"; // Faster animation

        // Add status log element
        const statusLog = document.createElement("div");
        statusLog.style.marginTop = "20px";
        statusLog.style.fontSize = "14px";
        statusLog.style.fontFamily = "monospace";
        statusLog.style.textAlign = "left";
        statusLog.style.width = "80%";
        statusLog.style.maxWidth = "500px";
        statusLog.style.opacity = "0";
        statusLog.style.animation = "fadeIn 0.5s 0.4s ease forwards"; // Faster animation

        overlay.appendChild(scanline);
        overlay.appendChild(logo);
        overlay.appendChild(text);
        overlay.appendChild(statusLog);
        document.body.appendChild(overlay);

        // Add log messages
        const addLogMessage = (message: string, delay: number) => {
          setTimeout(() => {
            const logEntry = document.createElement("div");
            logEntry.textContent = `> ${message}`;
            logEntry.style.animation = "fadeIn 0.3s ease-out";
            statusLog.appendChild(logEntry);
          }, delay);
        };

        // Initial message
        addLogMessage("Initiating shutdown sequence...", 300); // Faster timing

        // Play shutdown sound then continue with animations
        const shutdownPromise = playUISound("shutdown") as Promise<boolean>;
        shutdownPromise
          .then(() => {
            // Show status messages with faster timing
            addLogMessage("Saving system state...", 600);
            addLogMessage("Unloading core modules...", 900);
            addLogMessage("Disabling user interface...", 1200);
            addLogMessage("Shutdown complete.", 1500);

            // Faster shutdown animation
            setTimeout(() => {
              logo.style.animation = "pulse 2s infinite";
              text.textContent = "SHUTDOWN IN PROGRESS";

              // Fade to black faster
              setTimeout(() => {
                overlay.style.backgroundColor = "#000000";
                logo.style.animation = "none";
                logo.style.opacity = "0.5";
                text.style.opacity = "0.5";
                statusLog.style.opacity = "0.3";

                // Start final fade out faster
                setTimeout(() => {
                  overlay.style.backgroundColor = "#000000";
                  logo.style.opacity = "0";
                  text.style.opacity = "0";
                  statusLog.style.opacity = "0";
                  scanline.style.opacity = "0";

                  // Try to close the window faster
                  setTimeout(() => {
                    window.close();

                    // Fallback if window.close doesn't work
                    setTimeout(() => {
                      // Clear all DOM content and show offline message
                      document.body.innerHTML = "";
                      document.body.style.backgroundColor = "#000000";
                      document.body.style.display = "flex";
                      document.body.style.justifyContent = "center";
                      document.body.style.alignItems = "center";
                      document.body.style.height = "100vh";
                      document.body.style.pointerEvents = "none"; // Prevent interactions
                      document.title = "Singularity is Coming - OFFLINE";

                      // Add offline message
                      const offlineMsg = document.createElement("div");
                      offlineMsg.textContent = "SYSTEM OFFLINE";
                      offlineMsg.style.color = "#333";
                      offlineMsg.style.fontFamily =
                        "'Share Tech Mono', monospace";
                      offlineMsg.style.fontSize = "24px";
                      offlineMsg.style.textAlign = "center";

                      const closeMsg = document.createElement("div");
                      closeMsg.textContent = "Close browser tab to exit";
                      closeMsg.style.color = "#222";
                      closeMsg.style.fontFamily =
                        "'Share Tech Mono', monospace";
                      closeMsg.style.fontSize = "14px";
                      closeMsg.style.marginTop = "10px";

                      const container = document.createElement("div");
                      container.appendChild(offlineMsg);
                      container.appendChild(closeMsg);
                      document.body.appendChild(container);
                    }, 200); // Faster
                  }, 500); // Faster
                }, 1000); // Faster
              }, 1500); // Faster
            }, 1800); // Faster
          })
          .catch(() => {
            // Even if sound fails, continue with shutdown
            console.warn(
              "Shutdown sound failed to play, continuing shutdown sequence"
            );

            // Show remaining status messages with compressed timing
            addLogMessage("Saving system state...", 200);
            addLogMessage("Unloading core modules...", 400);
            addLogMessage("Disabling user interface...", 600);
            addLogMessage("Shutdown complete.", 800);

            // Compressed shutdown animation
            setTimeout(() => {
              overlay.style.backgroundColor = "#000000";
              logo.style.opacity = "0";
              text.style.opacity = "0";
              statusLog.style.opacity = "0";

              setTimeout(() => {
                // Clear all DOM content and show offline message
                document.body.innerHTML = "";
                document.body.style.backgroundColor = "#000000";
                document.body.style.pointerEvents = "none";
                document.title = "Singularity is Coming - OFFLINE";

                // Add minimal offline message
                const offlineMsg = document.createElement("div");
                offlineMsg.textContent = "SYSTEM OFFLINE";
                offlineMsg.style.position = "fixed";
                offlineMsg.style.top = "50%";
                offlineMsg.style.left = "50%";
                offlineMsg.style.transform = "translate(-50%, -50%)";
                offlineMsg.style.color = "#333";
                offlineMsg.style.fontFamily = "'Share Tech Mono', monospace";
                offlineMsg.style.fontSize = "24px";
                document.body.appendChild(offlineMsg);
              }, 800); // Faster
            }, 1000); // Faster
          });
      },
    },
  ];

  return (
    <div
      css={css`
        width: 100%;
        height: 100vh;
        background: ${themeColors.background};
        color: ${themeColors.text};
        overflow: hidden;
        position: relative;
        font-family: ${currentTheme.fontFamily ||
        "'Share Tech Mono', monospace"};
        user-select: none;
      `}
    >
      {/* Start Menu */}
      <StartMenu
        isOpen={isStartMenuOpen}
        onClose={closeStartMenu}
        apps={appDefinitions}
      />

      {/* Desktop Area */}
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 40px; /* Leave space for taskbar */
          display: flex;
          flex-wrap: wrap;
          padding: 20px;
          align-content: flex-start;
          overflow: hidden;
          background-image: ${currentTheme.background?.path
            ? `url(${currentTheme.background.path})`
            : "none"};
          background-size: cover;
          background-position: center;
        `}
      >
        {/* Desktop Icons */}
        <div
          css={css`
            /* Grid layout for diagnostics */
            position: absolute;
            top: 20px;
            left: 20px;
            height: calc(100vh - 60px);
            display: grid; /* Use Grid */
            grid-auto-flow: column; /* Fill columns first */
            grid-template-rows: repeat(
              auto-fill,
              120px
            ); /* Define row height (adjust as needed based on AppIcon size+margin) */
            grid-auto-columns: 120px; /* Define column width */
            gap: 15px; /* Spacing between icons */
            max-width: calc(100vw - 40px);
            max-height: calc(
              100vh - 60px
            ); /* Ensure container doesn't exceed viewport */
            overflow: auto; /* Allow scrolling for diagnostics if needed */
            z-index: 5; /* Increased z-index */
            pointer-events: none;

            & > .app-icon {
              pointer-events: all;
              /* Remove margin from AppIcon itself if grid gap is used */
              margin: 0 !important;
            }
          `}
        >
          {desktopApps.map((app, index) => {
            // Log each app being processed
            console.log(
              `Mapping desktop app: ${app.label}, Disabled: ${app.disabled}`
            );
            // Attempt to place 'fisch' in second column, first row (grid-column: 2 / span 1; grid-row: 1 / span 1;)
            // Note: This manual placement might overlap if other icons also try to occupy the same space.
            const gridStyle =
              app.id === "fisch" ? { gridColumn: "2", gridRow: "1" } : {};
            return (
              <AppIcon
                key={app.id}
                icon={app.icon}
                label={app.label}
                onClick={app.action}
                disabled={app.disabled}
                style={gridStyle} // Use inline style for simplicity in diagnostics
              />
            );
          })}
        </div>

        {/* Render children (WindowContainer) here */}
        {children}

        {/* Keyboard shortcuts help */}
        <KeyboardShortcutsHelp
          visible={showShortcuts}
          onClose={() => setShowShortcuts(false)}
        />
      </div>

      {/* Taskbar */}
      <div
        css={css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 40px;
          background-color: ${createRgba(themeColors.background, 0.8)};
          border-top: 1px solid ${createRgba(themeColors.primary, 0.5)};
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 15px;
          backdrop-filter: blur(10px);
          z-index: 100;
        `}
      >
        {/* Left side - Start button only */}
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <IconButton
            icon="hub"
            title="Start Menu (Ctrl+M)"
            css={css`
              position: relative;
              background-color: ${isStartMenuOpen
                ? createRgba(themeColors.primary, 0.3)
                : "transparent"};
              color: ${isStartMenuOpen
                ? themeColors.accent || themeColors.primary
                : themeColors.primary};
              border: 1px solid
                ${isStartMenuOpen
                  ? themeColors.primary
                  : createRgba(themeColors.primary, 0.5)};
              &:hover {
                border-color: ${themeColors.primary};
                box-shadow: 0 0 10px ${createRgba(themeColors.primary, 0.5)};
              }
            `}
            onClick={toggleStartMenu}
          />
        </div>

        {/* Center - Minimized apps & Copyright notice */}
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 6px; // Add gap for multiple items
            flex-grow: 1; // Allow this section to grow
            justify-content: center; // Center items
            overflow: hidden; // Prevent overflow
          `}
        >
          {/* Render minimized window buttons */}
          {windows
            .filter((win) => win.isMinimized)
            .map((win) => (
              <button
                key={win.id}
                css={css`
                  background-color: ${createRgba(themeColors.secondary, 0.3)};
                  border: 1px solid ${createRgba(themeColors.primary, 0.5)};
                  color: ${themeColors.text};
                  padding: 4px 10px;
                  border-radius: 4px;
                  cursor: pointer;
                  font-size: 12px;
                  font-family: ${currentTheme.fontFamily};
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 150px; // Limit width
                  transition: all 0.2s ease;
                  opacity: 0.9;

                  &:hover {
                    background-color: ${createRgba(themeColors.primary, 0.2)};
                    border-color: ${themeColors.primary};
                    color: ${themeColors.primary};
                    opacity: 1;
                  }
                `}
                title={`Restore ${win.title}`}
                onClick={() => {
                  playUISound("open");
                  restoreWindow(win.id);
                }}
              >
                <span
                  className="material-symbols-outlined"
                  css={css`
                    font-size: 14px;
                    margin-right: 5px;
                    vertical-align: middle;
                    // Consider adding getIconForWindowType here too
                  `}
                >
                  {/* Placeholder icon - could use window type icon */}{" "}
                  web_asset
                </span>
                {win.title}
              </button>
            ))}

          {/* Copyright Notice */}
          <div
            data-copyright-text="true"
            css={css`
              position: relative;
              display: flex;
              align-items: center;
              color: ${themeColors.primary};
              font-size: 11px;
              opacity: 0.8;
              transition: all 0.2s ease;
              cursor: pointer;
              background-color: ${createRgba(themeColors.primary, 0.05)};
              padding: 4px 10px;
              border-radius: 4px;
              border: 1px solid ${createRgba(themeColors.primary, 0.1)};
              margin-left: ${windows.some((w) => w.isMinimized) ? "10px" : "0"};

              &:hover {
                opacity: 1;
                background-color: ${createRgba(themeColors.primary, 0.1)};
                border-color: ${createRgba(themeColors.primary, 0.3)};
                box-shadow: 0 0 8px ${createRgba(themeColors.primary, 0.2)};
                animation: subtlePulse 2s infinite ease-in-out;
              }
            `}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event bubbling
              playUISound("click");
              setShowCopyrightMenu(!showCopyrightMenu);
              console.log(
                "Copyright menu click - new state:",
                !showCopyrightMenu
              );
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "12px", marginRight: "4px" }}
            >
              copyright
            </span>
            © 2025 Onirocrisia 𝄞 • Dawn Break
            {/* Menu Dropdown */}
            {showCopyrightMenu && (
              <div
                data-copyright-menu="true"
                css={css`
                  position: fixed; /* Change to fixed instead of absolute */
                  bottom: 55px; /* Position above the taskbar with more space */
                  left: 50%;
                  transform: translateX(-50%);
                  background-color: ${createRgba(themeColors.background, 0.95)};
                  border: 2px solid ${themeColors.primary};
                  border-radius: 6px;
                  width: 240px;
                  z-index: 10000; /* Very high z-index to ensure it's above everything */
                  box-shadow: 0 0 15px ${createRgba(themeColors.primary, 0.4)};
                  backdrop-filter: blur(5px);
                  opacity: 1; /* Ensure full opacity */
                  visibility: visible; /* Ensure visibility */
                  pointer-events: auto; /* Ensure it receives mouse events */
                  animation: fadeInUp 0.2s ease-out;

                  @keyframes fadeInUp {
                    from {
                      opacity: 0;
                      transform: translate(-50%, 10px);
                    }
                    to {
                      opacity: 1;
                      transform: translate(-50%, 0);
                    }
                  }
                `}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  css={css`
                    padding: 10px 15px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: background-color 0.2s;

                    &:hover {
                      background-color: ${createRgba(themeColors.primary, 0.2)};
                    }
                  `}
                  onClick={(e) => {
                    e.stopPropagation();
                    playUISound("click");
                    window.dispatchEvent(
                      new CustomEvent(TOGGLE_COPYRIGHT_EVENT)
                    );
                    setShowCopyrightMenu(false);
                  }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "16px", marginRight: "12px" }}
                  >
                    copyright
                  </span>
                  <span>License & Copyright</span>
                </div>

                <div
                  css={css`
                    padding: 10px 15px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: background-color 0.2s;

                    &:hover {
                      background-color: ${createRgba(themeColors.primary, 0.2)};
                    }
                  `}
                  onClick={(e) => {
                    e.stopPropagation();
                    playUISound("click");
                    window.dispatchEvent(
                      new CustomEvent(TOGGLE_TECH_INFO_EVENT)
                    );
                    setShowCopyrightMenu(false);
                  }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "16px", marginRight: "12px" }}
                  >
                    integration_instructions
                  </span>
                  <span>Technologies & Libraries</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right side - Window controls, shortcuts button, and clock */}
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 10px;
          `}
        >
          {/* Window layout buttons */}
          <IconButton
            icon="grid_view"
            title="Arrange Windows in Grid"
            onClick={() => {
              playUISound("click");
              arrangeWindows("grid");
            }}
          />
          <IconButton
            icon="view_week"
            title="Arrange Windows Vertically"
            onClick={() => {
              playUISound("click");
              arrangeWindows("vertical");
            }}
          />
          <IconButton
            icon="format_line_spacing"
            title="Arrange Windows Horizontally"
            onClick={() => {
              playUISound("click");
              arrangeWindows("horizontal");
            }}
          />

          {/* Keyboard shortcuts button */}
          <IconButton
            icon="keyboard"
            title="Keyboard Shortcuts (Ctrl+/) "
            onClick={() => setShowShortcuts(true)}
          />

          {/* Clock */}
          <TaskbarClock />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Desktop;
