/**
 * Utility functions for color manipulation in the AZIMUTH UI
 */

/**
 * Converts a hex color code to RGBA format with specified opacity
 *
 * @param hex - Hex color string (e.g., "#00FF00" or "#0F0")
 * @param opacity - Opacity value between 0 and 1
 * @returns RGBA color string
 */
export const hexToRgba = (hex: string, opacity: number): string => {
  // Ensure opacity is valid
  const validOpacity = Math.max(0, Math.min(1, opacity));

  // Expand shorthand form (e.g. "#03F") to full form (e.g. "#0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const expandedHex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  // Parse hex to RGB
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(expandedHex);
  if (!result) {
    // Fallback to black if unable to parse
    return `rgba(0, 0, 0, ${validOpacity})`;
  }

  // Convert hex values to decimal
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return `rgba(${r}, ${g}, ${b}, ${validOpacity})`;
};

/**
 * Creates an RGBA color string with specified opacity from a color value
 * Works with hex colors, rgb/rgba strings, or theme color values
 *
 * @param color - Input color (hex, rgb, rgba)
 * @param opacity - Opacity value between 0 and 1
 * @returns RGBA color string
 */
export const createRgba = (color: string, opacity: number): string => {
  if (!color) {
    // Fallback to black
    return `rgba(0, 0, 0, ${opacity})`;
  }

  // If color is already in rgba format, extract values and update opacity
  const rgbaMatch = color.match(
    /rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([.\d]+)\s*\)/
  );
  if (rgbaMatch) {
    return `rgba(${rgbaMatch[1]}, ${rgbaMatch[2]}, ${rgbaMatch[3]}, ${opacity})`;
  }

  // If color is in rgb format, convert to rgba with new opacity
  const rgbMatch = color.match(/rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/);
  if (rgbMatch) {
    return `rgba(${rgbMatch[1]}, ${rgbMatch[2]}, ${rgbMatch[3]}, ${opacity})`;
  }

  // Assume it's a hex color
  if (color.startsWith("#")) {
    return hexToRgba(color, opacity);
  }

  // Fallback for other color formats (names, etc.)
  return `${color.replace(/\)$/, `, ${opacity})`)}`;
};

/**
 * Adjusts the brightness of a color
 *
 * @param color - Hex color string
 * @param percent - Percent to adjust (-100 to 100)
 * @returns Adjusted hex color
 */
export const adjustBrightness = (color: string, percent: number): string => {
  const hex = color.replace(/^\s*#|\s*$/g, "");

  // convert 3 char codes to 6, e.g. `E0F` to `EE00FF`
  const r = Math.max(
    0,
    Math.min(255, parseInt(hex.substring(0, 2), 16) + percent)
  );
  const g = Math.max(
    0,
    Math.min(255, parseInt(hex.substring(2, 4), 16) + percent)
  );
  const b = Math.max(
    0,
    Math.min(255, parseInt(hex.substring(4, 6), 16) + percent)
  );

  const paddedR = r.toString(16).padStart(2, "0");
  const paddedG = g.toString(16).padStart(2, "0");
  const paddedB = b.toString(16).padStart(2, "0");

  return `#${paddedR}${paddedG}${paddedB}`;
};

/**
 * Checks if a color is light or dark
 *
 * @param color - Hex color string
 * @returns boolean - true if light, false if dark
 */
export const isLightColor = (color: string): boolean => {
  const hex = color.replace(/^\s*#|\s*$/g, "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate perceived brightness using the formula
  // (0.299*R + 0.587*G + 0.114*B)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness > 128;
};
