import React from 'react';

const Codex: React.FC = () => {
  return (
    <div style={{ padding: '1rem', color: 'inherit' }}>
      <h2>A.E.G.I.S Codex</h2>
      <p>Codex entries will appear here...</p>
      {/* TODO: Implement Codex data structure and browsing UI */}
    </div>
  );
};

export default Codex; 