import React, { useState, useEffect, useRef } from "react";
import { css, keyframes } from "@emotion/react";

/** @jsxImportSource @emotion/react */

interface TerminalProps {
  width?: string;
  height?: string;
}

// Define command history type
interface CommandHistory {
  command: string;
  output: string;
  isError?: boolean;
}

// Animation keyframes
const scanline = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0.1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: translateY(100%);
    opacity: 0.1;
  }
`;

const blink = keyframes`
  0%, 49% { opacity: 1; }
  50%, 100% { opacity: 0; }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 5px rgba(38, 218, 253, 0.3); }
  50% { box-shadow: 0 0 15px rgba(38, 218, 253, 0.8); }
  100% { box-shadow: 0 0 5px rgba(38, 218, 253, 0.3); }
`;

const Terminal: React.FC<TerminalProps> = ({
  width = "100%",
  height = "100%",
}) => {
  const [input, setInput] = useState("");
  const [commandHistory, setCommandHistory] = useState<CommandHistory[]>([
    { command: "", output: "Terminal v1.0.0" },
    { command: "", output: 'Type "help" for available commands.' },
  ]);
  const [activeEffect, setActiveEffect] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const terminalRef = useRef<HTMLDivElement>(null);

  // Commands database
  const commands: Record<
    string,
    { description: string; action: (args: string[]) => string }
  > = {
    help: {
      description: "Display available commands",
      action: () => {
        return Object.keys(commands)
          .sort()
          .map((cmd) => `${cmd.padEnd(15)} - ${commands[cmd].description}`)
          .join("\n");
      },
    },
    cls: {
      description: "Clear the terminal screen",
      action: () => {
        setTimeout(() => {
          setCommandHistory([]);
        }, 100);
        return "";
      },
    },
    echo: {
      description: "Display a message",
      action: (args) => args.join(" "),
    },
    date: {
      description: "Display current date and time",
      action: () => new Date().toString(),
    },
    whoami: {
      description: "Display current user",
      action: () => "NOT FOUND",
    },
    dir: {
      description: "List directory contents",
      action: () => {
        return (
          "Directory: /aegis/system\n\n" +
          ".                    <DIR>        06/15/2023  09:30 AM\n" +
          "..                   <DIR>        06/15/2023  09:30 AM\n" +
          "core                 <DIR>        06/15/2023  09:35 AM\n" +
          "user                 <DIR>        06/15/2023  09:40 AM\n" +
          "system               <DIR>        06/15/2023  09:32 AM\n" +
          "utils                <DIR>        06/15/2023  09:38 AM\n" +
          "aegis                1,245,184    06/15/2023  09:30 AM\n" +
          "readme.txt           2,048        06/15/2023  09:30 AM\n" +
          "librust_os           8,192,000    06/15/2023  09:31 AM\n"
        );
      },
    },
    systeminfo: {
      description: "Display system information",
      action: () => {
        return (
          "Local Machine Information:\n\n" +
          "System Type:            UNKNOWN\n" +
          "Processor:              UNKNOWN\n" +
          "BIOS Version:           UNKNOWN\n" +
          "Total Physical Memory:  UNKNOWN\n" +
          "Available Physical Memory: UNKNOWN\n" +
          "Quantum Coherence:      UNKNOWN\n" +
          "System Status:          UNKNOWN"
        );
      },
    },
    exit: {
      description: "Close the terminal",
      action: () => {
        return "Exiting terminal...";
      },
    },
    ver: {
      description: "Display AEGIS version",
      action: () =>
        "A.E.G.I.S Framework Interface [Version 1.0.0] (AZIMUTH Module)",
    },
    time: {
      description: "Display current time",
      action: () => {
        const now = new Date();
        return `Current time: ${now.getHours()}:${now
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${now.getSeconds().toString().padStart(2, "0")}`;
      },
    },
    exec: {
      description: "Execute a program",
      action: (args) => {
        if (args.length === 0) {
          return "Error: No program specified. Usage: exec [program]";
        }
        return `Attempting to execute "${
          args[0]
        }"...\n\nProcess started with PID ${Math.floor(
          Math.random() * 10000
        )}.`;
      },
    },
  };

  // Handle command execution
  const executeCommand = (cmd: string) => {
    // Trigger a "processing" effect
    setActiveEffect("processing");
    setTimeout(() => setActiveEffect(null), 300);

    const trimmedCmd = cmd.trim();
    if (!trimmedCmd) {
      addToHistory("", "");
      return;
    }

    const [command, ...args] = trimmedCmd.split(" ");
    const lowerCommand = command.toLowerCase();

    if (lowerCommand in commands) {
      const output = commands[lowerCommand].action(args);
      addToHistory(trimmedCmd, output);
    } else {
      addToHistory(
        trimmedCmd,
        `'${command}' is not recognized as an internal command or program.`,
        true
      );
    }
  };

  // Add command and output to history
  const addToHistory = (command: string, output: string, isError = false) => {
    setCommandHistory((prev) => [...prev, { command, output, isError }]);
  };

  // Handle input submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    executeCommand(input);
    setInput("");
  };

  // Focus the input when clicking on the terminal
  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Auto-scroll to bottom when new commands are added
  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [commandHistory]);

  return (
    <div
      css={css`
        width: ${width};
        height: ${height};
        background-color: rgba(0, 10, 20, 0.95);
        color: #26dafd;
        font-family: "Share Tech Mono", monospace;
        font-size: 14px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        letter-spacing: 1px;
        box-shadow: inset 0 0 30px rgba(38, 218, 253, 0.2);
        border: 1px solid rgba(38, 218, 253, 0.3);
      `}
      onClick={focusInput}
    >
      {/* Terminal content */}
      <div
        ref={terminalRef}
        css={css`
          flex: 1;
          overflow-y: auto;
          margin-bottom: 10px;
          position: relative;
          padding: 5px;

          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.3);
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(38, 218, 253, 0.4);
            &:hover {
              background: rgba(38, 218, 253, 0.6);
            }
          }
        `}
      >
        {/* Scan line effect */}
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 2px;
            background-color: rgba(38, 218, 253, 0.3);
            opacity: 0.5;
            animation: ${scanline} 4s linear infinite;
            z-index: 10;
            pointer-events: none;
          `}
        ></div>

        {/* CRT scanlines */}
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: repeating-linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0) 1px,
              rgba(38, 218, 253, 0.03) 1px,
              rgba(38, 218, 253, 0.03) 2px
            );
            pointer-events: none;
          `}
        ></div>

        {/* Processing effect overlay */}
        {activeEffect === "processing" && (
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(38, 218, 253, 0.1);
              pointer-events: none;
              z-index: 5;
            `}
          ></div>
        )}

        {/* Command history */}
        {commandHistory.map((entry, index) => (
          <div
            key={index}
            css={css`
              transition: opacity 0.2s ease;
              position: relative;
            `}
          >
            {entry.command && (
              <div
                css={css`
                  margin-top: 8px;
                  display: flex;
                `}
              >
                <span
                  css={css`
                    color: rgba(38, 218, 253, 0.8);
                    margin-right: 8px;
                    text-shadow: 0 0 5px rgba(38, 218, 253, 0.5);
                    display: flex;
                    align-items: center;
                  `}
                >
                  <span
                    css={css`
                      display: inline-block;
                      width: 8px;
                      height: 8px;
                      border-radius: 50%;
                      background-color: rgba(38, 218, 253, 0.8);
                      margin-right: 5px;
                      box-shadow: 0 0 5px rgba(38, 218, 253, 0.5);
                    `}
                  ></span>
                  AZIMUTH&gt;
                </span>
                <span
                  css={css`
                    color: #ffffff;
                  `}
                >
                  {entry.command}
                </span>
              </div>
            )}
            {entry.output && (
              <div
                css={css`
                  white-space: pre-wrap;
                  margin-top: 5px;
                  margin-left: 20px;
                  color: ${entry.isError
                    ? "rgba(255, 100, 100, 0.9)"
                    : "rgba(255, 255, 255, 0.9)"};
                `}
              >
                {entry.output}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Input form */}
      <form
        onSubmit={handleSubmit}
        css={css`
          position: relative;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            background-color: rgba(0, 20, 40, 0.7);
            padding: 8px 12px;
            border: 1px solid rgba(38, 218, 253, 0.3);
            box-shadow: 0 0 10px rgba(38, 218, 253, 0.2);
            animation: ${pulse} 4s infinite;
            position: relative;

            &::before,
            &::after {
              content: "";
              position: absolute;
              background-color: rgba(38, 218, 253, 0.8);
            }

            &::before {
              top: -1px;
              left: 10px;
              right: 10px;
              height: 1px;
            }

            &::after {
              bottom: -1px;
              left: 10px;
              right: 10px;
              height: 1px;
            }
          `}
        >
          <span
            css={css`
              color: rgba(38, 218, 253, 0.8);
              margin-right: 8px;
              display: flex;
              align-items: center;
              text-shadow: 0 0 5px rgba(38, 218, 253, 0.5);
            `}
          >
            <span
              css={css`
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: rgba(38, 218, 253, 0.8);
                margin-right: 5px;
                box-shadow: 0 0 5px rgba(38, 218, 253, 0.5);
              `}
            ></span>
            AZIMUTH&gt;
          </span>

          <input
            ref={inputRef}
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            css={css`
              background: transparent;
              border: none;
              color: #ffffff;
              outline: none;
              font-family: inherit;
              font-size: inherit;
              flex: 1;
              letter-spacing: 1px;
              text-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
            `}
            autoFocus
            aria-label="Terminal Input"
          />

          <span
            css={css`
              width: 8px;
              height: 16px;
              background-color: #26dafd;
              display: inline-block;
              margin-left: 2px;
              animation: ${blink} 1s step-end infinite;
              box-shadow: 0 0 5px rgba(38, 218, 253, 0.8);
            `}
          ></span>
        </div>
      </form>

      {/* Terminal footer */}
      <div
        css={css`
          font-size: 10px;
          display: flex;
          justify-content: space-between;
          padding-top: 8px;
          margin-top: 10px;
          border-top: 1px solid rgba(38, 218, 253, 0.3);
          color: rgba(38, 218, 253, 0.6);
        `}
      >
        <div>SECURE MODE: ACTIVE</div>
      </div>
    </div>
  );
};

export default Terminal;
