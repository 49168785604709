import React, { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { useTheme } from "../context/ThemeContext";
import { useAudio } from "../context/AudioContext";

/** @jsxImportSource @emotion/react */

// Helper function to create RGBA color from hex (copied from Footer.tsx)
const createRgba = (hex: string, opacity: number): string => {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
  }

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

interface AppDefinition {
  key: string;
  title: string;
  icon: React.ReactNode;
  action: () => void;
}

interface StartMenuProps {
  isOpen: boolean;
  onClose: () => void;
  apps: AppDefinition[];
}

const StartMenu: React.FC<StartMenuProps> = ({ isOpen, onClose, apps }) => {
  const { currentTheme } = useTheme();
  const { playUISound } = useAudio();
  const themeColors = currentTheme.colorScheme;
  const menuRef = useRef<HTMLDivElement>(null);

  // Handle outside clicks to close the menu
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        isOpen
      ) {
        onClose();
      }
    };

    // Touch event handler with proper typing
    const handleTouchStart = (e: TouchEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        isOpen
      ) {
        onClose();
      }
    };

    // Close on escape key
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" && isOpen) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  // Don't render if not open
  if (!isOpen) return null;

  // Group apps by category
  const systemApps = apps.filter((app) =>
    ["restart", "shutdown"].includes(app.key)
  );
  const standardApps = apps.filter(
    (app) => !["restart", "shutdown"].includes(app.key)
  );

  return (
    <div
      ref={menuRef}
      css={css`
        position: absolute;
        bottom: 45px;
        left: 15px;
        width: 320px;
        max-height: 70vh;
        background-color: ${createRgba(themeColors.background, 0.85)};
        border: 1px solid ${createRgba(themeColors.primary, 0.5)};
        border-radius: 8px;
        overflow: hidden;
        z-index: 1000;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5),
          0 0 10px ${createRgba(themeColors.primary, 0.3)};
        animation: menuAppear 0.2s ease-out forwards;
        backdrop-filter: blur(8px);

        @keyframes menuAppear {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}
    >
      {/* Header */}
      <div
        css={css`
          padding: 15px;
          border-bottom: 1px solid ${createRgba(themeColors.primary, 0.3)};
          background-color: ${createRgba(themeColors.background, 0.9)};
        `}
      >
        <div
          css={css`
            font-size: 16px;
            font-weight: bold;
            color: ${themeColors.primary};
            letter-spacing: 1px;
            text-transform: uppercase;
          `}
        >
          USER: Guest
        </div>
      </div>

      {/* Application List */}
      <div
        css={css`
          max-height: 50vh;
          overflow-y: auto;
          padding: 10px 0;

          /* Scrollbar styling */
          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-track {
            background: ${createRgba(themeColors.background, 0.5)};
          }
          &::-webkit-scrollbar-thumb {
            background-color: ${createRgba(themeColors.primary, 0.5)};
            border-radius: 3px;
          }
        `}
      >
        {/* Standard apps section */}
        <div
          css={css`
            margin-bottom: 8px;
          `}
        >
          <div
            css={css`
              padding: 0 15px;
              margin-bottom: 5px;
              font-size: 12px;
              color: ${createRgba(themeColors.text, 0.7)};
              text-transform: uppercase;
              letter-spacing: 1px;
            `}
          >
            Actions
          </div>

          {standardApps.map((app) => (
            <div
              key={app.key}
              css={css`
                display: flex;
                align-items: center;
                padding: 10px 15px;
                cursor: pointer;
                transition: all 0.2s ease;

                &:hover {
                  background-color: ${createRgba(themeColors.primary, 0.15)};
                }

                &:active {
                  background-color: ${createRgba(themeColors.primary, 0.25)};
                }
              `}
              onClick={() => {
                playUISound("click");
                app.action();
              }}
            >
              <div
                css={css`
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 12px;
                  color: ${themeColors.primary};
                `}
              >
                {app.icon}
              </div>
              <div
                css={css`
                  font-size: 14px;
                  color: ${themeColors.text};
                `}
              >
                {app.title}
              </div>
            </div>
          ))}
        </div>

        {/* System section */}
        <div
          css={css`
            margin-top: 15px;
            border-top: 1px solid ${createRgba(themeColors.primary, 0.2)};
            padding-top: 10px;
          `}
        >
          <div
            css={css`
              padding: 0 15px;
              margin-bottom: 5px;
              font-size: 12px;
              color: ${createRgba(themeColors.text, 0.7)};
              text-transform: uppercase;
              letter-spacing: 1px;
            `}
          >
            System
          </div>

          {systemApps.map((app) => (
            <div
              key={app.key}
              css={css`
                display: flex;
                align-items: center;
                padding: 10px 15px;
                cursor: pointer;
                transition: all 0.2s ease;

                &:hover {
                  background-color: ${app.key === "shutdown"
                    ? createRgba("#ff3b30", 0.15)
                    : createRgba(themeColors.primary, 0.15)};
                }

                &:active {
                  background-color: ${app.key === "shutdown"
                    ? createRgba("#ff3b30", 0.25)
                    : createRgba(themeColors.primary, 0.25)};
                }
              `}
              onClick={() => {
                playUISound(app.key === "shutdown" ? "shutdown" : "click");
                app.action();
              }}
            >
              <div
                css={css`
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 12px;
                  color: ${app.key === "shutdown"
                    ? "#ff3b30"
                    : themeColors.primary};
                `}
              >
                {app.icon}
              </div>
              <div
                css={css`
                  font-size: 14px;
                  color: ${app.key === "shutdown"
                    ? "#ff3b30"
                    : themeColors.text};
                `}
              >
                {app.title}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <div
        css={css`
          padding: 15px;
          border-top: 1px solid ${createRgba(themeColors.primary, 0.3)};
          background-color: ${createRgba(themeColors.background, 0.9)};
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          css={css`
            font-size: 12px;
            color: ${themeColors.text};
          `}
        >
          AZIMUTH v1.0.0-alpha
        </div>
        <div
          css={css`
            font-size: 12px;
            color: ${themeColors.primary};
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 4px;

            &:hover {
              background-color: ${createRgba(themeColors.primary, 0.15)};
            }
          `}
          onClick={() => {
            playUISound("click");
            onClose();
          }}
        >
          Close
        </div>
      </div>
    </div>
  );
};

export default StartMenu;
