import React, { useState, Suspense, useEffect } from "react";
import { css, keyframes } from "@emotion/react";
import { useWindowManager } from "../context/WindowManagerContext";
import { useTheme } from "../context/ThemeContext";
import IconButton from "./IconButton";
import { hexToRgba } from "../utils/helpers";
import { useAudio } from "../context/AudioContext";

/** @jsxImportSource @emotion/react */

// Animation keyframes (make glow color dynamic based on theme)
const glowPulse = (color: string) => keyframes`
  0% { box-shadow: 0 0 10px ${hexToRgba(color, 0.3)}; }
  50% { box-shadow: 0 0 20px ${hexToRgba(color, 0.5)}; }
  100% { box-shadow: 0 0 10px ${hexToRgba(color, 0.3)}; }
`;

// Simple loading indicator style (use theme color)
const loadingStyle = (textColor: string) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${textColor};
  font-family: "Share Tech Mono", monospace;
`;

const WindowContainer: React.FC = () => {
  const {
    windows,
    activeWindowId,
    activateWindow,
    removeWindow,
    minimizeWindow,
    maximizeWindow,
    restoreWindow,
    updateWindowPosition,
    updateWindowSize,
    getComponentForAppId,
    snapWindowToPosition,
    arrangeWindows,
    focusNextWindow,
    focusPreviousWindow,
  } = useWindowManager();
  const { currentTheme } = useTheme();
  const { playUISound } = useAudio();
  const themeColors = currentTheme.colorScheme;

  // State for tracking dragging
  type DragState = {
    isDragging: boolean;
    startPos: { x: number; y: number };
    startWindowPos: { x: number; y: number };
    windowId: string | null;
  };

  // State for tracking resizing
  type ResizeState = {
    isResizing: boolean;
    startPos: { x: number; y: number };
    startWindowSize: { width: number; height: number };
    windowId: string | null;
    direction: string;
  };

  const [dragState, setDragState] = useState<DragState>({
    isDragging: false,
    startPos: { x: 0, y: 0 },
    startWindowPos: { x: 0, y: 0 },
    windowId: null,
  });

  const [resizeState, setResizeState] = useState<ResizeState>({
    isResizing: false,
    startPos: { x: 0, y: 0 },
    startWindowSize: { width: 0, height: 0 },
    windowId: null,
    direction: "",
  });

  // Add state for context menu
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    windowId: "",
  });

  // Add keyboard shortcut handling
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Only handle shortcuts if there's an active window
      if (!activeWindowId) return;

      // Ctrl+Alt shortcuts for window management
      if (e.ctrlKey && e.altKey) {
        switch (e.key) {
          case "ArrowLeft":
            // Snap left
            snapWindowToPosition(activeWindowId, "left");
            e.preventDefault();
            break;
          case "ArrowRight":
            // Snap right
            snapWindowToPosition(activeWindowId, "right");
            e.preventDefault();
            break;
          case "ArrowUp":
            // Maximize or snap top
            snapWindowToPosition(activeWindowId, "top");
            e.preventDefault();
            break;
          case "ArrowDown":
            // Minimize or snap bottom
            snapWindowToPosition(activeWindowId, "bottom");
            e.preventDefault();
            break;
          case "m":
            // Maximize
            maximizeWindow(activeWindowId);
            e.preventDefault();
            break;
          case "n":
            // Minimize
            minimizeWindow(activeWindowId);
            e.preventDefault();
            break;
          case "c":
            // Center
            snapWindowToPosition(activeWindowId, "center");
            e.preventDefault();
            break;
          case "g":
            // Grid layout
            arrangeWindows("grid");
            e.preventDefault();
            break;
          case "Tab":
            // Cycle through windows
            if (e.shiftKey) {
              focusPreviousWindow();
            } else {
              focusNextWindow();
            }
            e.preventDefault();
            break;
        }
      } else if (e.altKey) {
        // Alt+Tab for window switching
        if (e.key === "Tab") {
          if (e.shiftKey) {
            focusPreviousWindow();
          } else {
            focusNextWindow();
          }
          e.preventDefault();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    activeWindowId,
    snapWindowToPosition,
    maximizeWindow,
    minimizeWindow,
    restoreWindow,
    arrangeWindows,
    focusNextWindow,
    focusPreviousWindow,
  ]);

  // Handle context menu
  const handleContextMenu = (e: React.MouseEvent, windowId: string) => {
    e.preventDefault();
    playUISound("click");
    setContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      windowId,
    });
  };

  // Close context menu when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      if (contextMenu.visible) {
        setContextMenu((prev) => ({ ...prev, visible: false }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contextMenu.visible]);

  // Start window drag
  const handleWindowDragStart = (
    event: React.MouseEvent,
    windowId: string,
    position: { x: number; y: number }
  ) => {
    event.preventDefault();
    activateWindow(windowId);

    setDragState({
      isDragging: true,
      startPos: { x: event.clientX, y: event.clientY },
      startWindowPos: position,
      windowId,
    });
  };

  // Handle resize start
  const handleResizeStart = (
    event: React.MouseEvent,
    windowId: string,
    size: { width: number; height: number },
    direction: string
  ) => {
    event.preventDefault();
    activateWindow(windowId);

    setResizeState({
      isResizing: true,
      startPos: { x: event.clientX, y: event.clientY },
      startWindowSize: size,
      windowId,
      direction,
    });
  };

  // Handle mouse move for both drag and resize
  const handleMouseMove = (event: React.MouseEvent) => {
    // Handle window dragging
    if (dragState.isDragging && dragState.windowId) {
      const deltaX = event.clientX - dragState.startPos.x;
      const deltaY = event.clientY - dragState.startPos.y;

      updateWindowPosition(dragState.windowId, {
        x: dragState.startWindowPos.x + deltaX,
        y: dragState.startWindowPos.y + deltaY,
      });
    }

    // Handle window resizing
    if (resizeState.isResizing && resizeState.windowId) {
      const deltaX = event.clientX - resizeState.startPos.x;
      const deltaY = event.clientY - resizeState.startPos.y;

      const window = windows.find((w) => w.id === resizeState.windowId);
      if (!window) return;

      let newWidth = resizeState.startWindowSize.width;
      let newHeight = resizeState.startWindowSize.height;
      let newX = window.position.x;
      let newY = window.position.y;

      // Apply resize based on direction
      if (resizeState.direction.includes("e")) {
        newWidth = Math.max(
          window.minSize.width,
          resizeState.startWindowSize.width + deltaX
        );
      }
      if (resizeState.direction.includes("s")) {
        newHeight = Math.max(
          window.minSize.height,
          resizeState.startWindowSize.height + deltaY
        );
      }
      if (resizeState.direction.includes("w")) {
        const widthChange = resizeState.startWindowSize.width - deltaX;
        const currentX = window.position.x;
        if (widthChange >= window.minSize.width) {
          newWidth = widthChange;
          newX = currentX + deltaX;
        } else {
          newWidth = window.minSize.width;
          newX = currentX + (resizeState.startWindowSize.width - newWidth);
        }
        updateWindowPosition(resizeState.windowId, {
          x: newX,
          y: window.position.y,
        });
      }
      if (resizeState.direction.includes("n")) {
        const heightChange = resizeState.startWindowSize.height - deltaY;
        const currentY = window.position.y;
        if (heightChange >= window.minSize.height) {
          newHeight = heightChange;
          newY = currentY + deltaY;
        } else {
          newHeight = window.minSize.height;
          newY = currentY + (resizeState.startWindowSize.height - newHeight);
        }
        updateWindowPosition(resizeState.windowId, {
          x: window.position.x,
          y: newY,
        });
      }

      updateWindowSize(resizeState.windowId, {
        width: newWidth,
        height: newHeight,
      });
    }
  };

  // Handle mouse up to end drag/resize
  const handleMouseUp = () => {
    setDragState((prev) => ({ ...prev, isDragging: false, windowId: null }));
    setResizeState((prev) => ({ ...prev, isResizing: false, windowId: null }));
  };

  // Define resize handle styles using theme
  const resizeHandleStyle = (direction: string) => css`
    position: absolute;
    background: transparent;
    z-index: 5;
    ${direction.includes("n") ? "top: -3px;" : ""}
    ${direction.includes("s") ? "bottom: -3px;" : ""}
    ${direction.includes("w") ? "left: -3px;" : ""}
    ${direction.includes("e") ? "right: -3px;" : ""}
    ${direction === "n" || direction === "s"
      ? "left: 0; right: 0; height: 6px; cursor: ns-resize;"
      : ""}
    ${direction === "w" || direction === "e"
      ? "top: 0; bottom: 0; width: 6px; cursor: ew-resize;"
      : ""}
    ${direction === "nw"
      ? "width: 10px; height: 10px; cursor: nwse-resize; top: -3px; left: -3px;"
      : ""}
    ${direction === "ne"
      ? "width: 10px; height: 10px; cursor: nesw-resize; top: -3px; right: -3px;"
      : ""}
    ${direction === "sw"
      ? "width: 10px; height: 10px; cursor: nesw-resize; bottom: -3px; left: -3px;"
      : ""}
    ${direction === "se"
      ? "width: 10px; height: 10px; cursor: nwse-resize; bottom: -3px; right: -3px;"
      : ""}
    
    &:hover {
      // background-color: ${hexToRgba(themeColors.primary, 0.3)};
    }
  `;

  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: ${dragState.isDragging || resizeState.isResizing
          ? "auto"
          : "none"};
        z-index: ${dragState.isDragging || resizeState.isResizing
          ? 10000
          : "auto"};
      `}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {windows.map((window) => {
        // Get the lazy component for the window's appId
        const LazyComponent = getComponentForAppId(window.appId);
        const isActive = activeWindowId === window.id;

        return (
          <div
            key={window.id}
            css={css`
              position: absolute;
              top: ${window.isMaximized ? "0" : `${window.position.y}px`};
              left: ${window.isMaximized ? "0" : `${window.position.x}px`};
              width: ${window.isMaximized ? "100%" : `${window.size.width}px`};
              height: ${window.isMaximized
                ? "calc(100vh - 40px)"
                : `${window.size.height}px`};
              display: ${window.isMinimized ? "none" : "flex"};
              flex-direction: column;
              background-color: ${hexToRgba(themeColors.background, 0.85)};
              border: 1px solid
                ${isActive
                  ? themeColors.primary
                  : hexToRgba(themeColors.primary, 0.5)};
              box-shadow: ${isActive
                ? `0 0 20px ${hexToRgba(
                    themeColors.primary,
                    0.6
                  )}, 0 0 40px ${hexToRgba(themeColors.primary, 0.3)}`
                : `0 0 10px ${hexToRgba(themeColors.primary, 0.2)}`};
              z-index: ${window.zIndex};
              pointer-events: auto;
              overflow: hidden;
              animation: ${isActive
                ? css`
                    ${glowPulse(themeColors.primary)} 4s infinite alternate
                  `
                : "none"};
              transition: all 0.3s ease;
              backdrop-filter: blur(8px);
              border-radius: 3px;
              font-family: ${currentTheme.fontFamily};
            `}
            onMouseDown={() => activateWindow(window.id)}
            onContextMenu={(e) => handleContextMenu(e, window.id)}
          >
            {/* Corner decorations - Use theme primary */}
            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: 12px;
                height: 12px;
                border-top: 1px solid ${themeColors.primary};
                border-left: 1px solid ${themeColors.primary};
                z-index: 10;
                pointer-events: none;
              `}
            />
            <div
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                width: 12px;
                height: 12px;
                border-top: 1px solid ${themeColors.primary};
                border-right: 1px solid ${themeColors.primary};
                z-index: 10;
                pointer-events: none;
              `}
            />
            <div
              css={css`
                position: absolute;
                bottom: 0;
                left: 0;
                width: 12px;
                height: 12px;
                border-bottom: 1px solid ${themeColors.primary};
                border-left: 1px solid ${themeColors.primary};
                z-index: 10;
                pointer-events: none;
              `}
            />
            <div
              css={css`
                position: absolute;
                bottom: 0;
                right: 0;
                width: 12px;
                height: 12px;
                border-bottom: 1px solid ${themeColors.primary};
                border-right: 1px solid ${themeColors.primary};
                z-index: 10;
                pointer-events: none;
              `}
            />

            {/* Updated Title Bar with context menu */}
            <div
              css={css`
                height: 30px;
                background-color: ${isActive
                  ? hexToRgba(themeColors.primary, 0.3)
                  : hexToRgba(themeColors.secondary, 0.3)};
                color: ${themeColors.text};
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
                cursor: grab;
                flex-shrink: 0;
                border-bottom: 1px solid
                  ${isActive
                    ? hexToRgba(themeColors.primary, 0.5)
                    : hexToRgba(themeColors.secondary, 0.5)};
                transition: background-color 0.3s ease, border-color 0.3s ease;
                user-select: none;
              `}
              onMouseDown={(e) => {
                if ((e.target as Element).closest(".window-controls")) return;
                handleWindowDragStart(e, window.id, window.position);
              }}
              onDoubleClick={() =>
                window.isMaximized
                  ? restoreWindow(window.id)
                  : maximizeWindow(window.id)
              }
              onContextMenu={(e) => handleContextMenu(e, window.id)}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}
              >
                {/* Window icon based on type */}
                <span
                  className="material-symbols-outlined"
                  css={css`
                    font-size: 16px;
                    opacity: 0.8;
                    color: ${isActive ? themeColors.accent : themeColors.text};
                  `}
                >
                  {getIconForWindowType(window.appId)}
                </span>

                <span
                  css={css`
                    font-weight: bold;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  `}
                >
                  {window.title}
                </span>
              </div>

              <div
                className="window-controls"
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 2px;
                `}
              >
                {/* Center button - Re-added */}
                <IconButton
                  icon="center_focus_strong"
                  size="small"
                  onClick={(e) => {
                    if (e) e.stopPropagation();
                    playUISound("click");
                    snapWindowToPosition(window.id, "center");
                  }}
                  title="Center Window"
                  css={css`
                    font-size: 14px;
                    opacity: 0.7;
                    &:hover {
                      opacity: 1;
                    }
                  `}
                />

                {/* Existing window control buttons */}
                <IconButton
                  icon="minimize"
                  onClick={(e) => {
                    if (e) e.stopPropagation();
                    playUISound("click");
                    minimizeWindow(window.id);
                  }}
                  title="Minimize"
                  size="small"
                  variant="transparent"
                />

                <IconButton
                  icon={window.isMaximized ? "close_fullscreen" : "fullscreen"}
                  onClick={(e) => {
                    if (e) e.stopPropagation();
                    playUISound("click");
                    window.isMaximized
                      ? restoreWindow(window.id)
                      : maximizeWindow(window.id);
                  }}
                  title={window.isMaximized ? "Restore" : "Maximize"}
                  size="small"
                  variant="transparent"
                />

                <IconButton
                  icon="close"
                  onClick={(e) => {
                    if (e) e.stopPropagation();
                    playUISound("click");
                    removeWindow(window.id);
                  }}
                  title="Close"
                  size="small"
                  variant="accent"
                />
              </div>
            </div>

            {/* Window Content Area */}
            <div
              css={css`
                flex-grow: 1;
                overflow: auto;
                position: relative;
                background-color: ${hexToRgba(themeColors.background, 0.5)};
              `}
            >
              <Suspense
                fallback={
                  <div css={loadingStyle(themeColors.primary)}>Loading...</div>
                }
              >
                {LazyComponent && <LazyComponent />}
              </Suspense>
            </div>

            {/* Resize Handles (only if not maximized) */}
            {!window.isMaximized && (
              <>
                <div
                  css={resizeHandleStyle("n")}
                  onMouseDown={(e) =>
                    handleResizeStart(e, window.id, window.size, "n")
                  }
                />
                <div
                  css={resizeHandleStyle("s")}
                  onMouseDown={(e) =>
                    handleResizeStart(e, window.id, window.size, "s")
                  }
                />
                <div
                  css={resizeHandleStyle("w")}
                  onMouseDown={(e) =>
                    handleResizeStart(e, window.id, window.size, "w")
                  }
                />
                <div
                  css={resizeHandleStyle("e")}
                  onMouseDown={(e) =>
                    handleResizeStart(e, window.id, window.size, "e")
                  }
                />
                <div
                  css={resizeHandleStyle("nw")}
                  onMouseDown={(e) =>
                    handleResizeStart(e, window.id, window.size, "nw")
                  }
                />
                <div
                  css={resizeHandleStyle("ne")}
                  onMouseDown={(e) =>
                    handleResizeStart(e, window.id, window.size, "ne")
                  }
                />
                <div
                  css={resizeHandleStyle("sw")}
                  onMouseDown={(e) =>
                    handleResizeStart(e, window.id, window.size, "sw")
                  }
                />
                <div
                  css={resizeHandleStyle("se")}
                  onMouseDown={(e) =>
                    handleResizeStart(e, window.id, window.size, "se")
                  }
                />
              </>
            )}
          </div>
        );
      })}

      {/* Context Menu */}
      {contextMenu.visible && (
        <div
          css={css`
            position: absolute;
            top: ${contextMenu.y}px;
            left: ${contextMenu.x}px;
            background-color: ${hexToRgba(themeColors.background, 0.95)};
            border: 1px solid ${themeColors.primary};
            border-radius: 4px;
            padding: 4px 0;
            min-width: 180px;
            box-shadow: 0 0 15px ${hexToRgba(themeColors.primary, 0.5)};
            z-index: 10000;
            backdrop-filter: blur(10px);
            font-family: ${currentTheme.fontFamily};
          `}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Context menu items */}
          <ContextMenuItem
            icon="center_focus_strong"
            text="Center Window"
            onClick={() => {
              snapWindowToPosition(contextMenu.windowId, "center");
              playUISound("click");
              setContextMenu((prev) => ({ ...prev, visible: false }));
            }}
          />
          <ContextMenuItem
            icon="grid_view"
            text="Arrange Grid"
            onClick={() => {
              arrangeWindows("grid");
              playUISound("click");
              setContextMenu((prev) => ({ ...prev, visible: false }));
            }}
          />
          <ContextMenuItem
            icon="format_line_spacing"
            text="Arrange Horizontal"
            onClick={() => {
              arrangeWindows("horizontal");
              playUISound("click");
              setContextMenu((prev) => ({ ...prev, visible: false }));
            }}
          />
          <ContextMenuItem
            icon="view_week"
            text="Arrange Vertical"
            onClick={() => {
              arrangeWindows("vertical");
              playUISound("click");
              setContextMenu((prev) => ({ ...prev, visible: false }));
            }}
          />
          <div
            css={css`
              height: 1px;
              background-color: ${hexToRgba(themeColors.primary, 0.3)};
              margin: 4px 8px;
            `}
          />
          <ContextMenuItem
            icon="close"
            text="Close Window"
            onClick={() => {
              removeWindow(contextMenu.windowId);
              playUISound("click");
              setContextMenu((prev) => ({ ...prev, visible: false }));
            }}
          />
        </div>
      )}
    </div>
  );
};

// Context menu item component
interface ContextMenuItemProps {
  icon: string;
  text: string;
  onClick: () => void;
}

const ContextMenuItem: React.FC<ContextMenuItemProps> = ({
  icon,
  text,
  onClick,
}) => {
  const { currentTheme } = useTheme();
  const themeColors = currentTheme.colorScheme;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 6px 12px;
        cursor: pointer;
        &:hover {
          background-color: ${hexToRgba(themeColors.primary, 0.3)};
        }
      `}
      onClick={onClick}
    >
      <span
        className="material-symbols-outlined"
        css={css`
          font-size: 16px;
          color: ${themeColors.accent};
        `}
      >
        {icon}
      </span>
      <span
        css={css`
          color: ${themeColors.text};
        `}
      >
        {text}
      </span>
    </div>
  );
};

// Helper function to get appropriate icon for window type
const getIconForWindowType = (appId: string): string => {
  switch (appId) {
    case "Terminal":
      return "terminal";
    case "FileExplorer":
      return "folder_open";
    case "SystemMonitor":
      return "monitoring";
    case "ThemeSelector":
      return "palette";
    case "Logs":
      return "subject";
    case "Codex":
      return "menu_book";
    case "QuantumVisualizer":
      return "bubble_chart";
    default:
      return "apps";
  }
};

export default WindowContainer;
