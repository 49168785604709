/**
 * Converts a hex color string (with or without #, 3 or 6 digits) to an rgba string.
 * @param hex - The hex color string (e.g., "#FF0000" or "F00").
 * @param alpha - The alpha transparency value (0 to 1).
 * @returns The rgba color string (e.g., "rgba(255, 0, 0, 0.5)").
 */
export const hexToRgba = (hex: string, alpha: number): string => {
  let processedHex = hex.startsWith('#') ? hex.slice(1) : hex;

  // Handle shorthand hex codes (e.g., F00 -> FF0000)
  if (processedHex.length === 3) {
    processedHex = processedHex
      .split('')
      .map(char => char + char)
      .join('');
  }

  // Handle full hex codes (e.g., FF0000)
  if (processedHex.length === 6) {
    const r = parseInt(processedHex.slice(0, 2), 16);
    const g = parseInt(processedHex.slice(2, 4), 16);
    const b = parseInt(processedHex.slice(4, 6), 16);
    // Clamp alpha value between 0 and 1
    const validAlpha = Math.max(0, Math.min(1, alpha));
    return `rgba(${r}, ${g}, ${b}, ${validAlpha})`;
  }

  // Return a default (transparent black) and warn if hex is invalid
  console.warn(`Invalid hex color format: ${hex}. Returning default rgba(0,0,0,alpha).`);
  const validAlpha = Math.max(0, Math.min(1, alpha));
  return `rgba(0, 0, 0, ${validAlpha})`;
};

// Add any other shared helper functions below 