import React from "react";
import { css } from "@emotion/react";
import { useTheme } from "../context/ThemeContext";
import IconButton from "./IconButton";

/** @jsxImportSource @emotion/react */

interface KeyboardShortcutsHelpProps {
  visible: boolean;
  onClose: () => void;
}

const KeyboardShortcutsHelp: React.FC<KeyboardShortcutsHelpProps> = ({
  visible,
  onClose,
}) => {
  const { currentTheme } = useTheme();
  const themeColors = currentTheme.colorScheme;

  if (!visible) return null;

  // Create RGBA color from hex
  const createRgba = (hex: string, opacity: number): string => {
    let r = 0,
      g = 0,
      b = 0;

    // 3 digits
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
      r = parseInt(hex.slice(1, 3), 16);
      g = parseInt(hex.slice(3, 5), 16);
      b = parseInt(hex.slice(5, 7), 16);
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const shortcuts = [
    { keys: "Ctrl+Alt+ArrowLeft", description: "Snap window to left" },
    { keys: "Ctrl+Alt+ArrowRight", description: "Snap window to right" },
    { keys: "Ctrl+Alt+ArrowUp", description: "Snap window to top" },
    { keys: "Ctrl+Alt+ArrowDown", description: "Snap window to bottom" },
    { keys: "Ctrl+Alt+C", description: "Center window" },
    { keys: "Ctrl+Alt+M", description: "Maximize window" },
    { keys: "Ctrl+Alt+N", description: "Minimize window" },
    { keys: "Ctrl+Alt+G", description: "Arrange windows in grid" },
    { keys: "Alt+Tab", description: "Cycle through windows" },
    { keys: "Alt+Shift+Tab", description: "Cycle windows backwards" },
    { keys: "Right-click title bar", description: "Window context menu" },
  ];

  return (
    <div
      css={css`
        position: absolute;
        bottom: 50px;
        right: 10px;
        width: 350px;
        background-color: ${createRgba(themeColors.background, 0.9)};
        border: 1px solid ${themeColors.primary};
        border-radius: 6px;
        padding: 15px;
        box-shadow: 0 0 20px ${createRgba(themeColors.primary, 0.5)};
        backdrop-filter: blur(10px);
        z-index: 1000;
        font-family: ${currentTheme.fontFamily};
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          border-bottom: 1px solid ${createRgba(themeColors.primary, 0.4)};
          padding-bottom: 8px;
        `}
      >
        <h3
          css={css`
            margin: 0;
            color: ${themeColors.text};
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <span className="material-symbols-outlined">keyboard</span>
          Keyboard Shortcuts
        </h3>
        <IconButton icon="close" size="small" onClick={onClose} />
      </div>

      <div
        css={css`
          max-height: 300px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: ${createRgba(themeColors.primary, 0.7)};
            border-radius: 5px;
          }
        `}
      >
        {shortcuts.map((shortcut, index) => (
          <div
            key={index}
            css={css`
              display: flex;
              justify-content: space-between;
              padding: 8px 0;
              border-bottom: 1px solid ${createRgba(themeColors.primary, 0.2)};
              &:last-child {
                border-bottom: none;
              }
            `}
          >
            <div
              css={css`
                font-family: "Share Tech Mono", monospace;
                color: ${themeColors.accent};
                background-color: ${createRgba(themeColors.background, 0.6)};
                padding: 3px 6px;
                border-radius: 4px;
                border: 1px solid ${createRgba(themeColors.primary, 0.4)};
              `}
            >
              {shortcut.keys}
            </div>
            <div
              css={css`
                color: ${themeColors.text};
                flex-grow: 1;
                text-align: right;
                margin-left: 10px;
              `}
            >
              {shortcut.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyboardShortcutsHelp;
