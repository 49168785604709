import React, { ReactNode } from 'react';
import { ThemeProvider } from '@emotion/react';

// Create a custom theme extending Arwes default theme
const aegisTheme = {
  palette: {
    primary: { main: '#26dafd' },
    secondary: { main: '#51ff9d' },
    success: { main: '#51ff9d' },
    error: { main: '#ff5151' },
    warning: { main: '#ffb654' },
    info: { main: '#26dafd' },
    neutral: { main: '#d4fffd' },
    background: { main: '#042933' },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  fontFamilies: {
    title: '"Orbitron", sans-serif',
    body: '"Titillium Web", sans-serif',
    code: '"JetBrains Mono", monospace',
  },
  fontSizes: {
    header1: 28,
    header2: 24,
    header3: 20,
    header4: 16,
    paragraph: 14,
    small: 12,
    tiny: 10,
  },
};

interface ArwesThemeProviderProps {
  children: ReactNode;
}

const ArwesThemeProvider: React.FC<ArwesThemeProviderProps> = ({ children }) => {
  return (
    <ThemeProvider theme={aegisTheme}>
      {children}
    </ThemeProvider>
  );
};

export default ArwesThemeProvider; 