import React, { useState, useMemo, useRef, useEffect } from "react";
import { useLogging } from "../context/LoggingContext";
import { LogEntry, LogLevel } from "../logging/types";
import { Text } from "@arwes/react";
import { css } from "@emotion/react";
import { useTheme, ColorScheme } from "../context/ThemeContext";

/** @jsxImportSource @emotion/react */

/**
 * LOGS COMPONENT IMPLEMENTATION NOTES
 * -----------------------------------
 * ISSUE: The original implementation using Arwes' <FrameCorners> component prevented log entries
 * from rendering correctly, even though the component function executed and had data.
 *
 * DIAGNOSIS:
 * - Log entries were visible in the component state (filteredLogs length showed in console logs)
 * - When using <FrameCorners>, the content inside would not display
 * - Removing <FrameCorners> made content visible again
 * - This suggested that <FrameCorners> was interfering with React's rendering of its children
 *
 * SOLUTION:
 * - Implemented a custom frame using standard div elements and CSS that mimics the visual style
 *   of <FrameCorners> without its DOM manipulation
 * - Used pseudo-elements (::before, ::after) to create the corner accents
 * - Maintained all the filtering and display logic of the original component
 *
 * WHY THIS APPROACH:
 * - <FrameCorners> likely uses portal-like DOM manipulation that conflicts with React's
 *   ability to update its children properly
 * - A custom implementation gives us full control over the styling without the side effects
 * - This pattern can be reused for other components if similar issues arise with Arwes components
 */

// Define all available log levels for filtering
const ALL_LOG_LEVELS: LogLevel[] = ["INFO", "ERROR", "WARN", "DEBUG", "SYSTEM"];

// Helper function to get color based on log level
const getLogLevelColor = (
  level: LogLevel,
  colorScheme: ColorScheme | null
): string => {
  switch (level) {
    case "ERROR":
      return colorScheme?.warning ?? "#ff5555";
    case "WARN":
      return colorScheme?.warning ?? "#ffff00";
    case "INFO":
      return colorScheme?.primary ?? "#00ffff";
    case "DEBUG":
      return colorScheme?.secondary ?? "#d4d4d4";
    case "SYSTEM":
      return colorScheme?.accent ?? "#00ff00";
    default:
      return colorScheme?.text ?? "#ffffff";
  }
};

const Logs: React.FC = () => {
  console.log("[Logs.tsx] Component function started executing.");
  const { logs } = useLogging();
  const { currentTheme } = useTheme();
  const [filterLevel, setFilterLevel] = useState<LogLevel | "all">("all");
  const [searchTerm, setSearchTerm] = useState("");
  const logContainerRef = useRef<HTMLDivElement>(null);

  const handleLevelChange = (level: LogLevel | "all") => {
    setFilterLevel(level);
  };

  const colorScheme = currentTheme?.colorScheme;

  const filteredLogs = useMemo(() => {
    return logs.filter((log) => {
      const levelMatch = filterLevel === "all" || log.level === filterLevel;
      const searchMatch =
        searchTerm === "" ||
        log.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
        log.source?.toLowerCase().includes(searchTerm.toLowerCase());
      return levelMatch && searchMatch;
    });
  }, [logs, filterLevel, searchTerm]);

  useEffect(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [filteredLogs]);

  console.log(
    "[Logs.tsx] filteredLogs length (intermediate view):",
    filteredLogs.length
  );

  return (
    <div
      css={css`
        /* Outer div styles */
        height: 100%;
        background-color: ${colorScheme?.background ?? "rgba(0, 8, 20, 0.9)"};
        overflow: hidden;
        color-scheme: light dark;
      `}
    >
      {/* Replace FrameCorners with custom-styled div */}
      <div
        css={css`
          border: 1px solid ${colorScheme?.primary ?? "#26dafd"};
          border-radius: 4px;
          padding: 20px;
          background-color: rgba(0, 20, 40, 0.5);
          height: calc(100% - 40px);
          box-sizing: border-box;
          overflow: hidden;
          position: relative;

          /* Add corner accents to simulate FrameCorners */
          &::before,
          &::after,
          & > div::before,
          & > div::after {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            border-color: ${colorScheme?.primary ?? "#26dafd"};
          }

          &::before {
            top: 0;
            left: 0;
            border-top: 2px solid;
            border-left: 2px solid;
          }

          &::after {
            top: 0;
            right: 0;
            border-top: 2px solid;
            border-right: 2px solid;
          }

          & > div::before {
            bottom: 0;
            left: 0;
            border-bottom: 2px solid;
            border-left: 2px solid;
          }

          & > div::after {
            bottom: 0;
            right: 0;
            border-bottom: 2px solid;
            border-right: 2px solid;
          }
        `}
      >
        <div style={{ position: "relative", height: "100%" }}>
          {/* Header Section - Minimal styles */}
          <div
            css={css`
              margin-bottom: 20px;
            `}
          >
            <Text
              as="h2"
              css={css`
                color: ${colorScheme?.primary ?? "#26dafd"};
                margin-top: 0;
              `}
            >
              System Logs
            </Text>
            {/* Keep controls for structure, but minimal styling */}
            <div
              css={css`
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                align-items: center;
              `}
            >
              <input
                type="text"
                placeholder="Search logs..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                css={css`
                  padding: 5px 10px;
                  background-color: rgba(0, 20, 40, 0.3);
                  border: 1px solid ${colorScheme?.secondary ?? "#26dafd"};
                  border-radius: 3px;
                  color: ${colorScheme?.text ?? "#ffffff"};
                `}
              />
              <div
                css={css`
                  display: flex;
                  gap: 10px;
                  flex-wrap: wrap;
                `}
              >
                {ALL_LOG_LEVELS.map((level: LogLevel) => (
                  <label
                    key={level}
                    css={css`
                      display: flex;
                      align-items: center;
                      color: ${getLogLevelColor(level, colorScheme)};
                      margin-right: 10px;
                    `}
                  >
                    <input
                      type="checkbox"
                      checked={filterLevel === level || filterLevel === "all"}
                      onChange={() => handleLevelChange(level)}
                      css={css`
                        margin-right: 5px;
                      `}
                    />
                    {level}
                  </label>
                ))}
                <label
                  css={css`
                    display: flex;
                    align-items: center;
                    color: ${colorScheme?.text ?? "#ffffff"};
                  `}
                >
                  <input
                    type="checkbox"
                    checked={filterLevel === "all"}
                    onChange={() => handleLevelChange("all")}
                    css={css`
                      margin-right: 5px;
                    `}
                  />
                  All
                </label>
              </div>
            </div>
          </div>

          {/* Log Container - Basic style */}
          <div
            ref={logContainerRef}
            css={css`
              border: 1px solid ${colorScheme?.primary ?? "#26dafd"};
              background-color: rgba(0, 0, 0, 0.2);
              height: calc(100% - 100px);
              overflow-y: auto;
              padding: 10px;

              /* Basic scrollbar styling */
              &::-webkit-scrollbar {
                width: 8px;
              }
              &::-webkit-scrollbar-track {
                background: ${colorScheme?.background ?? "rgba(0, 8, 20, 0.6)"};
                border-radius: 4px;
              }
              &::-webkit-scrollbar-thumb {
                background: ${colorScheme?.primary ?? "#26dafd"};
                border-radius: 4px;
              }
            `}
          >
            {/* Log List Rendering - Keep the simple inline styles */}
            {filteredLogs.length > 0 ? (
              filteredLogs.map((log: LogEntry, index: number) => (
                <div
                  key={`${log.timestamp}-${index}`}
                  style={{
                    borderBottom: "1px dashed #555",
                    padding: "5px 0",
                    fontFamily: "monospace",
                    fontSize: "13px",
                    color: getLogLevelColor(log.level, colorScheme),
                  }}
                >
                  <span>{new Date(log.timestamp).toLocaleTimeString()}</span>
                  <span style={{ marginLeft: "10px" }}>[{log.level}]</span>
                  <span style={{ marginLeft: "10px" }}>{log.message}</span>
                </div>
              ))
            ) : (
              <p style={{ textAlign: "center", padding: "20px" }}>
                No log entries to display.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logs;
