import { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid"; // For unique gate IDs
import Complex from "complex.js"; // Import complex number library

// Re-export the type explicitly to try and satisfy the linter
export interface QubitDisplayInfo {
  id: number;
  displayState: number; // theta angle on Bloch sphere
  displayPhase: number; // phi angle on Bloch sphere
  entangled: number[];
  x: number;
  y: number;
  vx: number;
  vy: number;
}

// --- Constants ---
const initialQubitCount = 3; // Start with fewer qubits for clarity
const initialMomentCount = 10; // Number of time steps available
const initialMaxSpeed = 0.6; // Slower physics

// Define available quantum operations (consider grouping?)
const availableOperations: string[] = [
  "H",
  "X",
  "Y",
  "Z",
  "S",
  "T",
  "CNOT",
  "SWAP",
  "Measure", // Standard gates
  // Add more later: RX, RY, RZ, Barrier, etc.
];

// --- Types for Quantum Circuit Data Structures ---
export interface QuantumCircuit {
  numQubits: number;
  numMoments: number;
  gatePlacements: GatePlacement[];
}

export type QuantumStateVector = Complex[];

export interface GatePlacement {
  id: string;
  gate: string;
  qubits: number[];
  momentIndex: number;
  params?: number[];
  controlQubit?: number;
  targetQubit?: number;
}

export interface SimulationMoment {
  momentIndex: number;
  stateVector: QuantumStateVector;
}

// Helper to create an initial empty circuit
const createInitialCircuit = (
  numQubits: number,
  numMoments: number
): QuantumCircuit => ({
  numQubits,
  numMoments,
  gatePlacements: [],
});

// Helper to create initial display states for qubits
const createInitialQubitDisplay = (numQubits: number): QubitDisplayInfo[] => {
  return Array.from({ length: numQubits }, (_, index) => {
    const angle = (2 * Math.PI * index) / numQubits;

    // Create a circular arrangement
    const x = 0.5 + 0.3 * Math.cos(angle);
    const y = 0.5 + 0.3 * Math.sin(angle);

    // Slightly more energetic motion for newly added qubits
    // The last qubit (newest one) gets more initial energy
    const isNewlyAdded =
      index === numQubits - 1 && numQubits > initialQubitCount;
    const speedMultiplier = isNewlyAdded ? 0.4 : 0.1;

    // Random velocities with scaled speed
    const vx = (Math.random() - 0.5) * initialMaxSpeed * speedMultiplier;
    const vy = (Math.random() - 0.5) * initialMaxSpeed * speedMultiplier;

    return {
      id: index,
      displayState: isNewlyAdded ? Math.PI / 4 : 0, // New qubits start in slight superposition
      displayPhase: isNewlyAdded ? Math.PI / 2 : 0, // New qubits have a different phase
      entangled: [],
      x, // Position from 0-1
      y, // Position from 0-1
      vx, // Velocity X component
      vy, // Velocity Y component
    };
  });
};

// Helper to find the first available moment for a qubit (no gates placed yet)
const findFirstAvailableMoment = (
  circuit: QuantumCircuit,
  qubitId: number
): number | null => {
  // Already placed gates for this qubit
  const existingMoments = circuit.gatePlacements
    .filter((placement) => placement.qubits.includes(qubitId))
    .map((placement) => placement.momentIndex);

  // Find the first moment that's not in use
  for (let i = 0; i < circuit.numMoments; i++) {
    if (!existingMoments.includes(i)) {
      return i;
    }
  }

  // No available moments, return null
  return null;
};

// Helper for simple physics (simplified)
const updateQubitPhysics = (
  timestamp: number,
  qubits: QubitDisplayInfo[]
): QubitDisplayInfo[] => {
  // Perform simplified physics calculations
  const updatedQubits = [...qubits];

  // Constants for physics simulation
  const dampingFactor = 0.99; // Slows particles gradually
  const centerAttraction = 0.0005; // Pulls toward center
  const minDistance = 0.05; // Minimum distance between qubits
  const repulsionFactor = 0.0001; // Strength of repulsion

  // Apply physics to each qubit
  for (let i = 0; i < updatedQubits.length; i++) {
    const qubit = updatedQubits[i];

    // Apply attraction to center (0.5, 0.5)
    const dx = 0.5 - qubit.x;
    const dy = 0.5 - qubit.y;
    qubit.vx += dx * centerAttraction;
    qubit.vy += dy * centerAttraction;

    // Apply damping
    qubit.vx *= dampingFactor;
    qubit.vy *= dampingFactor;

    // Apply velocity
    qubit.x += qubit.vx;
    qubit.y += qubit.vy;

    // Keep within bounds (0-1)
    if (qubit.x < 0.1) {
      qubit.x = 0.1;
      qubit.vx *= -0.5; // Bounce off edges with less energy
    } else if (qubit.x > 0.9) {
      qubit.x = 0.9;
      qubit.vx *= -0.5;
    }

    if (qubit.y < 0.1) {
      qubit.y = 0.1;
      qubit.vy *= -0.5;
    } else if (qubit.y > 0.9) {
      qubit.y = 0.9;
      qubit.vy *= -0.5;
    }
  }

  // Apply repulsion between qubits
  for (let i = 0; i < updatedQubits.length; i++) {
    for (let j = i + 1; j < updatedQubits.length; j++) {
      const q1 = updatedQubits[i];
      const q2 = updatedQubits[j];

      // Calculate distance
      const dx = q2.x - q1.x;
      const dy = q2.y - q1.y;
      const distSquared = dx * dx + dy * dy;

      if (distSquared < minDistance * minDistance) {
        // Normalize direction
        const dist = Math.sqrt(distSquared);
        const nx = dx / dist;
        const ny = dy / dist;

        // Calculate repulsion force (stronger for closer objects)
        const force = repulsionFactor / Math.max(distSquared, 0.001);

        // Apply force in opposite directions
        q1.vx -= nx * force;
        q1.vy -= ny * force;
        q2.vx += nx * force;
        q2.vy += ny * force;
      }
    }
  }

  return updatedQubits;
};

export { useQuantumCircuitSimulator };

function useQuantumCircuitSimulator() {
  // --- State ---
  const [circuit, setCircuit] = useState<QuantumCircuit>(() =>
    createInitialCircuit(initialQubitCount, initialMomentCount)
  );
  const [qubitDisplay, setQubitDisplay] = useState<QubitDisplayInfo[]>(() =>
    createInitialQubitDisplay(initialQubitCount)
  );
  const [selectedQubitId, setSelectedQubitId] = useState<number | null>(null);
  const [selectedGateId, setSelectedGateId] = useState<string | null>(null); // For selecting placed gates
  const [operations] = useState<string[]>(availableOperations);

  // Interaction modes for placing multi-qubit gates
  const [pendingGate, setPendingGate] = useState<{
    gate: string;
    controlQubit: number;
  } | null>(null);

  // Simulation results state
  const [simulationHistory, setSimulationHistory] = useState<
    SimulationMoment[]
  >([]);
  const [currentMomentIndex, setCurrentMomentIndex] = useState<number>(0); // For stepping through simulation

  // --- Qubit Initialization & Updates ---
  useEffect(() => {
    // Adjust display if circuit qubit count changes (e.g., via settings later)
    if (circuit.numQubits !== qubitDisplay.length) {
      setQubitDisplay(createInitialQubitDisplay(circuit.numQubits));
      // Reset simulation as well
      resetSimulation();
    }
  }, [circuit.numQubits]); // Only re-run if numQubits changes

  // Reset simulation history
  const resetSimulation = useCallback(() => {
    setSimulationHistory([]);
    setCurrentMomentIndex(0);
  }, []);

  // --- Circuit Modification ---
  const addGate = useCallback(
    (
      gate: string,
      qubits: number[],
      momentIndex: number,
      params?: number[]
    ) => {
      if (momentIndex < 0 || momentIndex >= circuit.numMoments) {
        console.error("Invalid moment index");
        return;
      }
      // Basic check for qubit validity
      if (qubits.some((qId) => qId < 0 || qId >= circuit.numQubits)) {
        console.error("Invalid qubit index in gate placement");
        return;
      }
      // TODO: Add collision detection - prevent placing over existing gate on same qubit/moment

      const newPlacement: GatePlacement = {
        id: uuidv4(),
        gate,
        qubits,
        momentIndex,
        params,
      };

      // Identify control/target for CNOT/SWAP for easier processing later
      if (gate === "CNOT" && qubits.length === 2) {
        newPlacement.controlQubit = 0; // Assume first qubit in array is control
        newPlacement.targetQubit = 1;
      } else if (gate === "SWAP" && qubits.length === 2) {
        // SWAP is symmetric, but might need indices later
      }

      setCircuit((prevCircuit) => ({
        ...prevCircuit,
        gatePlacements: [...prevCircuit.gatePlacements, newPlacement],
      }));
      // Invalidate simulation results when circuit changes
      setSimulationHistory([]);
      setCurrentMomentIndex(0);
    },
    [circuit.numMoments, circuit.numQubits]
  );

  const removeGate = useCallback((gateId: string) => {
    setCircuit((prevCircuit) => ({
      ...prevCircuit,
      gatePlacements: prevCircuit.gatePlacements.filter((p) => p.id !== gateId),
    }));
    // Invalidate simulation results
    setSimulationHistory([]);
    setCurrentMomentIndex(0);
  }, []);

  // Function called when an operation button is clicked
  const handleOperationButtonClick = useCallback(
    (op: string) => {
      if (selectedQubitId === null) return; // Need a qubit selected to place a gate

      // --- Handle starting multi-qubit gate placement ---
      if (op === "CNOT") {
        setPendingGate({ gate: "CNOT", controlQubit: selectedQubitId });
        // Optionally provide visual feedback
        return; // Wait for target selection
      }
      if (op === "SWAP") {
        setPendingGate({ gate: "SWAP", controlQubit: selectedQubitId }); // Use controlQubit to store the first qubit
        return; // Wait for second qubit selection
      }

      // --- Place single-qubit gate ---
      // Find the first available moment for the selected qubit
      const firstAvailableMoment = findFirstAvailableMoment(
        circuit,
        selectedQubitId
      );
      if (firstAvailableMoment !== null) {
        addGate(op, [selectedQubitId], firstAvailableMoment);
      } else {
        console.warn("No available moment found for this qubit.");
        // Optionally extend circuit moments here?
      }
      // Reset any pending gate state
      setPendingGate(null);
    },
    [selectedQubitId, addGate, circuit]
  );

  // Function called when a qubit is clicked on the display
  const handleQubitSelection = useCallback(
    (qubitId: number | null) => {
      // If we have a pending multi-qubit gate and selected a different qubit, complete the gate
      if (
        pendingGate &&
        qubitId !== null &&
        qubitId !== pendingGate.controlQubit
      ) {
        if (pendingGate.gate === "CNOT") {
          // Find first available moment for both qubits
          // Simplified for now - assumes moments are free
          const momentIndex = 0; // Will replace with proper logic
          addGate("CNOT", [pendingGate.controlQubit, qubitId], momentIndex);
        } else if (pendingGate.gate === "SWAP") {
          const momentIndex = 0; // Will replace with proper logic
          addGate("SWAP", [pendingGate.controlQubit, qubitId], momentIndex);
        }
        setPendingGate(null); // Clear pending state
        return;
      }

      // Normal qubit selection
      setSelectedQubitId(qubitId);
      // Deselect gate when selecting a qubit
      setSelectedGateId(null);
    },
    [pendingGate, addGate]
  );

  // Reset the circuit completely
  const resetCircuit = useCallback(() => {
    setCircuit(createInitialCircuit(initialQubitCount, initialMomentCount));
    setQubitDisplay(createInitialQubitDisplay(initialQubitCount));
    setSelectedQubitId(null);
    setSelectedGateId(null);
    setPendingGate(null);
    setSimulationHistory([]);
    setCurrentMomentIndex(0);
  }, []);

  // --- Simulation Logic (Simplified) ---
  const runSimulation = useCallback(() => {
    console.log("Running simulation for circuit:", circuit);

    // Skip complex state vector simulation for now to avoid freezing
    // Just update the visual state of qubits
    const newDisplay = [...qubitDisplay];

    // Apply a simple visual effect to each qubit
    for (let i = 0; i < newDisplay.length; i++) {
      // Rotate display state slightly
      newDisplay[i].displayState =
        (newDisplay[i].displayState + Math.PI / 8) % (2 * Math.PI);
      // Animate phase
      newDisplay[i].displayPhase =
        (newDisplay[i].displayPhase + Math.PI / 4) % (2 * Math.PI);
    }

    // Add some random entanglement for visual effect
    if (newDisplay.length > 1) {
      const qubit1 = Math.floor(Math.random() * newDisplay.length);
      let qubit2 = Math.floor(Math.random() * newDisplay.length);
      while (qubit2 === qubit1) {
        qubit2 = Math.floor(Math.random() * newDisplay.length);
      }

      if (!newDisplay[qubit1].entangled.includes(qubit2)) {
        newDisplay[qubit1].entangled.push(qubit2);
      }
      if (!newDisplay[qubit2].entangled.includes(qubit1)) {
        newDisplay[qubit2].entangled.push(qubit1);
      }
    }

    // Update display state
    setQubitDisplay(newDisplay);

    // Minimal simulation history
    const initialState: QuantumStateVector = [Complex.ONE];
    const history: SimulationMoment[] = [
      { momentIndex: 0, stateVector: initialState },
    ];

    setSimulationHistory(history);
    setCurrentMomentIndex(0);
  }, [circuit, qubitDisplay]);

  // Add a new qubit to the circuit
  const addQubit = useCallback(() => {
    // Increment the number of qubits in the circuit
    setCircuit((prevCircuit) => ({
      ...prevCircuit,
      numQubits: prevCircuit.numQubits + 1,
    }));

    // The useEffect hook will handle creating the new qubit display
    // because we're watching circuit.numQubits
  }, []);

  return {
    // Circuit state
    circuit,
    qubitDisplay,
    selectedQubitId,
    selectedGateId,
    operations,
    pendingGate,
    simulationHistory,
    currentMomentIndex,

    // Methods
    addGate,
    removeGate,
    handleOperationButtonClick,
    handleQubitSelection,
    resetCircuit,
    runSimulation,
    updateQubitPhysics, // Export the physics update function
    addQubit, // Export the add qubit function
  };
}
