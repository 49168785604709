import React from "react";
import { css, keyframes } from "@emotion/react";
import { useTheme } from "../context/ThemeContext";

/** @jsxImportSource @emotion/react */

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 5px rgba(0, 200, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 15px rgba(0, 200, 255, 0.6);
  }
  100% {
    box-shadow: 0 0 5px rgba(0, 200, 255, 0.3);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface QuantumControlPanelProps {
  operations: string[];
  selectedQubitId: number | null;
  entangleMode: boolean;
  swapMode: boolean;
  applyOperation: (operation: string) => void;
  addQubit: () => void;
  resetQubits: () => void;
}

const QuantumControlPanel: React.FC<QuantumControlPanelProps> = ({
  selectedQubitId,
  entangleMode,
  swapMode,
  applyOperation,
  addQubit,
  resetQubits,
}) => {
  const { currentTheme } = useTheme();
  const themeColors = currentTheme.colorScheme;

  const interactionMode = entangleMode || swapMode;
  const gateDisabled = selectedQubitId === null || interactionMode;

  // Group gates by category for better organization
  const singleQubitGates = ["H", "X", "Y", "Z", "S", "T"];
  const twoQubitGates = ["CNOT", "SWAP"];
  const measurementGates = ["Measure"];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding: 15px;
        background-color: ${themeColors.background}F0;
        border-bottom: 1px solid ${themeColors.primary}30;
        animation: ${fadeIn} 0.3s ease-out;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 1px;
          background: linear-gradient(
            to right,
            ${themeColors.primary}00,
            ${themeColors.primary}80,
            ${themeColors.primary}00
          );
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          background: linear-gradient(
            to right,
            ${themeColors.primary}00,
            ${themeColors.primary}60,
            ${themeColors.primary}00
          );
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
        `}
      >
        <div
          css={css`
            font-family: "Courier New", monospace;
            font-size: 14px;
            font-weight: bold;
            color: ${themeColors.text};
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 8px;
              background-color: ${selectedQubitId !== null
                ? themeColors.secondary
                : themeColors.accent};
              transition: background-color 0.3s ease;
              box-shadow: 0 0 8px
                ${selectedQubitId !== null
                  ? themeColors.secondary + "80"
                  : themeColors.accent + "80"};
            `}
          />
          {selectedQubitId !== null ? (
            <span>Qubit {selectedQubitId} Selected</span>
          ) : (
            <span>No Qubit Selected</span>
          )}

          {interactionMode && (
            <span
              css={css`
                margin-left: 15px;
                background-color: ${themeColors.accent}20;
                color: ${themeColors.accent};
                padding: 3px 8px;
                border-radius: 4px;
                font-size: 12px;
                border: 1px solid ${themeColors.accent}40;
              `}
            >
              {entangleMode ? "CNOT MODE" : "SWAP MODE"}
            </span>
          )}
        </div>

        <div
          css={css`
            display: flex;
            gap: 10px;
          `}
        >
          <button
            onClick={addQubit}
            css={css`
              background-color: ${themeColors.primary}90;
              color: ${themeColors.text};
              border: 1px solid ${themeColors.primary};
              border-radius: 4px;
              padding: 5px 10px;
              font-family: monospace;
              font-size: 12px;
              cursor: pointer;
              transition: all 0.2s ease;
              display: flex;
              align-items: center;

              &:hover {
                background-color: ${themeColors.primary};
                transform: translateY(-1px);
              }

              &:active {
                transform: translateY(0);
              }

              &::before {
                content: "+";
                margin-right: 5px;
                font-weight: bold;
              }
            `}
          >
            Add Qubit
          </button>

          <button
            onClick={resetQubits}
            css={css`
              background-color: ${themeColors.accent}90;
              color: ${themeColors.text};
              border: 1px solid ${themeColors.accent};
              border-radius: 4px;
              padding: 5px 10px;
              font-family: monospace;
              font-size: 12px;
              cursor: pointer;
              transition: all 0.2s ease;

              &:hover {
                background-color: ${themeColors.accent};
                transform: translateY(-1px);
              }

              &:active {
                transform: translateY(0);
              }
            `}
          >
            Reset Circuit
          </button>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-right: 20px;
          `}
        >
          <div
            css={css`
              font-family: "Courier New", monospace;
              font-size: 12px;
              color: ${themeColors.text}90;
              margin-bottom: 5px;
              border-bottom: 1px solid ${themeColors.primary}20;
              padding-bottom: 3px;
            `}
          >
            Single-Qubit Gates
          </div>
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: 5px;
            `}
          >
            {singleQubitGates.map((gate) => (
              <button
                key={gate}
                onClick={() => applyOperation(gate)}
                disabled={gateDisabled}
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 36px;
                  height: 36px;
                  background-color: ${gateDisabled
                    ? themeColors.background
                    : themeColors.primary}CC;
                  color: ${gateDisabled
                    ? themeColors.text + "60"
                    : themeColors.text};
                  border: 1px solid
                    ${gateDisabled
                      ? themeColors.primary + "30"
                      : themeColors.primary};
                  border-radius: 4px;
                  font-family: monospace;
                  font-weight: bold;
                  font-size: 16px;
                  cursor: ${gateDisabled ? "not-allowed" : "pointer"};
                  transition: all 0.2s ease;
                  position: relative;
                  overflow: hidden;

                  &:hover {
                    ${!gateDisabled &&
                    `
                      background-color: ${themeColors.primary};
                      transform: translateY(-2px);
                      box-shadow: 0 3px 10px ${themeColors.primary}40;
                    `}
                  }

                  &:active {
                    ${!gateDisabled &&
                    `
                      transform: translateY(0);
                      box-shadow: 0 1px 5px ${themeColors.primary}40;
                    `}
                  }

                  &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 2px;
                    background-color: ${themeColors.accent};
                    transform: scaleX(0);
                    transform-origin: center;
                    transition: transform 0.2s ease;
                  }

                  &:hover::after {
                    ${!gateDisabled && `transform: scaleX(1);`}
                  }
                `}
              >
                {gate}
              </button>
            ))}
          </div>
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-right: 20px;
          `}
        >
          <div
            css={css`
              font-family: "Courier New", monospace;
              font-size: 12px;
              color: ${themeColors.text}90;
              margin-bottom: 5px;
              border-bottom: 1px solid ${themeColors.primary}20;
              padding-bottom: 3px;
            `}
          >
            Two-Qubit Gates
          </div>
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: 5px;
            `}
          >
            {twoQubitGates.map((gate) => (
              <button
                key={gate}
                onClick={() => applyOperation(gate)}
                disabled={selectedQubitId === null}
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  min-width: 60px;
                  height: 36px;
                  background-color: ${selectedQubitId === null
                    ? themeColors.background
                    : (gate === "CNOT" && entangleMode) ||
                      (gate === "SWAP" && swapMode)
                    ? themeColors.accent
                    : themeColors.secondary}CC;
                  color: ${selectedQubitId === null
                    ? themeColors.text + "60"
                    : (gate === "CNOT" && entangleMode) ||
                      (gate === "SWAP" && swapMode)
                    ? themeColors.background
                    : themeColors.text};
                  border: 1px solid
                    ${selectedQubitId === null
                      ? themeColors.secondary + "30"
                      : (gate === "CNOT" && entangleMode) ||
                        (gate === "SWAP" && swapMode)
                      ? themeColors.accent
                      : themeColors.secondary};
                  border-radius: 4px;
                  font-family: monospace;
                  font-weight: bold;
                  font-size: 14px;
                  cursor: ${selectedQubitId === null
                    ? "not-allowed"
                    : "pointer"};
                  transition: all 0.2s ease;
                  padding: 0 10px;

                  ${((gate === "CNOT" && entangleMode) ||
                    (gate === "SWAP" && swapMode)) &&
                  `
                    animation: ${pulseAnimation} 2s infinite ease-in-out;
                  `}

                  &:hover {
                    ${selectedQubitId !== null &&
                    `
                      background-color: ${
                        (gate === "CNOT" && entangleMode) ||
                        (gate === "SWAP" && swapMode)
                          ? themeColors.accent
                          : themeColors.secondary
                      };
                      transform: translateY(-2px);
                      box-shadow: 0 3px 10px ${themeColors.secondary}40;
                    `}
                  }

                  &:active {
                    ${selectedQubitId !== null &&
                    `
                      transform: translateY(0);
                      box-shadow: 0 1px 5px ${themeColors.secondary}40;
                    `}
                  }
                `}
              >
                {gate}
              </button>
            ))}
          </div>
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              font-family: "Courier New", monospace;
              font-size: 12px;
              color: ${themeColors.text}90;
              margin-bottom: 5px;
              border-bottom: 1px solid ${themeColors.primary}20;
              padding-bottom: 3px;
            `}
          >
            Measurement
          </div>
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: 5px;
            `}
          >
            {measurementGates.map((gate) => (
              <button
                key={gate}
                onClick={() => applyOperation(gate)}
                disabled={gateDisabled}
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  min-width: 80px;
                  height: 36px;
                  background-color: ${gateDisabled
                    ? themeColors.background
                    : themeColors.accent}90;
                  color: ${gateDisabled
                    ? themeColors.text + "60"
                    : themeColors.text};
                  border: 1px solid
                    ${gateDisabled
                      ? themeColors.accent + "30"
                      : themeColors.accent};
                  border-radius: 4px;
                  font-family: monospace;
                  font-weight: bold;
                  font-size: 14px;
                  cursor: ${gateDisabled ? "not-allowed" : "pointer"};
                  transition: all 0.2s ease;
                  padding: 0 10px;

                  &:hover {
                    ${!gateDisabled &&
                    `
                      background-color: ${themeColors.accent};
                      transform: translateY(-2px);
                      box-shadow: 0 3px 10px ${themeColors.accent}40;
                    `}
                  }

                  &:active {
                    ${!gateDisabled &&
                    `
                      transform: translateY(0);
                      box-shadow: 0 1px 5px ${themeColors.accent}40;
                    `}
                  }
                `}
              >
                {gate}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuantumControlPanel;
