import React, { useState, useEffect, useRef } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useTheme } from "../context/ThemeContext";
import { hexToRgba } from "../utils/helpers";
import { css, keyframes } from "@emotion/react";
import IconButton from "./IconButton";

/** @jsxImportSource @emotion/react */

// Animation keyframes
const pulse = keyframes`
  0% { box-shadow: 0 0 5px rgba(38, 218, 253, 0.3); }
  50% { box-shadow: 0 0 15px rgba(38, 218, 253, 0.7); }
  100% { box-shadow: 0 0 5px rgba(38, 218, 253, 0.3); }
`;

const glitch = keyframes`
  0% {
    clip-path: inset(40% 0 61% 0);
    transform: translate(-2px, 2px);
  }
  20% {
    clip-path: inset(92% 0 1% 0);
    transform: translate(1px, 2px);
  }
  40% {
    clip-path: inset(43% 0 1% 0);
    transform: translate(3px, 1px);
  }
  60% {
    clip-path: inset(25% 0 58% 0);
    transform: translate(-5px, 1px);
  }
  80% {
    clip-path: inset(54% 0 7% 0);
    transform: translate(2px, -2px);
  }
  100% {
    clip-path: inset(58% 0 43% 0);
    transform: translate(-2px, 2px);
  }
`;

interface QubitProps {
  id: number;
  state: number;
  entangled?: number[];
}

// Define props for ThreeVisualization to accept themeColors
// Use ColorScheme type from ThemeContext
interface ThreeVisualizationProps {
  themeColors: ReturnType<typeof useTheme>["currentTheme"]["colorScheme"];
}

// Internal component for the Three.js scene
const ThreeVisualization: React.FC<ThreeVisualizationProps> = ({
  themeColors,
}) => {
  const particlesRef = useRef<THREE.Points>(null);
  const lineSegmentsRef = useRef<THREE.LineSegments>(null);
  const { size } = useThree();

  const particleCount = 2000;
  const lineCount = 50;

  // Particle positions
  const positions = React.useMemo(() => {
    const pos = new Float32Array(particleCount * 3);
    const scaleFactor = Math.min(size.width, size.height) * 0.01;
    for (let i = 0; i < particleCount; i++) {
      pos[i * 3 + 0] = (Math.random() - 0.5) * size.width * 0.02;
      pos[i * 3 + 1] = (Math.random() - 0.5) * size.height * 0.02;
      pos[i * 3 + 2] = (Math.random() - 0.5) * 10 * scaleFactor;
    }
    return pos;
  }, [size.width, size.height]);

  // Line positions
  const linePositions = React.useMemo(() => {
    const lines = new Float32Array(lineCount * 6);
    const scaleFactor = Math.min(size.width, size.height) * 0.01;
    for (let i = 0; i < lineCount; i++) {
      const x1 = (Math.random() - 0.5) * size.width * 0.02;
      const y1 = (Math.random() - 0.5) * size.height * 0.02;
      const z1 = (Math.random() - 0.5) * 10 * scaleFactor;
      const x2 = x1 + (Math.random() - 0.5) * 2 * scaleFactor;
      const y2 = y1 + (Math.random() - 0.5) * 2 * scaleFactor;
      const z2 = z1 + (Math.random() - 0.5) * 2 * scaleFactor;
      lines[i * 6 + 0] = x1;
      lines[i * 6 + 1] = y1;
      lines[i * 6 + 2] = z1;
      lines[i * 6 + 3] = x2;
      lines[i * 6 + 4] = y2;
      lines[i * 6 + 5] = z2;
    }
    return lines;
  }, [size.width, size.height]);

  useFrame((state: { clock: THREE.Clock }) => {
    const time = state.clock.getElapsedTime();
    if (particlesRef.current) {
      particlesRef.current.rotation.y = time * 0.1;
      particlesRef.current.rotation.x = Math.sin(time * 0.5) * 0.1;
    }
    if (lineSegmentsRef.current) {
      lineSegmentsRef.current.rotation.y = time * 0.05;
      lineSegmentsRef.current.rotation.z = Math.cos(time * 0.3) * 0.1;
    }
  });

  return (
    <>
      {/* Particles */}
      <points ref={particlesRef}>
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attach="attributes-position"
            array={positions}
            count={positions.length / 3}
            itemSize={3}
            needsUpdate={true}
          />
        </bufferGeometry>
        <pointsMaterial
          attach="material"
          size={0.03}
          color={new THREE.Color(themeColors.primary || "#00ff00")}
          transparent
          opacity={0.7}
          blending={THREE.AdditiveBlending}
          sizeAttenuation={true}
        />
      </points>

      {/* Line Segments */}
      <lineSegments ref={lineSegmentsRef}>
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attach="attributes-position"
            array={linePositions}
            count={linePositions.length / 3}
            itemSize={3}
            needsUpdate={true}
          />
        </bufferGeometry>
        <lineBasicMaterial
          attach="material"
          color={new THREE.Color(themeColors.secondary || "#0088ff")}
          transparent
          opacity={0.2}
          blending={THREE.AdditiveBlending}
        />
      </lineSegments>
    </>
  );
};

// Main QuantumVisualizer component
const QuantumVisualizer: React.FC = () => {
  const { currentTheme } = useTheme(); // Get currentTheme from context
  const themeColors = currentTheme.colorScheme; // Access colorScheme from currentTheme

  const [qubits, setQubits] = useState<QubitProps[]>([
    { id: 0, state: 0.5, entangled: [1] },
    { id: 1, state: 0.5, entangled: [0] },
    { id: 2, state: 0.25, entangled: [3, 4] },
    { id: 3, state: 0.75, entangled: [2, 4] },
    { id: 4, state: 0.5, entangled: [2, 3] },
    { id: 5, state: 0, entangled: [] },
    { id: 6, state: 1, entangled: [] },
    { id: 7, state: 0.5, entangled: [] },
  ]);
  const [activeQubit, setActiveQubit] = useState<number | null>(null);
  const [quantumNoise, setQuantumNoise] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setQubits((prevQubits) =>
        prevQubits.map((qubit) => {
          if (qubit.entangled && qubit.entangled.length > 0) {
            if (qubit.id === qubit.entangled[0]) {
              const fluctuation = (Math.random() - 0.5) * 0.1;
              return {
                ...qubit,
                state: Math.max(0, Math.min(1, qubit.state + fluctuation)),
              };
            }
            return qubit;
          } else {
            const fluctuation = (Math.random() - 0.5) * 0.2;
            return {
              ...qubit,
              state: Math.max(0, Math.min(1, qubit.state + fluctuation)),
            };
          }
        })
      );
    }, 1000);

    const noiseInterval = setInterval(() => {
      setQuantumNoise((prev) => !prev);
    }, 3000);

    return () => {
      clearInterval(interval);
      clearInterval(noiseInterval);
    };
  }, []);

  const handleQubitClick = (id: number) => {
    setActiveQubit(activeQubit === id ? null : id);
  };

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        background-color: rgba(0, 5, 15, 0.95);
        padding: 20px;
        display: flex;
        flex-direction: column;
        font-family: "Share Tech Mono", monospace;
        color: ${themeColors.primary};
        position: relative;
        overflow: hidden;
      `}
    >
      {/* Background grid effect */}
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: radial-gradient(
            ${hexToRgba(themeColors.primary || "#26dafd", 0.1)} 1px,
            transparent 1px
          );
          background-size: 30px 30px;
          z-index: 0;
          opacity: 0.5;
        `}
      ></div>

      {/* Quantum noise effect */}
      {quantumNoise && (
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: ${hexToRgba(
              themeColors.primary || "#26dafd",
              0.05
            )};
            z-index: 1;
            pointer-events: none;
            animation: ${glitch} 0.3s ease forwards;
          `}
        ></div>
      )}

      <header
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 15px;
          border-bottom: 1px solid
            ${hexToRgba(themeColors.primary || "#26dafd", 0.3)};
          margin-bottom: 20px;
          position: relative;
          z-index: 2;
        `}
      >
        <h1
          css={css`
            margin: 0;
            font-size: 20px;
            font-weight: normal;
            display: flex;
            align-items: center;
            color: ${themeColors.text};

            &::before {
              content: "";
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: ${hexToRgba(
                themeColors.primary || "#26dafd",
                0.8
              )};
              margin-right: 10px;
              animation: ${pulse} 2s infinite;
            }
          `}
        >
          Quantum State Visualizer
        </h1>

        <div
          css={css`
            display: flex;
            gap: 10px;
          `}
        >
          <IconButton
            icon="sync"
            title="Collapse Quantum State"
            onClick={() => {
              /* TODO */
            }}
          />

          <IconButton
            icon="shuffle"
            title="Entangle Selected Qubits"
            onClick={() => {
              /* TODO */
            }}
          />
        </div>
      </header>

      <div
        css={css`
          display: flex;
          gap: 30px;
          flex: 1;
          position: relative;
          z-index: 2;
          min-height: 0;
        `}
      >
        {/* Quantum visualization area */}
        <div
          css={css`
            flex: 1;
            background-color: ${hexToRgba(
              themeColors.background || "#00050F",
              0.5
            )};
            border: 1px solid
              ${hexToRgba(themeColors.primary || "#26dafd", 0.3)};
            border-radius: 5px;
            position: relative;
            overflow: hidden;
          `}
        >
          <Canvas
            style={{ background: "transparent" }}
            camera={{ position: [0, 0, 15], fov: 50 }}
          >
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} intensity={1} />
            <ThreeVisualization themeColors={themeColors} />
          </Canvas>
        </div>

        {/* Status panel */}
        <div
          css={css`
            width: 280px;
            background-color: ${hexToRgba(
              themeColors.background || "#000A19",
              0.8
            )};
            border: 1px solid
              ${hexToRgba(themeColors.primary || "#26dafd", 0.3)};
            border-radius: 5px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            backdrop-filter: blur(5px);
            height: calc(100% - 40px);
            box-sizing: border-box;
          `}
        >
          <h2
            css={css`
              margin: 0 0 15px 0;
              font-size: 16px;
              font-weight: normal;
              padding-bottom: 10px;
              border-bottom: 1px solid
                ${hexToRgba(themeColors.primary || "#26dafd", 0.3)};
            `}
          >
            Quantum Status
          </h2>

          {activeQubit !== null && qubits[activeQubit] ? (
            <div
              css={css`
                flex: 1;
                color: ${themeColors.text};
              `}
            >
              <h3
                css={css`
                  margin: 0 0 10px 0;
                  font-size: 14px;
                  font-weight: normal;
                  color: ${themeColors.text};
                `}
              >
                Qubit {activeQubit} Properties
              </h3>

              <div
                css={css`
                  margin-bottom: 15px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 5px;
                    font-size: 12px;
                  `}
                >
                  <span>State:</span>
                  <span>{qubits[activeQubit].state.toFixed(4)}</span>
                </div>

                <div
                  css={css`
                    height: 10px;
                    background-color: ${hexToRgba(
                      themeColors.background || "#000000",
                      0.3
                    )};
                    border-radius: 5px;
                    overflow: hidden;
                  `}
                >
                  <div
                    css={css`
                      height: 100%;
                      width: ${qubits[activeQubit].state * 100}%;
                      background: linear-gradient(
                        to right,
                        ${themeColors.secondary || "#0088ff"},
                        ${themeColors.primary || "#26dafd"}
                      );
                      border-radius: 5px;
                    `}
                    style={{ width: `${qubits[activeQubit].state * 100}%` }}
                  ></div>
                </div>
              </div>

              <div
                css={css`
                  margin-bottom: 15px;
                `}
              >
                <div
                  css={css`
                    font-size: 12px;
                    margin-bottom: 5px;
                  `}
                >
                  Probability:
                </div>
                <div
                  css={css`
                    display: flex;
                    gap: 10px;
                    font-size: 12px;
                  `}
                >
                  <div
                    css={css`
                      background-color: ${hexToRgba(
                        themeColors.primary || "#26dafd",
                        0.2
                      )};
                      padding: 5px;
                      text-align: center;
                      border-radius: 3px;
                    `}
                    style={{ flex: qubits[activeQubit].state }}
                  >
                    |1⟩: {(qubits[activeQubit].state * 100).toFixed(1)}%
                  </div>
                  <div
                    css={css`
                      background-color: ${hexToRgba(
                        themeColors.primary || "#26dafd",
                        0.1
                      )};
                      padding: 5px;
                      text-align: center;
                      border-radius: 3px;
                    `}
                    style={{ flex: 1 - qubits[activeQubit].state }}
                  >
                    |0⟩: {((1 - qubits[activeQubit].state) * 100).toFixed(1)}%
                  </div>
                </div>
              </div>

              <div>
                <div
                  css={css`
                    font-size: 12px;
                    margin-bottom: 5px;
                  `}
                >
                  Entangled with:
                </div>
                {qubits[activeQubit].entangled &&
                qubits[activeQubit].entangled.length > 0 ? (
                  <div
                    css={css`
                      display: flex;
                      gap: 5px;
                      flex-wrap: wrap;
                    `}
                  >
                    {qubits[activeQubit].entangled.map((id) => (
                      <div
                        key={id}
                        css={css`
                          background-color: ${hexToRgba(
                            themeColors.primary || "#26dafd",
                            0.1
                          )};
                          border: 1px solid
                            ${hexToRgba(themeColors.primary || "#26dafd", 0.3)};
                          padding: 3px 8px;
                          border-radius: 3px;
                          font-size: 12px;
                          cursor: pointer;
                          &:hover {
                            background-color: ${hexToRgba(
                              themeColors.primary || "#26dafd",
                              0.2
                            )};
                          }
                        `}
                        onClick={() => handleQubitClick(id)}
                      >
                        Q{id}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    css={css`
                      font-size: 12px;
                      opacity: 0.7;
                    `}
                  >
                    None (isolated qubit)
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              css={css`
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                opacity: 0.7;
                text-align: center;
                padding: 20px 0;
                color: ${hexToRgba(themeColors.text, 0.7)};
              `}
            >
              <span
                className="material-symbols-outlined"
                css={css`
                  font-size: 40px;
                  margin-bottom: 15px;
                  opacity: 0.5;
                  color: ${themeColors.primary};
                  text-shadow: 0 0 10px ${hexToRgba(themeColors.primary, 0.5)};
                `}
              >
                science
              </span>
              <div
                css={css`
                  font-size: 14px;
                `}
              >
                Select a qubit to view detailed information
              </div>
            </div>
          )}

          <div
            css={css`
              margin-top: auto;
              padding-top: 15px;
              border-top: 1px solid
                ${hexToRgba(themeColors.primary || "#26dafd", 0.3)};
              font-size: 12px;
              color: ${themeColors.text};
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
              `}
            >
              <span>System Coherence:</span>
              <span>96.4%</span>
            </div>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <span>Quantum Decoherence:</span>
              <span>00:05:24</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Wrapper component needed by WindowManager
const SystemMonitor: React.FC = () => {
  return (
    <div css={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <QuantumVisualizer />
    </div>
  );
};

export default SystemMonitor;
