import React, { useState } from "react";
import { css, keyframes } from "@emotion/react";
import {
  useTheme,
  ColorScheme,
  Background,
  Theme,
} from "../context/ThemeContext";
import { useAudio } from "../context/AudioContext";
import IconButton from "./IconButton";
import { hexToRgba } from "../utils/helpers";

/** @jsxImportSource @emotion/react */

// Animation for background previews
const gradientAnimation = keyframes`
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

interface ThemeSelectorProps {
  width?: string;
  height?: string;
}

const ThemeSelector: React.FC<ThemeSelectorProps> = ({
  width = "100%",
  height = "100%",
}) => {
  const {
    currentTheme,
    availableThemes,
    availableBackgrounds,
    availableColorSchemes,
    availableSoundPacks,
    setTheme,
    setBackground,
    setColorScheme,
    setSoundPack,
    setAnimationSpeed,
  } = useTheme();

  const { isSoundEnabled, toggleSound, setSoundVolume, soundVolume } =
    useAudio();

  const [activeTab, setActiveTab] = useState<
    "themes" | "colorSchemes" | "backgrounds" | "soundAndDisplay"
  >("themes");
  const themeColors = currentTheme.colorScheme;

  // Helper style definitions
  const sectionTitleStyles = (color: string) => css`
    font-size: 18px;
    margin-bottom: 20px;
    color: ${color};
    border-bottom: 1px solid ${hexToRgba(color, 0.3)};
    padding-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  `;

  const gridStyles = css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 15px;
  `;

  const itemCardStyles = (isActive: boolean) => css`
    background-color: ${hexToRgba(themeColors.background, 0.7)};
    border: 1px solid
      ${isActive ? themeColors.primary : hexToRgba(themeColors.primary, 0.3)};
    border-radius: 4px;
    padding: 15px;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: ${isActive
      ? `0 0 10px ${hexToRgba(themeColors.primary, 0.4)}`
      : "none"};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 150px;
    position: relative;

    &:hover {
      border-color: ${themeColors.primary};
      box-shadow: 0 0 10px ${hexToRgba(themeColors.primary, 0.3)};
      transform: translateY(-2px);
    }
  `;

  const colorPreviewStyles = (scheme: ColorScheme) => css`
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    height: 25px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid ${hexToRgba(scheme.primary, 0.5)};

    div {
      flex: 1;
      height: 100%;
    }
  `;

  const backgroundPreviewStyles = (background: Background) => css`
    margin-bottom: 10px;
    height: 120px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid ${hexToRgba(themeColors.primary, 0.5)};
    position: relative;
    background-color: ${hexToRgba(themeColors.background, 0.3)};

    ${background.type === "image"
      ? `background-image: url(${background.path});
         background-size: cover;
         background-position: center;`
      : ""}

    ${background.type === "animation"
      ? `&::before {
          content: "Dynamic Animation";
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: ${hexToRgba(themeColors.background, 0.7)};
          color: ${themeColors.primary};
          font-size: 12px;
          padding: 3px 8px;
          border-radius: 3px;
          border: 1px solid ${hexToRgba(themeColors.primary, 0.5)};
        }`
      : ""}
  `;

  const themePreviewStyles = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;

    .theme-preview-header {
      display: flex;
      gap: 8px;
      margin-bottom: 5px;

      .color-box {
        width: 20px;
        height: 20px;
        border-radius: 3px;
      }
    }

    .theme-preview-body {
      display: flex;
      height: 30px;
      overflow: hidden;
      border-radius: 3px;
      border: 1px solid ${hexToRgba(themeColors.primary, 0.5)};

      .primary-bar {
        width: 30%;
        background-color: ${theme.colorScheme.primary};
      }

      .secondary-bar {
        width: 20%;
        background-color: ${theme.colorScheme.secondary};
      }

      .accent-bar {
        width: 15%;
        background-color: ${theme.colorScheme.accent};
      }

      .background-bar {
        width: 35%;
        background-color: ${theme.colorScheme.background};
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        color: ${theme.colorScheme.text};
      }
    }
  `;

  const itemDetailsStyles = css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `;

  const itemNameStyles = css`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  `;

  const itemDescriptionStyles = (textColor: string) => css`
    font-size: 12px;
    color: ${hexToRgba(textColor, 0.8)};
    margin-top: auto;
    padding-top: 10px;
    line-height: 1.4;
  `;

  return (
    <div
      css={css`
        width: ${width};
        height: ${height};
        display: flex;
        flex-direction: column;
        background-color: ${themeColors.background.startsWith("rgba")
          ? themeColors.background
          : hexToRgba(themeColors.background, 0.95)};
        color: ${themeColors.primary};
        font-family: ${currentTheme.fontFamily};
        position: relative;
      `}
    >
      {/* Scanlines effect */}
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: repeating-linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1) 1px,
            transparent 1px,
            transparent 2px
          );
          pointer-events: none;
          z-index: 1;
        `}
      ></div>

      {/* Tab navigation */}
      <div
        css={css`
          display: flex;
          border-bottom: 1px solid ${hexToRgba(themeColors.primary, 0.5)};
          background-color: ${hexToRgba(themeColors.background, 0.8)};
          position: relative;
          z-index: 2;
        `}
      >
        <div
          css={css`
            flex: 1;
            padding: 15px;
            background: none;
            border: none;
            color: ${activeTab === "themes"
              ? themeColors.text
              : hexToRgba(themeColors.primary, 0.6)};
            font-family: ${currentTheme.fontFamily};
            font-size: 16px;
            cursor: pointer;
            transition: all 0.2s ease;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            text-shadow: ${activeTab === "themes"
              ? `0 0 5px ${hexToRgba(themeColors.primary, 0.5)}`
              : "none"};

            ${activeTab === "themes"
              ? `
              &::after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 15%;
                right: 15%;
                height: 2px;
                background-color: ${themeColors.primary};
                box-shadow: 0 0 5px ${hexToRgba(themeColors.primary, 0.5)};
                z-index: 1;
              }
            `
              : ""}

            &:hover {
              background-color: ${hexToRgba(themeColors.primary, 0.1)};
              color: ${themeColors.text};
            }
          `}
          onClick={() => setActiveTab("themes")}
        >
          <span
            className="material-symbols-outlined"
            css={css`
              margin-right: 8px;
              font-size: 20px;
            `}
          >
            palette
          </span>
          Themes
        </div>

        <div
          css={css`
            flex: 1;
            padding: 15px;
            background: none;
            border: none;
            color: ${activeTab === "colorSchemes"
              ? themeColors.text
              : hexToRgba(themeColors.primary, 0.6)};
            font-family: ${currentTheme.fontFamily};
            font-size: 16px;
            cursor: pointer;
            transition: all 0.2s ease;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            text-shadow: ${activeTab === "colorSchemes"
              ? `0 0 5px ${hexToRgba(themeColors.primary, 0.5)}`
              : "none"};

            ${activeTab === "colorSchemes"
              ? `
              &::after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 15%;
                right: 15%;
                height: 2px;
                background-color: ${themeColors.primary};
                box-shadow: 0 0 5px ${hexToRgba(themeColors.primary, 0.5)};
                z-index: 1;
              }
            `
              : ""}

            &:hover {
              background-color: ${hexToRgba(themeColors.primary, 0.1)};
              color: ${themeColors.text};
            }
          `}
          onClick={() => setActiveTab("colorSchemes")}
        >
          <span
            className="material-symbols-outlined"
            css={css`
              margin-right: 8px;
              font-size: 20px;
            `}
          >
            format_color_fill
          </span>
          Color Schemes
        </div>

        <div
          css={css`
            flex: 1;
            padding: 15px;
            background: none;
            border: none;
            color: ${activeTab === "backgrounds"
              ? themeColors.text
              : hexToRgba(themeColors.primary, 0.6)};
            font-family: ${currentTheme.fontFamily};
            font-size: 16px;
            cursor: pointer;
            transition: all 0.2s ease;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            text-shadow: ${activeTab === "backgrounds"
              ? `0 0 5px ${hexToRgba(themeColors.primary, 0.5)}`
              : "none"};

            ${activeTab === "backgrounds"
              ? `
              &::after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 15%;
                right: 15%;
                height: 2px;
                background-color: ${themeColors.primary};
                box-shadow: 0 0 5px ${hexToRgba(themeColors.primary, 0.5)};
                z-index: 1;
              }
            `
              : ""}

            &:hover {
              background-color: ${hexToRgba(themeColors.primary, 0.1)};
              color: ${themeColors.text};
            }
          `}
          onClick={() => setActiveTab("backgrounds")}
        >
          <span
            className="material-symbols-outlined"
            css={css`
              margin-right: 8px;
              font-size: 20px;
            `}
          >
            wallpaper
          </span>
          Backgrounds
        </div>

        <div
          css={css`
            flex: 1;
            padding: 15px;
            background: none;
            border: none;
            color: ${activeTab === "soundAndDisplay"
              ? themeColors.text
              : hexToRgba(themeColors.primary, 0.6)};
            font-family: ${currentTheme.fontFamily};
            font-size: 16px;
            cursor: pointer;
            transition: all 0.2s ease;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            text-shadow: ${activeTab === "soundAndDisplay"
              ? `0 0 5px ${hexToRgba(themeColors.primary, 0.5)}`
              : "none"};

            ${activeTab === "soundAndDisplay"
              ? `
              &::after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 15%;
                right: 15%;
                height: 2px;
                background-color: ${themeColors.primary};
                box-shadow: 0 0 5px ${hexToRgba(themeColors.primary, 0.5)};
                z-index: 1;
              }
            `
              : ""}

            &:hover {
              background-color: ${hexToRgba(themeColors.primary, 0.1)};
              color: ${themeColors.text};
            }
          `}
          onClick={() => setActiveTab("soundAndDisplay")}
        >
          <span
            className="material-symbols-outlined"
            css={css`
              margin-right: 8px;
              font-size: 20px;
            `}
          >
            tune
          </span>
          Settings
        </div>
      </div>

      {/* Content area */}
      <div
        css={css`
          flex: 1;
          padding: 20px;
          overflow-y: auto;
          position: relative;
          z-index: 2;

          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.3);
            border-radius: 4px;
          }
          &::-webkit-scrollbar-thumb {
            background: ${hexToRgba(themeColors.primary, 0.4)};
            border-radius: 4px;
            &:hover {
              background: ${hexToRgba(themeColors.primary, 0.6)};
            }
          }
        `}
      >
        {/* Themes tab */}
        {activeTab === "themes" && (
          <div>
            <div css={sectionTitleStyles(themeColors.text)}>
              Complete Themes
            </div>
            <p
              css={css`
                margin-bottom: 20px;
                color: ${hexToRgba(themeColors.text, 0.8)};
                font-size: 14px;
              `}
            >
              Select a complete theme package that includes color scheme and
              recommended background.
            </p>

            <div css={gridStyles}>
              {availableThemes.map((theme) => (
                <div
                  key={theme.id}
                  css={itemCardStyles(currentTheme.id === theme.id)}
                  onClick={() => {
                    console.log(
                      `[ThemeSelector] Setting theme to: ${theme.id}`
                    );

                    const audio = new Audio();
                    audio.src = "/sounds/ui/confirm.mp3";
                    audio.volume = 0.5;
                    audio
                      .play()
                      .catch((err) =>
                        console.warn("Failed to play sound:", err)
                      );

                    setTheme(theme.id);
                  }}
                >
                  <div css={itemDetailsStyles}>
                    <span css={itemNameStyles}>{theme.name}</span>
                    <div css={themePreviewStyles(theme)}>
                      <div className="theme-preview-header">
                        <div
                          className="color-box"
                          style={{ backgroundColor: theme.colorScheme.primary }}
                        />
                        <div
                          className="color-box"
                          style={{
                            backgroundColor: theme.colorScheme.secondary,
                          }}
                        />
                        <div
                          className="color-box"
                          style={{ backgroundColor: theme.colorScheme.accent }}
                        />
                      </div>
                      <div className="theme-preview-body">
                        <div className="primary-bar" />
                        <div className="secondary-bar" />
                        <div className="accent-bar" />
                        <div className="background-bar">
                          {theme.background.type === "animation"
                            ? "Animated"
                            : "Static"}
                        </div>
                      </div>
                    </div>
                    <p css={itemDescriptionStyles(theme.colorScheme.text)}>
                      {theme.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Color Schemes tab */}
        {activeTab === "colorSchemes" && (
          <div>
            <div css={sectionTitleStyles(themeColors.text)}>Color Schemes</div>
            <p
              css={css`
                margin-bottom: 20px;
                color: ${hexToRgba(themeColors.text, 0.8)};
                font-size: 14px;
              `}
            >
              Change only the color scheme while keeping your current
              background.
            </p>

            <div css={gridStyles}>
              {availableColorSchemes.map((scheme) => (
                <div
                  key={scheme.id}
                  css={itemCardStyles(
                    currentTheme.colorScheme.id === scheme.id
                  )}
                  onClick={() => setColorScheme(scheme.id)}
                >
                  <div css={itemDetailsStyles}>
                    <span css={itemNameStyles}>{scheme.name}</span>
                    <div css={colorPreviewStyles(scheme)}>
                      <div
                        css={css`
                          flex: 1;
                          height: 15px;
                          background-color: ${scheme.primary};
                          border-radius: 2px;
                        `}
                      ></div>
                      <div
                        css={css`
                          flex: 1;
                          height: 15px;
                          background-color: ${scheme.secondary};
                          border-radius: 2px;
                        `}
                      ></div>
                      <div
                        css={css`
                          flex: 1;
                          height: 15px;
                          background-color: ${scheme.accent};
                          border-radius: 2px;
                        `}
                      ></div>
                      <div
                        css={css`
                          background-color: ${scheme.background};
                          border: 1px solid ${hexToRgba(scheme.text, 0.2)};
                        `}
                      ></div>
                      <div
                        css={css`
                          background-color: ${scheme.text};
                          border: 1px solid ${hexToRgba(scheme.background, 0.2)};
                        `}
                      ></div>
                    </div>
                    <p css={itemDescriptionStyles(scheme.text)}>
                      {scheme.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Backgrounds tab */}
        {activeTab === "backgrounds" && (
          <div>
            <div css={sectionTitleStyles(themeColors.text)}>Backgrounds</div>
            <p
              css={css`
                margin-bottom: 20px;
                color: ${hexToRgba(themeColors.text, 0.8)};
                font-size: 14px;
              `}
            >
              Select a background style for your interface. Choose between
              dynamic animated backgrounds or static images.
            </p>

            <div css={gridStyles}>
              {availableBackgrounds.map((bg) => (
                <div
                  key={bg.id}
                  css={itemCardStyles(bg.id === currentTheme.background.id)}
                  onClick={() => setBackground(bg.id)}
                >
                  <div css={itemDetailsStyles}>
                    <span css={itemNameStyles}>{bg.name}</span>
                    <div css={backgroundPreviewStyles(bg)}>
                      {bg.type === "animation" && (
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            width: 100%;
                            color: ${themeColors.text};
                            background: linear-gradient(
                              145deg,
                              ${hexToRgba(themeColors.background, 0.9)},
                              ${hexToRgba(themeColors.primary, 0.2)},
                              ${hexToRgba(themeColors.background, 0.9)}
                            );
                            background-size: 400% 400%;
                            animation: ${gradientAnimation} 8s ease infinite;
                          `}
                        >
                          <div
                            css={css`
                              text-align: center;
                              display: flex;
                              flex-direction: column;
                              align-items: center;
                              gap: 10px;
                            `}
                          >
                            <div
                              css={css`
                                font-size: 32px;
                                color: ${themeColors.primary};
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 48px;
                                width: 48px;
                                border-radius: 50%;
                                background-color: ${hexToRgba(
                                  themeColors.background,
                                  0.7
                                )};
                                border: 1px solid
                                  ${hexToRgba(themeColors.primary, 0.6)};
                                box-shadow: 0 0 15px
                                  ${hexToRgba(themeColors.primary, 0.4)};
                              `}
                            >
                              {bg.id === "animated-grid" && (
                                <span className="material-symbols-outlined">
                                  grid_view
                                </span>
                              )}
                              {bg.id === "animated-particles" && (
                                <span className="material-symbols-outlined">
                                  blur_on
                                </span>
                              )}
                              {bg.id === "animated-waves" && (
                                <span className="material-symbols-outlined">
                                  waves
                                </span>
                              )}
                            </div>
                            <div
                              css={css`
                                font-size: 14px;
                                background-color: ${hexToRgba(
                                  themeColors.background,
                                  0.7
                                )};
                                padding: 4px 10px;
                                border-radius: 12px;
                                border: 1px solid
                                  ${hexToRgba(themeColors.primary, 0.3)};
                              `}
                            >
                              Dynamic Animation
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <p css={itemDescriptionStyles(themeColors.text)}>
                      {bg.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Sound and Display Settings tab */}
        {activeTab === "soundAndDisplay" && (
          <div>
            <div css={sectionTitleStyles(themeColors.text)}>
              Interface Settings
            </div>

            {/* Animation speed */}
            <div
              css={css`
                margin-bottom: 20px;
                padding: 15px;
                background-color: ${hexToRgba(themeColors.background, 0.3)};
                border: 1px solid ${hexToRgba(themeColors.primary, 0.3)};
                border-radius: 4px;
              `}
            >
              <div
                css={css`
                  font-size: 16px;
                  color: ${themeColors.text};
                  margin-bottom: 10px;
                  display: flex;
                  align-items: center;
                `}
              >
                <span
                  className="material-symbols-outlined"
                  css={css`
                    margin-right: 10px;
                    color: ${themeColors.primary};
                    font-size: 20px;
                  `}
                >
                  speed
                </span>
                Animation Speed
              </div>

              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 10px;
                `}
              >
                <span
                  css={css`
                    color: ${hexToRgba(themeColors.primary, 0.8)};
                    font-size: 14px;
                  `}
                >
                  Slow
                </span>
                <input
                  type="range"
                  min="0.5"
                  max="2"
                  step="0.1"
                  value={currentTheme.animationSpeed}
                  onChange={(e) =>
                    setAnimationSpeed(parseFloat(e.target.value))
                  }
                  title={`Animation Speed: ${currentTheme.animationSpeed.toFixed(
                    1
                  )}x`}
                  css={css`
                    flex: 1;
                    height: 4px;
                    appearance: none;
                    background: linear-gradient(
                      to right,
                      ${hexToRgba(themeColors.primary, 0.3)},
                      ${themeColors.primary}
                    );
                    border-radius: 2px;
                    outline: none;

                    &::-webkit-slider-thumb {
                      appearance: none;
                      width: 15px;
                      height: 15px;
                      border-radius: 50%;
                      background: ${themeColors.primary};
                      cursor: pointer;
                      border: 2px solid ${themeColors.background};
                    }
                  `}
                />
                <span
                  css={css`
                    color: ${hexToRgba(themeColors.primary, 0.8)};
                    font-size: 14px;
                  `}
                >
                  Fast
                </span>
                <span
                  css={css`
                    width: 40px;
                    text-align: right;
                    font-size: 14px;
                    color: ${themeColors.text};
                  `}
                >
                  {currentTheme.animationSpeed.toFixed(1)}x
                </span>
              </div>
            </div>

            {/* Sound settings */}
            <div
              css={css`
                margin-bottom: 20px;
                padding: 15px;
                background-color: ${hexToRgba(themeColors.background, 0.3)};
                border: 1px solid ${hexToRgba(themeColors.primary, 0.3)};
                border-radius: 4px;
              `}
            >
              <div
                css={css`
                  font-size: 16px;
                  color: ${themeColors.text};
                  margin-bottom: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <span
                    className="material-symbols-outlined"
                    css={css`
                      margin-right: 10px;
                      color: ${themeColors.primary};
                      font-size: 20px;
                    `}
                  >
                    volume_up
                  </span>
                  Sound Effects
                </div>

                <IconButton
                  icon={isSoundEnabled ? "volume_up" : "volume_off"}
                  title={
                    isSoundEnabled
                      ? "Disable Sound Effects"
                      : "Enable Sound Effects"
                  }
                  onClick={toggleSound}
                  variant={isSoundEnabled ? "primary" : "transparent"}
                />
              </div>

              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  opacity: ${isSoundEnabled ? 1 : 0.5};
                  transition: opacity 0.3s ease;
                  pointer-events: ${isSoundEnabled ? "auto" : "none"};
                `}
              >
                <span
                  css={css`
                    color: ${hexToRgba(themeColors.primary, 0.8)};
                    font-size: 20px;
                  `}
                >
                  <span className="material-symbols-outlined">volume_down</span>
                </span>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={soundVolume}
                  onChange={(e) => setSoundVolume(parseFloat(e.target.value))}
                  disabled={!isSoundEnabled}
                  title={`Sound Volume: ${Math.round(soundVolume * 100)}%`}
                  css={css`
                    flex: 1;
                    height: 4px;
                    appearance: none;
                    background: linear-gradient(
                      to right,
                      ${themeColors.primary} 0%,
                      ${themeColors.primary} ${soundVolume * 100}%,
                      ${hexToRgba(themeColors.primary, 0.3)}
                        ${soundVolume * 100}%,
                      ${hexToRgba(themeColors.primary, 0.3)} 100%
                    );
                    border-radius: 2px;
                    outline: none;

                    &::-webkit-slider-thumb {
                      appearance: none;
                      width: 15px;
                      height: 15px;
                      border-radius: 50%;
                      background: ${themeColors.primary};
                      cursor: pointer;
                      border: 2px solid ${themeColors.background};
                    }
                  `}
                />
                <span
                  css={css`
                    color: ${hexToRgba(themeColors.primary, 0.8)};
                    font-size: 20px;
                  `}
                >
                  <span className="material-symbols-outlined">volume_up</span>
                </span>
                <span
                  css={css`
                    width: 40px;
                    text-align: right;
                    font-size: 14px;
                    color: ${themeColors.text};
                  `}
                >
                  {Math.round(soundVolume * 100)}%
                </span>
              </div>
            </div>

            {/* Sound Packs (Future Implementation) */}
            <div
              css={css`
                margin-bottom: 20px;
                padding: 15px;
                background-color: ${hexToRgba(themeColors.background, 0.3)};
                border: 1px solid ${hexToRgba(themeColors.primary, 0.3)};
                border-radius: 4px;
              `}
            >
              <div
                css={css`
                  font-size: 16px;
                  color: ${themeColors.text};
                  margin-bottom: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <span
                    className="material-symbols-outlined"
                    css={css`
                      margin-right: 10px;
                      color: ${themeColors.primary};
                      font-size: 20px;
                    `}
                  >
                    library_music
                  </span>
                  Sound Packs
                </div>
              </div>

              <div
                css={css`
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
                  gap: 10px;
                  margin-bottom: 15px;
                `}
              >
                {availableSoundPacks.map((soundPack) => (
                  <div
                    key={soundPack.id}
                    css={css`
                      background-color: ${hexToRgba(
                        themeColors.background,
                        0.5
                      )};
                      border: 1px solid
                        ${currentTheme.soundPack?.id === soundPack.id
                          ? themeColors.primary
                          : hexToRgba(themeColors.primary, 0.3)};
                      border-radius: 4px;
                      padding: 12px;
                      cursor: ${soundPack.isAvailable ? "pointer" : "default"};
                      opacity: ${soundPack.isAvailable ? 1 : 0.5};
                      transition: all 0.2s ease;
                      position: relative;

                      ${soundPack.isAvailable
                        ? `
                          &:hover {
                            border-color: ${themeColors.primary};
                            box-shadow: 0 0 5px ${hexToRgba(
                              themeColors.primary,
                              0.3
                            )};
                          }
                        `
                        : ""}

                      ${currentTheme.soundPack?.id === soundPack.id
                        ? `
                          box-shadow: 0 0 8px ${hexToRgba(
                            themeColors.primary,
                            0.4
                          )};
                        `
                        : ""}
                    `}
                    onClick={() => {
                      if (soundPack.isAvailable) {
                        setSoundPack(soundPack.id);
                      }
                    }}
                  >
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 8px;
                      `}
                    >
                      <span
                        css={css`
                          font-weight: bold;
                          color: ${themeColors.text};
                        `}
                      >
                        {soundPack.name}
                      </span>

                      {!soundPack.isAvailable && (
                        <span
                          css={css`
                            font-size: 10px;
                            color: ${themeColors.accent};
                            background-color: ${hexToRgba(
                              themeColors.background,
                              0.7
                            )};
                            padding: 2px 6px;
                            border-radius: 3px;
                            border: 1px solid
                              ${hexToRgba(themeColors.accent, 0.5)};
                          `}
                        >
                          Coming Soon
                        </span>
                      )}
                    </div>

                    <div
                      css={css`
                        font-size: 12px;
                        color: ${hexToRgba(themeColors.text, 0.8)};
                      `}
                    >
                      {soundPack.description}
                    </div>
                  </div>
                ))}
              </div>

              <div
                css={css`
                  margin-top: 5px;
                  color: ${hexToRgba(themeColors.text, 0.8)};
                  font-size: 12px;
                  font-style: italic;
                `}
              >
                More sound packs will be available in future updates.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThemeSelector;
