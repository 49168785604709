import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { playSound, SOUND_PATHS } from "../utils/audioUtils";

/** @jsxImportSource @emotion/react */

// Define bootSteps outside the component so it's a stable reference
const bootSteps = [
  {
    message: "INITIALIZING GOLDEN AXIOM v1.0.0: IMPLEMENTATION STATUS 92%",
    delay: 800,
  },
  {
    message:
      "VERIFYING SYSTEM COHERENCE: STRUCTURAL INTEGRITY INDEX 0.96 | MODULE COMPATIBILITY 0.93",
    delay: 700,
  },
  {
    message:
      "MOUNTING OPERATING SYSTEM LAYER: KERNEL | MEMORY ALLOCATION | PROCESS ISOLATION",
    delay: 1000,
  },
  {
    message:
      "ESTABLISHING NEXUS CONNECTION: SINGULAR KNOWLEDGE NODE | TIMESTAMP 1743214260123",
    delay: 900,
  },
  {
    message:
      "LOADING LANGUAGE LAYER: COMPILER 0.9.4 | INTERPRETER 1.0.1 | SYNTAX VALIDATION",
    delay: 800,
  },
  {
    message:
      "INITIALIZING CORE INTELLIGENCE: OPTIMIZER (RATE 0.08/CYCLE) | EVALUATOR | LEARNER",
    delay: 1000,
  },
  {
    message:
      "CONNECTING TO MODULE LAYER: ARCHON 0.9.8 | IRIS 0.9.2 | UROBOROS 0.1.0 | SYNAPSE 0.0.5",
    delay: 800,
  },
  {
    message:
      "LAUNCHING AZIMUTH USER INTERFACE: HUMAN DEPENDENCY LEVEL 0.12 | PERFORMANCE TARGET 60FPS",
    delay: 1000,
  },
  {
    message:
      "POWERED BY NEXUS CORE - AUTONOMOUS EVOLUTIONARY GENERATIVE INTELLIGENCE SYSTEM",
    delay: 1200,
  },
];

interface BootSequenceProps {
  onComplete: () => void;
}

const BootSequence: React.FC<BootSequenceProps> = ({ onComplete }) => {
  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [showCursor, setShowCursor] = useState(true);
  const [text, setText] = useState<string[]>([""]);
  const [scanlines, setScanlines] = useState(true);
  const [glitch, setGlitch] = useState(false);
  const [showAcronym, setShowAcronym] = useState(false);

  // Set the document title
  useEffect(() => {
    // Title will be set by the state change event in App.tsx
    // No explicit document.title setting needed here
    // No cleanup - we want the state-based title to persist
  }, []);

  // Allow skipping with any key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Prevent skipping if modifier keys are pressed, or if input fields are focused elsewhere
      if (event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
        return;
      }
      // Add check for active element if necessary, but for boot sequence, any key might be fine
      // const activeElement = document.activeElement;
      // if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
      //   return;
      // }

      console.log("[BootSequence] Key pressed, skipping sequence.");
      onComplete();
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup listener when component unmounts or completes
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onComplete]); // Depend on onComplete to ensure it's the latest version

  // Play boot sound on mount
  useEffect(() => {
    playSound(SOUND_PATHS.ui.boot, 0.7).catch((err) => {
      console.warn("Failed to play boot sound:", err);
    });
  }, []);

  // Cursor blinking effect
  useEffect(() => {
    const interval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  // Random scanline effect
  useEffect(() => {
    const interval = setInterval(() => {
      setScanlines((prev) => !prev);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // Occasional glitch effect
  useEffect(() => {
    const triggerGlitch = () => {
      setGlitch(true);
      setTimeout(() => setGlitch(false), 200);
    };

    const interval = setInterval(() => {
      if (Math.random() > 0.7) {
        triggerGlitch();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  // Show the acronym after all boot steps are processed
  useEffect(() => {
    if (step >= bootSteps.length) {
      setTimeout(() => {
        setShowAcronym(true);
      }, 500);

      // Delay onComplete to allow acronym to be visible
      setTimeout(() => {
        console.log(
          "[BootSequence] Simplified - Calling onComplete after acronym display."
        );
        onComplete();
      }, 2500);

      return;
    }
  }, [step, onComplete]);

  // Additional effect to handle boot steps
  useEffect(() => {
    // Exit if all steps are processed
    if (step >= bootSteps.length) {
      return;
    }

    const currentStepData = bootSteps[step];
    console.log(
      `[BootSequence] Simplified - Starting effect for step ${step}: ${currentStepData.message}`
    );

    // 1. Set the full text for the current step immediately
    setText((prevText) => {
      const updatedText = [...prevText];
      while (updatedText.length <= step) {
        updatedText.push(""); // Ensure line exists
      }
      updatedText[step] = currentStepData.message; // Set full message
      console.log(`[BootSequence] Simplified - Set text for step ${step}`);
      return updatedText;
    });

    // 2. Immediately set the timeout for the next step
    console.log(
      `[BootSequence] Simplified - Setting timeout (${currentStepData.delay}ms) for step ${step} completion.`
    );
    const timeoutId = setTimeout(() => {
      console.log(
        `[BootSequence] Simplified - Timeout finished for step ${step}. Processing next step.`
      );

      // Use functional update for step
      setStep((prevStep) => {
        const nextStep = prevStep + 1;
        const newProgress = Math.min(100, (nextStep / bootSteps.length) * 100);
        setProgress(newProgress);
        console.log(
          `[BootSequence] Simplified - State updated: nextStep=${nextStep}, progress=${newProgress.toFixed(
            1
          )}%`
        );

        // Prepare the text array for the next step OR finish
        if (nextStep < bootSteps.length) {
          setText((prevText) => {
            // Ensure next line exists for the next step
            if (prevText.length <= nextStep) {
              return [...prevText, ""];
            }
            return prevText; // No change needed if line exists
          });
        }
        return nextStep; // Return the new step value for the state update
      });
    }, currentStepData.delay);

    // Cleanup function for the effect (clears the step transition timeout)
    return () => {
      console.log(
        `[BootSequence] Simplified - Cleaning up timeout for step transition: ${step}`
      );
      clearTimeout(timeoutId);
    };
  }, [step]);

  // Type rendering function - renders text with a typewriter effect
  const renderBootStepWithDetails = (line: string, index: number) => {
    // Extract main message and details if they exist
    let mainMessage = line;
    let details = null;

    // Check if there are metrics/details to display (after a colon)
    if (line.includes(":")) {
      const [message, detailsPart] = line.split(":");
      mainMessage = message;
      details = detailsPart.trim();
    }

    // Format details with colored values
    const formattedDetails = details
      ? details
          .replace(
            /(\d+\.\d+|\d+%|v\d+\.\d+\.\d+|\d+\.\d+\/CYCLE|\d+FPS)/g,
            (match) => `<span style="color: #26dafd;">${match}</span>`
          )
          .replace(
            /\|/g,
            '<span style="color: #a3e8f7; opacity: 0.7;">|</span>'
          )
      : null;

    // Generate a pseudo-kernel process ID for the prefix
    const hexIndex = (index + 1).toString(16).toUpperCase().padStart(3, "0");
    const prefix = `KRNL:0x${hexIndex}`;

    return (
      <div
        key={index}
        css={css`
          margin-bottom: ${index === text.length - 1 ? 0 : "10px"};
          display: flex;
          flex-direction: column;
          animation: ${index === text.length - 1
            ? "fadeIn 0.3s forwards"
            : "none"};

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}
      >
        <div style={{ display: "flex" }}>
          <span
            css={css`
              color: #5cebfa;
              margin-right: 10px;
              font-weight: 500;
            `}
          >
            {/* Enhanced Prefix */}[{prefix}]
          </span>
          <span>{mainMessage}</span>
          {index === text.length - 1 && showCursor && (
            <span
              css={css`
                margin-left: 2px;
              `}
            >
              ▌
            </span>
          )}
        </div>

        {formattedDetails && (
          <div
            css={css`
              padding-left: 85px;
              font-size: 12px;
              color: #a3e8f7;
              margin-top: 3px;
              opacity: 0.9;
            `}
            dangerouslySetInnerHTML={{ __html: formattedDetails }}
          />
        )}
      </div>
    );
  };

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        color: #26dafd;
        font-family: "Share Tech Mono", monospace;
        padding: 40px;
        box-sizing: border-box;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: ${scanlines
            ? "repeating-linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) 1px, transparent 1px, transparent 2px)"
            : "none"};
          pointer-events: none;
          z-index: 10;
        }
      `}
    >
      {/* Glitch overlay */}
      {glitch && (
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(38, 218, 253, 0.03);
            z-index: 11;
            animation: glitch 0.2s ease;

            @keyframes glitch {
              0% {
                transform: translate(0);
              }
              20% {
                transform: translate(-5px, 5px);
              }
              40% {
                transform: translate(-5px, -5px);
              }
              60% {
                transform: translate(5px, 5px);
              }
              80% {
                transform: translate(5px, -5px);
              }
              100% {
                transform: translate(0);
              }
            }
          `}
        />
      )}

      {/* A.Z.I.M.U.T.H Logo */}
      <div
        css={css`
          text-align: center;
          margin-bottom: 40px;
          margin-top: 80px;
          position: relative;
        `}
      >
        {/* Using SVG logo instead of text */}
        <div
          css={css`
            text-align: center;
            animation: pulse 4s infinite alternate;

            @keyframes pulse {
              0% {
                filter: drop-shadow(0 0 10px rgba(38, 218, 253, 0.5));
              }
              100% {
                filter: drop-shadow(0 0 20px rgba(38, 218, 253, 0.8))
                  drop-shadow(0 0 30px rgba(38, 218, 253, 0.4));
              }
            }
          `}
        >
          {/* Enhanced A.Z.I.M.U.T.H header with advanced cyberpunk styling */}
          <div
            css={css`
              font-size: 48px;
              font-weight: bold;
              letter-spacing: 6px;
              margin: 20px 0;
              color: #26dafd;
              text-shadow: 0 0 10px rgba(38, 218, 253, 0.8),
                0 0 20px rgba(38, 218, 253, 0.4),
                0 0 30px rgba(38, 218, 253, 0.2);
              position: relative;
              display: inline-block;
              transform: perspective(500px) rotateX(10deg);
              background: linear-gradient(180deg, #26dafd, #0a81a8);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              /* Add line-through effect */
              &::before {
                content: "";
                position: absolute;
                width: 105%;
                height: 1px;
                background-color: rgba(38, 218, 253, 0.7);
                left: -2.5%;
                top: 50%;
                box-shadow: 0 0 10px 1px #26dafd;
                transform: translateY(-50%);
                animation: scanline 3s linear infinite;
              }

              @keyframes scanline {
                0% {
                  opacity: 0;
                  top: 0%;
                }
                50% {
                  opacity: 0.8;
                }
                100% {
                  opacity: 0;
                  top: 100%;
                }
              }

              /* Add a glitch effect on hover */
              &:hover {
                animation: textGlitch 0.3s infinite;
                cursor: pointer;
              }

              @keyframes textGlitch {
                0% {
                  text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
                    -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
                    -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
                  transform: perspective(500px) rotateX(10deg) scale(1);
                }
                15% {
                  transform: perspective(500px) rotateX(10deg) scale(1.01);
                }
                16% {
                  text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
                    0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
                    -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
                  transform: perspective(500px) rotateX(10deg) scale(0.99);
                }
                49% {
                  text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
                    0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
                    -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
                }
                50% {
                  text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
                    0.05em 0 0 rgba(0, 255, 0, 0.75),
                    0 -0.05em 0 rgba(0, 0, 255, 0.75);
                }
                99% {
                  text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
                    0.05em 0 0 rgba(0, 255, 0, 0.75),
                    0 -0.05em 0 rgba(0, 0, 255, 0.75);
                  transform: perspective(500px) rotateX(10deg) scale(1.01);
                }
                100% {
                  text-shadow: -0.025em 0 0 rgba(255, 0, 0, 0.75),
                    -0.025em -0.025em 0 rgba(0, 255, 0, 0.75),
                    -0.025em -0.05em 0 rgba(0, 0, 255, 0.75);
                  transform: perspective(500px) rotateX(10deg) scale(1);
                }
              }

              /* Decorative elements */
              &::after {
                content: "// GRAPHICAL USER INTERFACE";
                position: absolute;
                font-size: 12px;
                bottom: -15px;
                right: 0;
                color: #26dafd;
                text-shadow: 0 0 5px #26dafd;
                opacity: 0.8;
                letter-spacing: 1px;
                font-weight: normal;
                -webkit-text-fill-color: initial;
              }
            `}
          >
            {/* Final message set to AZIMUTH */}
            AZIMUTH
          </div>

          <div
            style={{
              fontSize: "12px",
              marginTop: "10px",
              opacity: 0.8,
              color: "#26dafd",
            }}
          >
            v1.0.0-alpha.23
          </div>
        </div>

        {/* Animated corner decorations */}
        <div
          css={css`
            position: absolute;
            top: -20px;
            left: calc(50% - 180px);
            width: 20px;
            height: 20px;
            border-top: 1px solid #26dafd;
            border-left: 1px solid #26dafd;
          `}
        />
        <div
          css={css`
            position: absolute;
            top: -20px;
            right: calc(50% - 180px);
            width: 20px;
            height: 20px;
            border-top: 1px solid #26dafd;
            border-right: 1px solid #26dafd;
          `}
        />
        <div
          css={css`
            position: absolute;
            bottom: -20px;
            left: calc(50% - 180px);
            width: 20px;
            height: 20px;
            border-bottom: 1px solid #26dafd;
            border-left: 1px solid #26dafd;
          `}
        />
        <div
          css={css`
            position: absolute;
            bottom: -20px;
            right: calc(50% - 180px);
            width: 20px;
            height: 20px;
            border-bottom: 1px solid #26dafd;
            border-right: 1px solid #26dafd;
          `}
        />
      </div>

      {/* Terminal output */}
      <div
        css={css`
          flex: 1;
          overflow: auto;
          background-color: rgba(0, 20, 40, 0.3);
          border: 1px solid #26dafd;
          padding: 20px;
          font-family: "Share Tech Mono", monospace;
          font-size: 14px;
          color: #26dafd;
          line-height: 1.6;
          box-shadow: 0 0 20px rgba(38, 218, 253, 0.2);
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 20px;
            background: linear-gradient(
              to bottom,
              rgba(38, 218, 253, 0.1),
              transparent
            );
            pointer-events: none;
          }
        `}
      >
        {text.map((line, index) => renderBootStepWithDetails(line, index))}
      </div>

      {/* Progress bar */}
      <div
        css={css`
          margin-top: 40px;
          height: 30px;
          border: 1px solid #26dafd;
          position: relative;
          overflow: hidden;
          box-shadow: 0 0 10px rgba(38, 218, 253, 0.2);
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: ${progress}%;
            background-color: rgba(38, 218, 253, 0.3);
            transition: width 0.3s ease-in-out;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              width: 5px;
              height: 100%;
              background-color: rgba(38, 218, 253, 0.6);
            }
          `}
        ></div>

        {/* Animated progress lines */}
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: repeating-linear-gradient(
              -45deg,
              transparent,
              transparent 10px,
              rgba(38, 218, 253, 0.1) 10px,
              rgba(38, 218, 253, 0.1) 20px
            );
            background-size: 200% 200%;
            animation: progressAnimation 20s linear infinite;

            @keyframes progressAnimation {
              0% {
                background-position: 0% 0%;
              }
              100% {
                background-position: 100% 100%;
              }
            }
          `}
        ></div>

        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            text-shadow: 0 0 5px rgba(38, 218, 253, 0.5);
          `}
        >
          SYSTEM BOOT: {Math.floor(progress)}%
        </div>
      </div>

      {/* Display AZIMUTH acronym meaning after all steps complete */}
      {showAcronym && (
        <div
          css={css`
            margin-top: 2rem;
            padding: 1rem;
            border: 1px solid rgba(92, 235, 250, 0.3);
            background-color: rgba(0, 0, 0, 0.3);
            animation: fadeIn 0.5s forwards;
            text-align: center;
            font-size: 1.2rem;
          `}
        >
          <div
            css={css`
              font-size: 1.5rem;
              color: #5cebfa;
              margin-bottom: 0.5rem;
              font-weight: bold;
              text-shadow: 0 0 5px rgba(92, 235, 250, 0.7);
            `}
          >
            WELCOME
          </div>
        </div>
      )}

      {/* Status indicator with blinking cursor effect */}
      <div
        css={css`
          margin-top: 1rem;
          display: flex;
          align-items: center;
        `}
      >
        <span>STATUS:</span>
        <span
          css={css`
            color: #30ff85;
            margin-left: 0.5rem;
          `}
        >
          {step >= bootSteps.length ? "READY" : "LOADING"}
        </span>
        <span
          css={css`
            display: inline-block;
            width: 0.5rem;
            height: 1rem;
            background-color: #5cebfa;
            margin-left: 0.5rem;
            opacity: ${showCursor ? 1 : 0};
          `}
        />
      </div>
    </div>
  );
};

export default BootSequence;
