import React, { useState } from "react";
import { css, keyframes } from "@emotion/react";
import { useTheme } from "../context/ThemeContext";
import { hexToRgba } from "../utils/helpers";

/** @jsxImportSource @emotion/react */

interface FileExplorerProps {
  width?: string;
  height?: string;
}

interface FileItem {
  name: string;
  type: "file" | "folder";
  size: number;
  modified: string;
  children?: FileItem[];
}

// Animation keyframes - Updated glowPulse to accept color
const glowPulse = (color: string) => keyframes`
  0% { box-shadow: 0 0 5px ${hexToRgba(color, 0.3)}; }
  50% { box-shadow: 0 0 10px ${hexToRgba(color, 0.5)}; }
  100% { box-shadow: 0 0 5px ${hexToRgba(color, 0.3)}; }
`;

const scanline = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(30px); }
`;

const pulse = keyframes`
  0% { background-color: rgba(38, 218, 253, 0.05); }
  50% { background-color: rgba(38, 218, 253, 0.1); }
  100% { background-color: rgba(38, 218, 253, 0.05); }
`;

// Demo file system data
const demoFileSystem: FileItem[] = [
  {
    name: "System",
    type: "folder",
    size: 0,
    modified: "2023-06-15 09:30",
    children: [
      {
        name: "kernel32.sys",
        type: "file",
        size: 8192000,
        modified: "2023-06-15 09:31",
      },
      {
        name: "aegis.exe",
        type: "file",
        size: 1245184,
        modified: "2023-06-15 09:30",
      },
      {
        name: "drivers",
        type: "folder",
        size: 0,
        modified: "2023-06-15 09:32",
        children: [
          {
            name: "quantum.drv",
            type: "file",
            size: 5242880,
            modified: "2023-06-15 09:32",
          },
          {
            name: "neural.drv",
            type: "file",
            size: 3145728,
            modified: "2023-06-15 09:33",
          },
        ],
      },
    ],
  },
  {
    name: "User",
    type: "folder",
    size: 0,
    modified: "2023-06-15 09:40",
    children: [
      {
        name: "documents.txt",
        type: "file",
        size: 2048,
        modified: "2023-06-15 09:41",
      },
      {
        name: "profile.dat",
        type: "file",
        size: 4096,
        modified: "2023-06-15 09:42",
      },
    ],
  },
  {
    name: "Applications",
    type: "folder",
    size: 0,
    modified: "2023-06-15 10:00",
    children: [
      {
        name: "terminal.app",
        type: "file",
        size: 524288,
        modified: "2023-06-15 10:01",
      },
      {
        name: "explorer.app",
        type: "file",
        size: 655360,
        modified: "2023-06-15 10:02",
      },
      {
        name: "settings.app",
        type: "file",
        size: 262144,
        modified: "2023-06-15 10:03",
      },
    ],
  },
  {
    name: "readme.txt",
    type: "file",
    size: 1024,
    modified: "2023-06-15 09:30",
  },
];

const FileExplorer: React.FC<FileExplorerProps> = ({
  width = "100%",
  height = "100%",
}) => {
  const { currentTheme } = useTheme();
  const themeColors = currentTheme.colorScheme;
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [currentItems, setCurrentItems] = useState<FileItem[]>(demoFileSystem);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [navigationStack, setNavigationStack] = useState<{
    path: string[][];
    position: number;
  }>({
    path: [[]],
    position: 0,
  });

  // Find directory items based on path
  const findDirectoryByPath = (
    _items: FileItem[],
    path: string[]
  ): FileItem[] | null => {
    if (path.length === 0) {
      return demoFileSystem;
    }

    let currentLevel = demoFileSystem;

    for (const segment of path) {
      const folder = currentLevel.find(
        (item) => item.type === "folder" && item.name === segment
      );
      if (!folder || !folder.children) {
        return null;
      }
      currentLevel = folder.children;
    }

    return currentLevel;
  };

  // Handle navigation to a folder
  const navigateToFolder = (folderName: string) => {
    const newPath = [...currentPath, folderName];
    const items = findDirectoryByPath(demoFileSystem, newPath);

    if (items) {
      setCurrentPath(newPath);
      setCurrentItems(items);
      setSelectedItem(null);

      // Update navigation stack
      const newPosition = navigationStack.position + 1;
      const newStack = {
        path: [...navigationStack.path.slice(0, newPosition), newPath],
        position: newPosition,
      };
      setNavigationStack(newStack);
    }
  };

  // Handle item click
  const handleItemClick = (item: FileItem) => {
    setSelectedItem(item.name);

    if (item.type === "folder") {
      navigateToFolder(item.name);
    }
  };

  // Navigate back
  const navigateBack = () => {
    if (navigationStack.position > 0) {
      const newPosition = navigationStack.position - 1;
      const previousPath = navigationStack.path[newPosition];
      const items = findDirectoryByPath(demoFileSystem, previousPath);

      if (items) {
        setCurrentPath(previousPath);
        setCurrentItems(items);
        setSelectedItem(null);
        setNavigationStack({
          ...navigationStack,
          position: newPosition,
        });
      }
    }
  };

  // Navigate forward
  const navigateForward = () => {
    if (navigationStack.position < navigationStack.path.length - 1) {
      const newPosition = navigationStack.position + 1;
      const nextPath = navigationStack.path[newPosition];
      const items = findDirectoryByPath(demoFileSystem, nextPath);

      if (items) {
        setCurrentPath(nextPath);
        setCurrentItems(items);
        setSelectedItem(null);
        setNavigationStack({
          ...navigationStack,
          position: newPosition,
        });
      }
    }
  };

  // Navigate up
  const navigateUp = () => {
    if (currentPath.length > 0) {
      const newPath = currentPath.slice(0, -1);
      const items = findDirectoryByPath(demoFileSystem, newPath);

      if (items) {
        setCurrentPath(newPath);
        setCurrentItems(items);
        setSelectedItem(null);

        // Update navigation stack
        const newPosition = navigationStack.position + 1;
        const newStack = {
          path: [...navigationStack.path.slice(0, newPosition), newPath],
          position: newPosition,
        };
        setNavigationStack(newStack);
      }
    }
  };

  // Format file size
  const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return "-";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  return (
    <div
      css={css`
        width: ${width};
        height: ${height};
        display: flex;
        flex-direction: column;
        background-color: ${hexToRgba(themeColors.background, 0.95)};
        color: ${themeColors.primary};
        font-family: "Share Tech Mono", monospace;
        position: relative;
        letter-spacing: 1px;
      `}
    >
      {/* Scanlines effect */}
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: repeating-linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1) 1px,
            transparent 1px,
            transparent 2px
          );
          pointer-events: none;
          z-index: 5;
        `}
      ></div>

      {/* Top bar with navigation */}
      <div
        css={css`
          display: flex;
          align-items: center;
          padding: 8px;
          background-color: ${hexToRgba(themeColors.background, 0.9)};
          border-bottom: 1px solid ${hexToRgba(themeColors.primary, 0.5)};
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
          position: relative;
          z-index: 2;

          &::after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 10%;
            right: 10%;
            height: 1px;
            background: linear-gradient(
              to right,
              transparent,
              ${hexToRgba(themeColors.primary, 0.8)},
              transparent
            );
          }
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 5px;
            margin-right: 15px;
          `}
        >
          <button
            css={css`
              background: none;
              border: 1px solid ${hexToRgba(themeColors.primary, 0.3)};
              color: ${themeColors.primary};
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border-radius: 3px;
              opacity: ${navigationStack.position > 0 ? 1 : 0.5};

              &:hover {
                background-color: ${hexToRgba(themeColors.primary, 0.1)};
                box-shadow: 0 0 10px ${hexToRgba(themeColors.primary, 0.3)};
              }

              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            `}
            onClick={navigateBack}
            disabled={navigationStack.position <= 0}
          >
            <span className="material-symbols-outlined">arrow_back</span>
          </button>

          <button
            css={css`
              background: none;
              border: 1px solid ${hexToRgba(themeColors.primary, 0.3)};
              color: ${themeColors.primary};
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border-radius: 3px;
              opacity: ${navigationStack.position <
              navigationStack.path.length - 1
                ? 1
                : 0.5};

              &:hover {
                background-color: ${hexToRgba(themeColors.primary, 0.1)};
                box-shadow: 0 0 10px ${hexToRgba(themeColors.primary, 0.3)};
              }

              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            `}
            onClick={navigateForward}
            disabled={
              navigationStack.position >= navigationStack.path.length - 1
            }
          >
            <span className="material-symbols-outlined">arrow_forward</span>
          </button>

          <button
            css={css`
              background: none;
              border: 1px solid ${hexToRgba(themeColors.primary, 0.3)};
              color: ${themeColors.primary};
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border-radius: 3px;
              opacity: ${currentPath.length > 0 ? 1 : 0.5};

              &:hover {
                background-color: ${hexToRgba(themeColors.primary, 0.1)};
                box-shadow: 0 0 10px ${hexToRgba(themeColors.primary, 0.3)};
              }

              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            `}
            onClick={navigateUp}
            disabled={currentPath.length === 0}
            title="Navigate Up"
          >
            <span className="material-symbols-outlined">arrow_upward</span>
          </button>
        </div>

        <div
          css={css`
            flex: 1;
            background-color: ${hexToRgba(themeColors.background, 0.6)};
            padding: 5px 10px;
            border-radius: 3px;
            border: 1px solid ${hexToRgba(themeColors.primary, 0.3)};
            display: flex;
            align-items: center;
            animation: ${glowPulse(themeColors.primary)} 4s infinite;
            position: relative;
            overflow: hidden;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              height: 2px;
              background: ${hexToRgba(themeColors.primary, 0.1)};
              animation: ${scanline} 2s linear infinite;
            }
          `}
        >
          <span
            className="material-symbols-outlined"
            css={css`
              margin-right: 8px;
              color: ${hexToRgba(themeColors.primary, 0.8)};
              text-shadow: 0 0 5px ${hexToRgba(themeColors.primary, 0.5)};
              font-size: 18px;
            `}
          >
            folder_open
          </span>
          <span
            css={css`
              font-weight: bold;
            `}
          >
            Z:\Guest\{currentPath.join("\\")}
          </span>
        </div>
      </div>

      {/* File list */}
      <div
        css={css`
          flex: 1;
          overflow: auto;
          padding: 10px;

          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.3);
          }
          &::-webkit-scrollbar-thumb {
            background: ${hexToRgba(themeColors.primary, 0.4)};
            &:hover {
              background: ${hexToRgba(themeColors.primary, 0.6)};
            }
          }
        `}
      >
        <table
          css={css`
            width: 100%;
            border-collapse: collapse;
          `}
        >
          <thead>
            <tr
              css={css`
                background-color: ${hexToRgba(themeColors.background, 0.8)};
                border-bottom: 1px solid ${hexToRgba(themeColors.primary, 0.5)};
                position: sticky;
                top: 0;
                z-index: 1;

                &::after {
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  height: 1px;
                  background: linear-gradient(
                    to right,
                    transparent,
                    ${hexToRgba(themeColors.primary, 0.8)},
                    transparent
                  );
                }
              `}
            >
              <th
                css={css`
                  padding: 8px 15px;
                  text-align: left;
                  font-weight: normal;
                  color: ${themeColors.text};
                  text-shadow: 0 0 5px ${hexToRgba(themeColors.primary, 0.5)};
                  width: 40%;
                `}
              >
                Name
              </th>
              <th
                css={css`
                  padding: 8px 15px;
                  text-align: left;
                  font-weight: normal;
                  color: ${themeColors.text};
                  text-shadow: 0 0 5px ${hexToRgba(themeColors.primary, 0.5)};
                  width: 15%;
                `}
              >
                Type
              </th>
              <th
                css={css`
                  padding: 8px 15px;
                  text-align: right;
                  font-weight: normal;
                  color: ${themeColors.text};
                  text-shadow: 0 0 5px ${hexToRgba(themeColors.primary, 0.5)};
                  width: 15%;
                `}
              >
                Size
              </th>
              <th
                css={css`
                  padding: 8px 15px;
                  text-align: left;
                  font-weight: normal;
                  color: ${themeColors.text};
                  text-shadow: 0 0 5px ${hexToRgba(themeColors.primary, 0.5)};
                  width: 30%;
                `}
              >
                Modified
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr
                key={item.name}
                css={css`
                  border-bottom: 1px solid
                    ${hexToRgba(themeColors.primary, 0.2)};
                  cursor: pointer;
                  transition: background-color 0.2s ease;
                  position: relative;

                  ${selectedItem === item.name
                    ? `
                    background-color: ${hexToRgba(themeColors.primary, 0.15)};
                    
                    &::before {
                      content: "";
                      position: absolute;
                      left: 0;
                      top: 0;
                      bottom: 0;
                      width: 3px;
                      background-color: ${themeColors.primary};
                      box-shadow: 0 0 10px ${hexToRgba(
                        themeColors.primary,
                        0.8
                      )};
                    }
                  `
                    : ""}

                  &:hover {
                    background-color: ${selectedItem === item.name
                      ? hexToRgba(themeColors.primary, 0.15)
                      : hexToRgba(themeColors.primary, 0.1)};
                    animation: ${pulse} 2s infinite;
                  }
                `}
                onClick={() => handleItemClick(item)}
              >
                <td
                  css={css`
                    padding: 8px 15px;
                    display: flex;
                    align-items: center;
                  `}
                >
                  <span
                    className="material-symbols-outlined"
                    css={css`
                      margin-right: 10px;
                      color: ${item.type === "folder"
                        ? themeColors.primary
                        : hexToRgba(themeColors.primary, 0.6)};
                      font-size: 20px;
                      text-shadow: ${item.type === "folder"
                        ? `0 0 5px ${hexToRgba(themeColors.primary, 0.5)}`
                        : "none"};
                    `}
                  >
                    {item.type === "folder" ? "folder" : "description"}
                  </span>
                  <span
                    css={css`
                      color: ${item.type === "folder"
                        ? themeColors.text
                        : hexToRgba(themeColors.text, 0.9)};
                      text-shadow: ${item.type === "folder"
                        ? `0 0 3px ${hexToRgba(themeColors.text, 0.3)}`
                        : "none"};
                    `}
                  >
                    {item.name}
                  </span>
                </td>
                <td
                  css={css`
                    padding: 8px 15px;
                    color: ${hexToRgba(themeColors.text, 0.8)};
                  `}
                >
                  {item.type === "folder"
                    ? "Folder"
                    : item.name.split(".").pop()?.toUpperCase()}
                </td>
                <td
                  css={css`
                    padding: 8px 15px;
                    text-align: right;
                    color: ${hexToRgba(themeColors.text, 0.8)};
                  `}
                >
                  {formatFileSize(item.size)}
                </td>
                <td
                  css={css`
                    padding: 8px 15px;
                    color: ${hexToRgba(themeColors.text, 0.8)};
                  `}
                >
                  {item.modified}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Status bar */}
      <div
        css={css`
          padding: 5px 10px;
          background-color: ${hexToRgba(themeColors.background, 0.8)};
          border-top: 1px solid ${hexToRgba(themeColors.primary, 0.3)};
          font-size: 12px;
          color: ${hexToRgba(themeColors.text, 0.7)};
          display: flex;
          justify-content: space-between;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 10%;
            right: 10%;
            height: 1px;
            background: linear-gradient(
              to right,
              transparent,
              ${hexToRgba(themeColors.primary, 0.5)},
              transparent
            );
          }
        `}
      >
        <div>
          {currentItems.length} item{currentItems.length !== 1 ? "s" : ""}
        </div>
        <div>Available space: 254.8 GB</div>
      </div>
    </div>
  );
};

export default FileExplorer;
